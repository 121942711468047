import httpApi from "@/plugins/http";

const router = "release";

export const HttpRequestRelease = {
  get: (filter) => {
    return httpApi.get(`${router}/listar`, {
      params: filter,
    });
  },

  getById(id) {
    return httpApi.get(`${router}/${id}`);
  },

  getTarefasParaEdicao(id) {
    return httpApi.get(`${router}/tarefas-edicao/${id}`);
  },

  getTarefas(id) {
    return httpApi.get(`${router}/tarefas/${id}`);
  },

  getBranches(id) {
    return httpApi.get(`${router}/branches/${id}`);
  },

  getSQL(id) {
    window.open(`${httpApi.defaults.baseURL}${router}/get-sql/${id}`, "_blank");
  },

  postRelease: (params) => {
    return httpApi.post(`${router}`, params);
  },

  putRelease: (params) => {
    return httpApi.post(`${router}/alterar/${params.id}`, params);
  },

  putSetPublished: (params) => {
    return httpApi.put(`${router}/set-published/${params.id}`);
  },

  sendPublicationEmail(id) {
    return httpApi.get(`${router}/send-publication-email/${id}`);
  },

  getUpdatedSqlScripts(releaseId) {
    return httpApi.get(`${router}/get-updated-sql-scripts/${releaseId}`);
  },

  putSqlScripts(params) {
    return httpApi.put(`${router}/set-sql-scripts/${params.id}`, params);
  }
};
