<template>
  <div>
    <CGIModal
      :loading="view.loading"
      :exibir="view.modal.show"
      :titulo="view.action"
      :fullScreen="false"
      :maxWidth="'1200'"
      :scrollable="true"
      @clickCancelar="close"
      @clickConfirmar="submit"
    >
      <template v-slot:body>
        <v-form ref="form" v-model="view.valid" lazy-validation>
          <v-row dense>
            <v-col cols="12" md="4">
              <CGIDataTable
               
              />
            </v-col>
          </v-row>
        </v-form>
      </template>
    </CGIModal>
  </div>
</template>
<script>
import CGIModal from "@/components/CGIModal";
//import CGITextField from "@/components/CGITextField";
import CGIDataTable from "@/components/CGIDataTable";
import { mask } from "vue-the-mask";

const objectModel = {
  id: 0,
  versao: "",
  dataInicio: ""
};

export default {
  name: "",

  directives: {
    mask
  },

  components: {
    CGIModal,
    //CGITextField,
    CGIDataTable,
    
  },

  props: {},

  data() {
    return {
      model: objectModel,
      view: {
        action: "Clientes Notificados",
        loading: false,
        lists: {
          tasks: []
        },
        columns: {
          tasks: [
            {
              text: "Tarefa Id",
              value: "id"
            },
            {
              text: "Dt. Cadastro",
              value: "dataCadastro",
              type: "date"
            },
            {
              text: "Tarefa",
              value: "tituloTarefa"
            },
            {
              text: "Branch",
              value: "branch"
            }
          ]
        },
        modal: {
          show: false
        }
      }
    };
  },

  computed: {},

  watch: {},

  methods: {
    submit() {
      this.close();
    },
    close() {
      this.model = {};
      this.view.modal.show = false;
    },

    openModal(release) {
      this.view.modal.show = true;
      console.log(release)
    }
  }
};
</script>
<style></style>
