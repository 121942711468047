<template>
  <div>
    {{ view.exibir }}
    <ModalBase
      :exibir="view.exibir"
      :titulo="'Abrir Ticket/Tarefa'"
      @clickCancelar="view.exibir = false"
      @clickConfirmar="confirmar()"
    >
      >
      <template v-slot:body>
        <v-form
          ref="form"
          v-model="view.valid"
          lazy-validation
          v-on:submit.prevent
        >
          <v-row align="center">
            <v-col cols="4">
              <v-text-field
                ref="txtTicket"
                dense
                v-model="model.ticketId"
                label="Nº Ticket"
                autofocus
                type="text"
                :rules="[view.required]"
                v-on:keyup.enter="onEnter"
              />
            </v-col>
            <v-col>
              <v-checkbox
                v-model="model.DefinirTarefa"
                label="Definir Tarefa"
              />
            </v-col>
          </v-row>
        </v-form>
      </template>
    </ModalBase>
    <ViewTaskTicket ref="ViewTaskTicket" />
    <SetTask ref="SetTask" />
  </div>
</template>
<script>
import ModalBase from "@/components/CGIModal";
import EventBus from "@/plugins/event-bus";
import ViewTaskTicket from "../app/view-task-ticket/ViewTaskTicket";
import SetTask from "../app/task/SetTask";

const objectModel = {
  ticketId: null,
  DefinirTarefa: false,
};

export default {
  props: {},
  components: {
    ModalBase,
    ViewTaskTicket,
    SetTask,
  },

  data() {
    return {
      model: Object.assign({}, objectModel),
      view: {
        exibir: false,
        required: (value) => !!value || "Campo Obrigatório.",
      },
    };
  },

  mounted() {
    var self = this;

    self.$nextTick(() => this.$refs.txtTicket.focus());

    EventBus.$on("OPEN_TICKET", () => {
      this.model = Object.assign({}, objectModel);
      self.view.exibir = true;
    });
  },

  methods: {
    onEnter() {
      this.confirmar();
    },
    confirmar() {
      if (this.model.DefinirTarefa)
        this.$refs.SetTask.openModal(this.model.ticketId);
      else this.$refs.ViewTaskTicket.openModal(this.model.ticketId);
      this.view.exibir = false;
    },
  },
};
</script>
