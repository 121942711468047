<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card outlined class="mb-4">
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="3">
                <span>
                  <b>Número do Ticket:</b>
                  <v-spacer />
                  {{ dataProp.data.id }}
                </span>
              </v-col>
              <v-col cols="12" md="9">
                <span>
                  <b>Título:</b>
                  <v-spacer />
                  {{ dataProp.data.titulo }}
                </span>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" md="3">
                <span>
                  <b>Soliciante:</b>
                  <v-spacer />
                  {{ dataProp.data.nomeClienteUsuario }}
                </span>
              </v-col>
              <v-col cols="12" md="3">
                <span>
                  <b>Cliente:</b>
                  <v-spacer />
                  {{ dataProp.data.nomeCliente }}
                </span>
              </v-col>
              <v-col cols="12" md="3">
                <span>
                  <b>Tipo Ticket:</b>
                  <v-spacer />
                  {{ dataProp.data.nomeTipoTicket }}
                </span>
              </v-col>
              <v-col cols="12" md="3">
                <span>
                  <b>Status Ticket:</b>
                  <v-spacer />
                  {{ dataProp.data.nomeStatus }}
                </span>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" md="3">
                <span>
                  <b>Módulo Principal:</b>
                  <v-spacer />
                  {{ dataProp.data.nomeModuloPrincipal }}
                </span>
              </v-col>
              <v-col cols="12" md="3">
                <span>
                  <b>Privacidade:</b>
                  <v-spacer />
                  {{ dataProp.data.internoExterno }}
                </span>
              </v-col>
              <v-col cols="12" md="3">
                <span>
                  <b>Tarefa DEV:</b>
                  <v-spacer />
                  {{ dataProp.data.ehTarefa }}
                </span>
              </v-col>
              <v-col cols="12" md="3">
                <span>
                  <b>Usuário:</b>
                  <v-spacer />
                  {{ dataProp.data.nomeUsuario }}
                </span>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" md="3">
                <span>
                  <b>Data Cadastro:</b>
                  <v-spacer />
                  {{ dataProp.data.dataCadastro }}
                </span>
              </v-col>
              <v-col cols="12" md="3">
                <span>
                  <b>Data Previsão:</b>
                  <v-spacer />
                  {{ dataProp.data.dataPrevisao }}
                </span>
              </v-col>

              <v-col cols="12" md="2">
                <span>
                  <b>Prioridade:</b>
                  <v-spacer />
                  {{ dataProp.data.prioridade }}
                </span>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12">
                <CGITextEditor
                  class="mb-4"
                  v-model="dataProp.data.solicitacao"
                  label="Solicitação"
                  disabled
                />
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" md="12">
                <CGIFileUpload
                  v-model="dataProp.data.anexos"
                  routerDownload="ticket"
                  :onlyList="true"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-row>
      <v-col cols="12">
        <p class="title text-center">Atendimentos</p>
        <CGIDataTable
          :columns="view.colunas.historico"
          :items="dataProp.historic"
          :expandedFilter="false"
        />
      </v-col>
    </v-row>
    <!-- <MaterialCardVue
      color="info"
      icon="mdi-twitter"
      title="Followers"
      value="+245"
      sub-icon="mdi-clock"
      sub-text="Just Updated"
    />-->
  </div>
</template>

<script>
import CGIDataTable from "@/components/CGIDataTable.vue";
import CGITextEditor from "@/components/CGITextEditor.vue";
import CGIFileUpload from "@/components/CGIFileUpload";
// import MaterialCardVue from "./MaterialCard.vue";

export default {
  components: {
    CGIDataTable,
    CGITextEditor,
    CGIFileUpload
    // MaterialCardVue
  },
  props: {
    dataProp: {}
  },
  data() {
    return {
      view: {
        colunas: {
          historico: [
            {
              text: "Id",
              value: "id"
            },
            {
              text: "Data",
              value: "dataCadastro",
              type: "datetime"
            },
            {
              text: "Título",
              value: "titulo"
            },
            {
              text: "Obs.",
              value: "obs",
              type: "html"
            },
            {
              text: "Usuário",
              value: "nomeUsuario"
            }
          ]
        }
      }
    };
  }
};
</script>

<style>
</style>