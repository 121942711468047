<template>
  <div>
    <CGIModal
      :loading="view.loading"
      :exibir="view.modal.show"
      titulo="Editor de SQLs do Release"
      :fullScreen="false"
      :maxWidth="'1200'"
      :scrollable="true"
      @clickCancelar="close"
      @clickConfirmar="submit"
    >
      <template v-slot:body>
        <v-form ref="form" v-model="view.valid" lazy-validation>
          <v-container>
            <v-row dense>
              <v-col cols="12">
                <v-textarea
                  v-model="model.scriptsSQL"
                  :disabled="view.published"
                  counter
                  type="text"
                  rows="15"
                  dense
                  outlined
                />
              </v-col>
            </v-row>
            <v-row dense class="mt-n2 mb-n2">
              <v-col cols="12">
                <div class="text-right">
                  <v-btn
                    class="ma-2"
                    :loading="view.loading"
                    :disabled="view.loading || view.published"
                    color="secondary"
                    @click="view.confirmDialog = true"
                  >
                    Atualizar SQL
                    <v-icon right dark>mdi-update</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </template>
    </CGIModal>

    <!-- Dialogo de confirmação -->
    <CGIConfirmationDialog
      :show="view.confirmDialog"
      message="<p>Ao confirmar a ação, os ajustes feitos anteriormente serão perdidos.</p>
               <p>Tem certeza que deseja atualizar os Scripts?</p>"
      @clickCancel="view.confirmDialog = false"
      @clickConfim="refreshSql()"
    />
  </div>
</template>

<script>
import { HttpRequestRelease } from "./http";
import CGIModal from "@/components/CGIModal";
import CGIConfirmationDialog from "@/components/CGIConfirmationDialog";

export default {
  name: "SqlEditor",
  components: {
    CGIModal,
    CGIConfirmationDialog
  },
  props: {},
  data() {
    return {
      loader: null,
      model: {
        id: 0,
        scriptsSQL: ""
      },
      view: {
        loading: false,
        published: false,
        modal: {
          show: false
        },
        confirmDialog: false
      }
    };
  },
  methods: {
    openModal(release) {
      this.view.published = false;
      this.model.id = release.id;
      this.model.scriptsSQL = release.scriptsSQL;

      // Validando se o release já foi publicado.
      if (release.dataPublicacao) {
        this.view.published = true;
      }
      this.view.modal.show = true;
    },
    close() {
      this.model = {};
      this.view.modal.show = false;
    },
    submit() {
      this.view.loading = true;
      HttpRequestRelease.putSqlScripts(this.model).then(response => {
        this.model.scriptsSQL = response.data.data;
        this.view.loading = false;
      });
      this.close();
    },
    /**
     * Buscando
     */
    refreshSql() {
      this.view.confirmDialog = false;
      this.view.loading = true;

      HttpRequestRelease.getUpdatedSqlScripts(this.model.id).then(response => {
        this.model.scriptsSQL = response.data.data;
        this.view.loading = false;
      });
    }
  }
};
</script>

<style>
</style>