import List from "./List";
import Resume from "./Resume";
import Updates from "./Updates";

export default [
  {
    path: "/ticket/listar/",
    meta: {
      public: false
    },
    name: "Listar Tickets",
    component: List
  },
  {
    path: "/ticket/resume/",
    meta: {
      public: false
    },
    name: "Resumo de Tickets",
    component: Resume
  },
  {
    path: "/ticket/updates/",
    meta: {
      public: false
    },
    name: "Resumo de Atualizações",
    component: Updates
  }
];
