<template>
  <div>
    <v-combobox
      dense
      outlined
      v-model="selected"
      :items="view.lists.items"
      :label="label"
      :item-text="itemText"
      :itemValue="itemValue"
      :multiple="multiple"
      :deletable-chips="multiple"
      :loading="view.loading"
      :rules="rules"
      @change="change"
      :clearable="clearable"
      :small-chips="small_chips"
      :hide-selected="hide_selected"
    >
      <!-- Definir Slot's quando tiver um tempo  -->
      <!-- Por enquanto vamos utilizando a combo padrão -->
    </v-combobox>
  </div>
</template>
<script>
import http from "@/plugins/http";

export default {
  props: {
    /**
     * Valor definido via v-model
     */
    value: null,

    /**
     * Prop para verificar se o componente é carregado via serviço
     */
    itemsAsync: {
      default: false,
      type: Boolean,
    },

    /**
     * Rota utilizada para carregar dados caso o 'itemAsync' estiver definida como true
     */
    route: {
      default: "",
      type: String,
    },

    /**
     * Array de items caso o componente não seja carregado via serviço
     */
    items: {
      default() {
        return [];
      },
      type: Array,
    },

    /**
     * Label de exibição
     */
    label: {
      default: "",
      type: String,
    },

    /**
     * Hint de exibição
     */
    hint: {
      default: "",
      type: String,
    },

    /**
     * Prop para exibição dos dados na combo
     */
    itemText: {
      default: "name",
      type: String,
    },

    /**
     * Valor a ser retornado quando o usuário selecionar um item na combo
     */
    itemValue: {
      default: "id",
      type: String,
    },

    /**
     * Seleção multipla
     */
    multiple: {
      default: false,
      type: Boolean,
    },

    /**
     * Exibir selecionados em small-chips
     */
    small_chips: {
      default: false,
      type: Boolean,
    },

    /**
     * Esconder item selecionado
     */
    hide_selected: {
      default: false,
      type: Boolean,
    },

    /**
     * Regras de validação do campo
     */
    rules: {
      default() {
        return [];
      },
      type: Array,
    },

    /**
     * Ação para limpar o campo direto no componente
     */
    clearable: {
      default: true,
      type: Boolean,
    },

    setTodos: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      selected: null,
      view: {
        loading: false,
        lists: {
          items: [],
        },
      },
    };
  },

  watch: {
    value() {
      if (
        this.value > 0 &&
        this.selected == null &&
        this.view.lists.items.length > 0
      ) {
        this.selected = this.view.lists.items.find(
          (x) => x[this.itemValue] == this.value
        );
      }
    },

    items() {
      this.value();
    },
  },

  mounted() {
    // Validando se as props de Async estão preenchidas corretamente
    if (!this.validateAsync()) {
      throw 'Por favor, verifique se as props "route" e "itemsAsync" estão definidas corretamente';
    }

    this.load();
  },

  methods: {
    /**
     * Carregando dados
     */
    load() {
      if (this.setTodos) {
        this.view.lists.items.push({ id: "0", name: "Todos" });
      }

      // Se o itemsAsync for falso, vamos utilizar os items definido nas props
      if (!this.itemsAsync) {
        this.view.lists.items = this.view.lists.items.concat(this.items);
        return;
      }

      // Carregando dados da combo através do serviço definido nas props
      this.view.loading = true;
      http
        .get(this.route)
        .then((response) => {
          this.view.lists.items = this.view.lists.items.concat(
            response.data.data
          );
          this.view.loading = false;

          if (this.value != 0 && this.value != undefined) {
            if (!this.multiple) {
              this.selected = this.view.lists.items.find(
                (x) => x[this.itemValue] == this.value
              );
            } else {
              /**
               * Caso seja multiplo, tratamento diferente para receber os dados vindos via PROP
               */
              this.selected = [];
              let arrayItems = this.value.split(",");
              arrayItems.map((id) => {
                this.selected.push(
                  this.view.lists.items.find((x) => x[this.itemValue] == id)
                );
              });
            }
          }
        })
        .catch((error) => {
          if (error.response.status != 500) {
            let message = error.response.data.message;
            this.notify(message, "error");
          }
        });
    },

    /**
     * Evento emitido quando usuário seleciona um item da combo
     */
    change() {
      let value = 0;
      if (!this.multiple) {
        value =
          this.itemValue.length == 0 || this.selected == undefined
            ? this.selected
            : this.selected[this.itemValue];
      } else {
        value = [];
        this.selected.map((item) => {
          value.push(item.id);
        });
        value = value.join(",");
      }

      this.$emit("input", value);
      this.$forceUpdate();
    },

    /**
     * Método irá verificar caso o componente seja de preenchimento remoto(via serviço),
     * ele valida se as informações da rota e flag estão definidas corretamente
     */
    validateAsync() {
      let valid = true;

      if (
        (this.route.length > 0 && !this.itemsAsync) ||
        (this.route.length == 0 && this.itemsAsync)
      ) {
        valid = false;
      }

      return valid;
    },
  },
};
</script>
