<template>
  <div>
    <div id="appRoot">
      <v-app id="inspire" class="app">
        <!-- se a página for privada(Autenticada) precisamos exibir o toolbar e a menu Drawer -->
        <template v-if="!$route.meta.public">
          <Toolbar />
          <drawer />
          <v-content>
            <div class="container-application">
              <router-view />
            </div>
          </v-content>
        </template>
        <!-- Se a página for publica, não exibimos as opções de menu -->
        <template v-else>
          <!-- <transition> -->
          <!-- <keep-alive> -->
          <router-view></router-view>
          <!-- </keep-alive> -->
          <!-- </transition> -->
        </template>

        <v-footer padless fixed>
          <v-card class="flex" elevation="1" flat tile>
            <v-card-text class="py-2 text-center">
              HeadThink © Todos os Direitos Reservados - Suporte:
              <!--  -->
              <a
                href="https://web.whatsapp.com/send?phone=5522999953808"
                target="_blank"
              >
                (22)99995-3808
              </a>
              <!-- Se estivermos em um dispositivo mobile  -->
              <!-- <a href="https://wa.me/5522999953808" target="_blank"> (22)99995-3808 </a>  -->
              * Segunda até Sexta - Horário: 08:00/12:00 hs 13:30/18:00 hs *
            </v-card-text>
          </v-card>
        </v-footer>

        <!-- Componentes globais -->
        <Toast />
        <OpenTicket />
      </v-app>
    </div>
  </div>
</template>

<script>
import Toast from "./components/CGIToast";
import Drawer from "./components/NavigationDrawer";
import Toolbar from "./components/Toolbar";
import OpenTicket from "./components/CGIOpenTicket";

export default {
  name: "App",

  components: {
    Toast,
    Drawer,
    Toolbar,
    OpenTicket,
  },

  data: () => ({}),
};
</script>

<style lang="scss">
@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-balham.css";

.container-application {
  // padding: 10px;
  padding-top: 10px;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 45px;
}
</style>
