import httpApi from "@/plugins/http";

const router = "basedeconhecimento";

export const HttpRequestBaseDeConhecimento = {
  /**
   * Lista todos os usuário com ou sem filtro
   */
  get: (filter) => {
    return httpApi.get(`${router}/listar`, {
      params: filter,
    });
  },

  getById(id) {
    return httpApi.get(`${router}/${id}`);
  },

  getModuloBaseConhecimento() {
    return httpApi.get(`${router}/modulosBaseConhecimento`);
  },

  postBaseDeConhecimento: (params) => {
    return httpApi.post(`${router}`, params);
  },

  putBaseDeConhecimento: (params) => {
    return httpApi.post(`${router}/alterar/${params.id}`, params);
  },
};
