<template>
  <div>
    <CGIModal
      :exibir="view.modal.show"
      :titulo="view.title"
      :loading="view.loading"
      :fullScreen="false"
      :max-width="'900px'"
      @clickCancelar="close()"
      @clickConfirmar="submit()"
    >
      <template v-slot:body>
        <v-form ref="form" v-model="view.valid" lazy-validation>
          <v-card outlined class="mb-4">
            <v-card-text>
              <v-row dense>
                <v-col cols="12" md="2">
                  <span>
                    <b>Número da Tarefa:</b>
                    <br />
                    {{ model.id }}
                  </span>
                </v-col>
                <v-col cols="12" md="2">
                  <span>
                    <b>Cliente:</b>
                    <br />
                    {{ model.nomeCliente }}
                  </span>
                </v-col>
                <v-col cols="12" md="2">
                  <span>
                    <b>Soliciante:</b>
                    <br />
                    {{ model.nomeClienteUsuario }}
                  </span>
                </v-col>
                <v-col cols="12" md="6">
                  <span>
                    <b>Título:</b>
                    <br />
                    {{ model.tituloTarefa }}
                  </span>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <span>
                    <b>Dados da Tarefa:</b>
                    <br />
                    <span v-html="model.obsTarefa" />
                  </span>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <span>
                    <b>Teste da Tarefa:</b>
                    <br />
                    {{model.testesTarefa}}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-row dense>
            <v-col cols="12" md="12">
              <CGITextEditor
                class="mb-4"
                v-model="model.obsAtualizacao"
                label="Observações da Tarefa"
                placeholder="Entre com as observações da tarefa"
              />
            </v-col>

            <v-col cols="12" md="12">
              <v-textarea
                class="overline font-weight-black"
                v-model="model.comandosSQL"
                counter
                label="SQLs da Tarefa"
                type="text"
                rows="5"
                hint="Entre com os comandos SQL"
                dense
                outlined
              />
            </v-col>

            <v-row dense>
              <v-col cols="12" md="8">
                <CGITextField
                  label="Branch"
                  type="text"
                  hint="Entre com o título da Tarefa"
                  v-model="model.branch"
                  counter="200"
                />
              </v-col>
              <v-col cols="12" md="4">
                <CGICurrency
                  label="Tempo do Atend."
                  hint="Entre com o tempo do atendimento"
                  type="int"
                  v-model="model.tempo"
                />
              </v-col>
            </v-row>
            <v-col cols="12" md="12">
                <v-checkbox
                    class="mt-1 ml-1"
                    v-model="model.DevolverParaSuporte"
                    label="Devolver Para o Suporte"
                    hint="Define se o Ticket ira ser devolvido para o Suporte"
                  />
            </v-col>
             <v-col v-if="model.DevolverParaSuporte" cols="12" md="12">
                <CGITextEditor
                v-model="model.obsAtendimento"
                label="Obs do atendimento"
                placeholder="Entre com a obs. do atendimento"
              />
            </v-col>
          </v-row>
        </v-form>
      </template>
    </CGIModal>
  </div>
</template>

<script>
import { HttpRequestTask } from "./http";
import { HttpRequestTicket } from "../ticket/http";
import CGIModal from "@/components/CGIModal";
import CGITextField from "@/components/CGITextField";
import CGICurrency from "@/components/CGICurrency";
import CGITextEditor from "@/components/CGITextEditor";

const objectModel = {
  id: 0,

  nomeCliente: "",
  nomeClienteUsuario: "",
  titulo: "",
  solicitacao: "",
  dataCadastro: "",

  tituloTarefa: "",
  obsTarefa: "",
  testesTarefa: "",
  usuarioTarefaId: 0,
  statusTarefaId: 0,

  obsAtualizacao: "",
  comandosSQL: "",
  branch: "",
  tempo: 0,
  DevolverParaSuporte:false,
  obsAtendimento:"",
};

export default {
  name: "SetTask",

  components: {
    CGIModal,
    CGITextField,
    CGICurrency,
    CGITextEditor
  },

  data() {
    return {
      model: Object.assign({}, objectModel),
      view: {
        modal: {
          show: false
        },
        loading:false,
        rules: {
          required: value => !!value || "Campo Obrigatório.",
          requiredTempo: value => value == 0 || "Informe o valor"
        }
      }
    };
  },

  props: {},

  methods: {
    openModal(ticketId) {
      this.model = Object.assign({}, objectModel);
      this.view.modal.show = true;
      this.view.title = `Atualizar - Tarefa [${ticketId}]`;
      this.model.ticketId = Object.assign(ticketId);
      this.load(ticketId);
    },
    close() {
      this.model = Object.assign({}, objectModel);
      this.view.modal.show = false;
    },
    load(ticketId) {
      let proTicket = HttpRequestTicket.getById(ticketId);

      Promise.all([proTicket]).then(response => {
        this.model = response[0].data.data;
        this.view.loading = false;
      });
    },
    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.view.loading = true;

      HttpRequestTask.putUpdateTask(this.model)
        .then(() => {
          this.notify("Tarefa definida com sucesso!", "success");
          this.view.loading = false;
          this.close();
          this.$emit("onSuccess");
        })
        .catch(error => {
          console.log(error);
          let message = error.response.data.message;
          this.notify(message, "error");
          this.view.loading = false;
          return;
        });
    }
  }
};
</script>

<style></style>
