<template>
  <div>
    <CGIModal
      :loading="view.loading"
      :exibir="view.modal.show"
      :titulo="view.action"
      :fullScreen="true"
      :scrollable="true"
      @clickCancelar="close"
      @clickConfirmar="submit"
    >
      <template v-slot:body>
        <v-form ref="form" v-model="view.valid" lazy-validation>
          <v-row dense>
            <v-col cols="12" md="2">
              <CGITextField
                label="Id"
                hint="Id da base"
                v-model="model.id"
                disabled
              />
            </v-col>

            <v-col cols="12" md="10">
              <CGITextField
                label="Título"
                type="text"
                hint="Entre com o título"
                :rules="[view.rules.required]"
                required
                v-model="model.titulo"
              />
            </v-col>
            <v-col cols="12" md="4">
              <CGIComboBox
                v-model="model.moduloId"
                route="/combo/modulo"
                label="Módulo"
                hint="Selecione o módulo"
                :itemsAsync="true"
                required
                :rules="[view.rules.required]"
              />
            </v-col>
            <v-col cols="12" md="4">
              <CGIComboBox
                v-model="model.releaseId"
                route="/combo/release"
                label="Release de lançamento"
                :setTodos="true"
                required
                :rules="[view.rules.required]"
                :itemsAsync="true"
              />
            </v-col>
            <v-col cols="12" md="4">
              <CGIComboBox
                v-model="model.internoExternoId"
                route="/combo/internoexterno"
                label="Interno ou Externo"
                hint="Selecione tickets internos ou externos"
                :setTodos="true"
                required
                :rules="[view.rules.required]"
                :itemsAsync="true"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="9" md="12">
              <CGITextEditor
                class="mb-4"
                v-model="model.conteudo"
                :rules="[view.rules.required]"
                label="Conteúdo"
                placeholder="Entre com o conteúdo"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-file-input
                label="Arquivo WORD"
                truncate-length="15"
                v-model="model.ArquivoWord"
              ></v-file-input>
            </v-col>
            <v-col cols="12" md="3">
              <v-file-input
                label="Arquivo PDF"
                v-model="model.ArquivoPDF"
                truncate-length="15"
              ></v-file-input>
            </v-col>
            <v-col cols="12" md="6">
              <CGITextField
                label="Resumo"
                type="text"
                hint="Entre com o Resumo"
                :rules="[view.rules.required]"
                required
                v-model="model.resumo"
              />
            </v-col>
          </v-row>
        </v-form>
      </template>
    </CGIModal>
  </div>
</template>
<script>
import { HttpRequestBaseDeConhecimento } from "./http";
import CGIModal from "@/components/CGIModal";
import CGITextField from "@/components/CGITextField";
import CGITextEditor from "@/components/CGITextEditor";
import CGIComboBox from "@/components/CGICombobox";

const objectModel = {
  id: 0,
  titulo: "",
  conteudo: "",
  resumo: "",
  moduloId: 0,
  ArquivoWord: {},
  ArquivoPDF: {},
};

export default {
  name: "",

  components: {
    CGIModal,
    CGITextField,
    CGITextEditor,
    CGIComboBox,
  },

  props: {},

  data() {
    return {
      model: objectModel,
      view: {
        action: "Incluindo nova base de conhecimento",
        conteudoModel: "",
        loading: false,
        modal: {
          show: false,
        },
        rules: {
          required: (value) => !!value || "Campo Obrigatório.",
        },
      },
    };
  },

  computed: {},

  watch: {},

  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (
        this.model.id == undefined ||
        this.model.id == null ||
        this.model.id == 0
      ) {
        this.save();
      } else {
        this.update();
      }
    },

    save() {
      this.view.loading = true;

      HttpRequestBaseDeConhecimento.postBaseDeConhecimento(this.model)
        .then(() => {
          this.view.modal.show = false;
          this.notify("Base de conhecimento incluído com sucesso", "success");
          close();
        })
        .catch((error) => {
          let message = error.response.data.message;
          this.notify(message, "error");
        })
        .then(() => {
          this.view.loading = false;
        });
    },
    update() {
      HttpRequestBaseDeConhecimento.putBaseDeConhecimento(this.model)
        .then(() => {
          this.notify("Base de conhecimento alterado com sucesso", "success");
          this.view.modal.show = false;
          close();
        })
        .catch((error) => {
          let message = error.response.data.message;
          this.notify(message, "error");
        })
        .then(() => {
          this.view.loading = false;
          this.close();
        });
    },

    close() {
      this.model = {};
      this.view.modal.show = false;
    },

    add() {
      this.view.modal.show = true;
      this.view.action = "Incluindo base de conhecimento";
    },

    change(cliente) {
      this.view.modal.show = true;
      this.view.action =
        "Alterando base de conhecimento [" + cliente.nome + "]";

      this.view.loading = true;

      let proCliente = HttpRequestBaseDeConhecimento.getById(cliente.id);

      Promise.all([proCliente]).then((response) => {
        this.model = response[0].data.data;

        this.view.loading = false;
      });
    },
  },
};
</script>
<style></style>
