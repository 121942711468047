<template>
  <div>
    <v-card outlined>
      <v-card-title>Anexos</v-card-title>
      <v-card-text>
        <v-row v-if="!onlyList">
          <v-col cols="12" md="10">
            <v-file-input
              outlined
              dense
              multiple
              small-chips
              counter
              v-model="inputFiles"
              name="upload"
              label="Anexos"
              placeholder="Selecione os arquivos que deseja anexar"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-btn icon dark color="primary" @click="addFiles()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-simple-table dense v-if="listFiles.length > 0">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Lista de anexos</th>
                    <th class="text-left"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in listFiles" :key="item.name">
                    <td>{{ item.name }}</td>
                    <td>
                      <v-btn v-if="!onlyList" color="red" icon @click="remove(item, index)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                      <v-btn v-if="item.path" color="green" icon @click="download(item)">
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import httpApi from "@/plugins/http";
export default {
  props: {
    value: null,
    routerDownload: {
      type: String,
      default: "",
      require
    },
    onlyList: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inputFiles: [],
      listFiles: []
    };
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue != oldValue) {
        this.listFiles = newValue;
      }
    }
  },
  methods: {
    addFiles() {
      this.inputFiles.forEach(item => {
        this.listFiles.push(item);
      });
      this.inputFiles = [];
      this.$emit("input", this.listFiles);
    },

    remove(item, index) {
      this.listFiles.splice(index, 1);
      this.$emit("input", this.listFiles);
      if (item.id != undefined) {
        this.$emit("removed", item.id);
      }
    },

    download(item) {
      httpApi
        .get(`${this.routerDownload}/download-anexo/${item.id}`)
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", item.name); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
    }
  }
};
</script>

<style>
</style>