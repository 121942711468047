<template>
  <v-content>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4 lg4>
          <v-card class="elevation-2 pa-4">
            <v-card-text>
              <v-form ref="form" v-model="view.valid" lazy-validation>
                <CGITextField
                  append-icon="mdi-account"
                  name="login"
                  label="Usuário"
                  type="text"
                  hint="Login do usuário"
                  required
                  :rules="[view.rules.required]"
                  v-model="model.login"
                />

                <CGITextField
                  append-icon="mdi-lock"
                  name="password"
                  label="Senha"
                  id="password"
                  type="password"
                  hint="Senha do usuário"
                  required
                  :rules="[view.rules.required]"
                  v-model="model.senha"
                />
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-btn
                block
                color="primary"
                @click="login"
                :loading="view.loading.isLoading"
                >Login</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>
<script>
import { HttpRequestAuth } from "./http";
import CGITextField from "@/components/CGITextField";

export default {
  name: "Auth",

  props: {},

  components: {
    CGITextField,
  },

  data() {
    return {
      model: {
        login: "",
        senha: "",
      },
      view: {
        valid: false,
        loading: {
          isLoading: false,
        },
        rules: {
          required: (value) => !!value || "Campo Obrigatório.",
        },
      },
    };
  },

  mounted() {},

  watch: {},

  computed: {},

  methods: {
    login() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.view.loading.isLoading = true;

      //console.log(this.model);

      HttpRequestAuth.login(this.model)
        .then((response) => {
          let data = response.data.data;

          /*if (data.trocarSenhaProximoLogon) {
            this.$refs.changePassword.show(data);
          } else {
            this.authenticate(data);
          }*/
          this.authenticate(data);
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status != 500) {
            let message = error.response.data.message;
            this.notify(message, "error");
          }
        })
        .then(() => {
          this.view.loading.isLoading = false;
        });
    },

    setLocalStorage(data) {
      // console.log(data);

      localStorage.setItem("token", data.token);
      localStorage.setItem("user", JSON.stringify(data));
      localStorage.setItem("currentProject", JSON.stringify(data.projeto));
    },

    authenticate(params) {
      this.setLocalStorage(params);

      let queryString = window.location.search.substring(1);
      let urlSearchParams = new URLSearchParams(queryString);
      let redirect = urlSearchParams.get("redirect");

      //let dash = params.dashboardInicial;

      if (params.clienteId > 0) {
        this.$router.push("/client-dash");
      } else if (redirect == null) {
        //if (dash == "DEV") this.$router.push("/task/resume");
        //else if (dash == "SUPORTE") this.$router.push("/ticket/resume");
        //else this.$router.push("/");

        this.$router.push("/dash");
      } else {
        this.$router.push(redirect);
      }
    },
  },
};
</script>
