<template>
  <div>
    <CGIModal
      :loading="view.loading"
      :exibir="view.modal.show"
      :titulo="view.action"
      :fullScreen="false"
      :scrollable="false"
      @clickCancelar="close"
      @clickConfirmar="submit"
    >
      <template v-slot:body>
        <v-form ref="form" v-model="view.valid" lazy-validation>
          <v-row>
            <v-col cols="12" md="12">
              <v-row dense>
                <v-col cols="12">
                  <CGITextField
                    label="Título"
                    type="text"
                    hint="Entre com o título do ticket"
                    counter="100"
                    :rules="[view.rules.required]"
                    required
                    v-model="model.titulo"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <CGITextEditor
                    class="mb-4"
                    v-model="model.solicitacao"
                    :dateModel="view.solicitacaoModel"
                    label="Solicitação"
                    placeholder="Entre com a solicitação"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" md="6">
                  <CGIComboBox
                    v-model="model.prioridade"
                    route="/combo/prioridade"
                    label="Prioridade"
                    hint="Defina a prioridade do ticket"
                    :itemsAsync="true"
                    required
                    :rules="[view.rules.required]"
                  />
                </v-col>
              </v-row>
               <strong>Informar o Máximo de Informações no Ticket <br/>Exemplo: Prints,Link,Rotas</strong>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </CGIModal>

  </div>
</template>
<script>
import { HttpRequestTicket } from "./http";
import { mask } from "vue-the-mask";
import CGIModal from "@/components/CGIModal";
import CGITextField from "@/components/CGITextField";
import CGIComboBox from "@/components/CGICombobox";
//import CGIDataTable from "@/components/CGIDataTable";
//import CGIDatePicker from "@/components/CGIDatePicker";
import CGITextEditor from "@/components/CGITextEditor";
//import CGIFileUpload from "@/components/CGIFileUpload";
//import CGICurrency from "@/components/CGICurrency";


const objectModel = {
  id: 0,
  clienteUsuarioId: 0,
  usuarioId: 0,
  prioridade: null,
  tipoTicketId: 19,
  solicitacao: "",
  titulo: "",
  statusId: 19,
  solucaoApresentada: "",
  obsConclusao: "",
  usuarioConclusaoId: 0,
  informarConclusao: false,
  informarAtualizacao: false,
  tituloAtualizacao: "",
  statusAtualizacaoId: 0,
  tempoAtualizacao: 0,
  obsAtualizacao: "",
  ehTarefa: false,
  internoExterno: 2,
  moduloPrincipalId: 0,
  anexos: [],
  removed: [],
  definirTarefa: false,
};

export default {
  name: "Form",

  directives: {
    mask,
  },

  components: {
    CGIModal,
    CGITextField,
    CGIComboBox,
   //CGIDataTable,
    //CGIDatePicker,
    CGITextEditor,
    //CGIFileUpload,
    //CGICurrency,
  },

  props: {},

  data() {
    return {
      model: Object.assign({}, objectModel),
      view: {
        action: "Incluindo novo ticket",
        loading: false,
        lists: {
          historico: [],
        },
        colunas: {
          historico: [
            {
              text: "Id",
              value: "id",
            },
            {
              text: "Data",
              value: "dataCadastro",
              type: "datetime",
            },
            {
              text: "Título",
              value: "titulo",
            },
            {
              text: "Obs.",
              value: "obs",
              type: "html",
            },
            {
              text: "Usuário",
              value: "nomeUsuario",
            },
          ],
          clientes: [
            {
              text: "",
              value: "selecionado",
              type: "checkbox",
              config: {
                disabled: false,
              },
            },
            {
              text: "Id",
              value: "id",
            },
            {
              text: "Cliente",
              value: "identificacao",
            },
          ],
        },
        modal: {
          show: false,
        },
        rules: {
          required: (value) => !!value || "Campo Obrigatório.",
          requiredTempo: (value) => value == 0 || "Informe o valor",
        },
        ticketId: 0,
        dateModelPrevisao: "",
        dateModelCadastro: "",
        solicitacaoModel: "",
      },
    };
  },

  computed: {},

  watch: {},

  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      let user = JSON.parse(localStorage.getItem("user"));
      this.model.clienteUsuarioId = user.clienteId;

      if (
        this.model.id == undefined ||
        this.model.id == null ||
        this.model.id == 0
      ) {
        this.save();
      } else {
        this.update();
      }
    },

    save() {
      this.view.loading = true;
      HttpRequestTicket.postTicket(this.model)
        .then((data) => {
          this.model.id = data.data.data;

          this.notify("Ticket incluído com sucesso", "success");
          this.close();
          this.$emit("onSuccess");
        })
        .catch((error) => {
          this.view.loading = false;

          let message = error.response.data.message;
          this.notify(message, "error");
        })
        .then(() => {
          this.view.loading = false;
          this.$emit("onSuccess");
        });
    },

    update() {
      this.view.loading = true;
      let putTicket = HttpRequestTicket.putTicket(this.model);

      Promise.all([putTicket])
        .then(() => {
          this.notify("Ticket alterado com sucesso", "success");
          this.change(this.model);
          this.close();
          this.$emit("onSuccess");
        })
        .catch((error) => {
          console.log(error);
          let message = error.response.data.message;
          this.notify(message, "error");
          this.view.loading = false;

        })
    },

    close() {
      this.model = objectModel;
      this.view.modal.show = false;
      this.view.lists.historico = [];
      this.$emit("onSuccess");
    },

    add() {
      this.model = Object.assign({}, objectModel);
      this.view.modal.show = true;
      this.view.action = "Incluindo novo ticket";
      this.view.loading = false;
    },

    change(ticket) {
      this.model = Object.assign({}, objectModel);
      this.view.modal.show = true;
      this.view.ticketId = ticket.id;
      this.view.action = "Alterando ticket [" + ticket.id + "]";
      this.view.loading = true;

      let proTicket = HttpRequestTicket.getById(ticket.id);

      Promise.all([proTicket]).then((response) => {
        this.model = response[0].data.data;

        this.view.loading = false;

        this.dataProcessing();
      });
    },

    /**
     * Ao clicar ação de abrir modal para lançar Novo Atendimento
     */
    onClickNewService() {
      this.$refs.InformService.openModal(
        this.view.ticketId,
        this.model.titulo,
        this.model.internoExterno
      );
    },

    callChange() {
      this.change(this.model);
    },

    dataProcessing() {
      switch (this.model.prioridade) {
        case "U":
          this.model.prioridade = 1;
          break;
        case "A":
          this.model.prioridade = 2;
          break;
        case "M":
          this.model.prioridade = 3;
          break;
        case "B":
          this.model.prioridade = 4;
          break;
      }

      switch (this.model.internoExterno) {
        case "I":
          this.model.internoExterno = 1;
          break;
        case "E":
          this.model.internoExterno = 2;
          break;
      }
    },

    removeAttachments(item) {
      if (this.model.removed == undefined) this.model.removed = [];
      this.model.removed.push(item);
    },
  },
};
</script>
<style></style>
