<template>
  <div>
    <CGIModal
      :loading="view.loading"
      :exibir="view.modal.show"
      :titulo="'Atualizar Certificado Digital'"
      @clickCancelar="close"
      @clickConfirmar="submit"
    >
      <template v-slot:body>
        <v-form ref="form" v-model="view.valid" lazy-validation>
          <v-row dense>
            <v-col cols="12" md="5">
              <CGITextField label="Cliente" type="text" v-model="model.identification" disabled />
            </v-col>
            <v-col cols="12" md="7">
              <CGIComboBox
                v-model="model.companyId"
                :route="'/combo/empresas/' + model.environment"
                label="Empresa"
                hint="Defina a Empresa do cliente"
                :itemsAsync="true"
                required
                :rules="[view.rules.required]"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="5">
              <CGITextField
                label="Senha do Certificado"
                type="text"
                hint="Entre com a senha do certificado digital"
                :rules="[view.rules.required]"
                required
                v-model="model.passCert"
              />
            </v-col>
            <v-col cols="12" md="7">
              <v-file-input
                accept=".pfx"
                outlined
                dense
                name="upload"
                label="Certificado"
                placeholder="Selecione o arquivo do certificado digital"
                @change="loadTextFromFile"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Empresa</th>
                      <th class="text-center">Certificado Cadastrado</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in view.lists.certificates" :key="item.nome">
                      <td>{{ item.nome }}</td>
                      <td v-if="item.stringCertificado" class="text-center">
                        <v-icon color="green">mdi-check-bold</v-icon>
                      </td>
                      <td v-else color="red" class="text-center">
                        <v-icon>mdi-cancel</v-icon>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </CGIModal>
  </div>
</template>

<script>
import { HttpRequestCertificado } from "./http";
import { HttpRequestCliente } from "../../http";
import CGIModal from "@/components/CGIModal";
import CGITextField from "@/components/CGITextField";
import CGIComboBox from "@/components/CGICombobox";

const objectBase = {
  identification: "",
  companyId: 0,
  passCert: "",
  stringCert: "",
  environment: ""
};

export default {
  name: "",
  components: {
    CGIModal,
    CGITextField,
    CGIComboBox
  },
  data() {
    return {
      model: { ...objectBase },
      view: {
        loading: false,
        lists: {
          certificates: []
        },
        modal: {
          show: false
        },
        rules: {
          required: value => !!value || "Campo Obrigatório."
        }
      }
    };
  },
  methods: {
    openModal(client) {
      this.view.loading = true;
      this.model.identification = client.identificacao;

      // Pegando qual o nome do banco de dados
      let link = client.link.split("//");
      let environment = link[1].split(".")[0];
      this.model.environment = environment;

      // Buscando informações sobre os certificados.
      HttpRequestCliente.getListCerts(this.model.environment).then(response => {
        this.view.lists.certificates = response.data.data;
        this.view.loading = false;
      });

      this.$forceUpdate();

      this.view.modal.show = true;
    },
    loadTextFromFile(file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = event => {
        let string = event.target.result.split(",")[1]; // Pegando somente a parte string do certificado
        this.model.stringCert = string;
      };
    },
    close() {
      this.model = { ...objectBase };
      this.view.lists.certificates = [];
      this.view.modal.show = false;
      this.view.loading = false;
    },
    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.view.loading = true;

      HttpRequestCertificado.postCert(this.model)
        .then(() => {
          this.notify("Cliente alterado com sucesso", "success");
          close();
        })
        .catch(error => {
          let message = error.response.data.message;
          this.notify(message, "error");
          this.view.loading = false;
        })
        .then(() => {
          this.close();
        });
    }
  }
};
</script>

<style>
</style>