<template>
  <div>
    <div></div>
    <CGIDataTable
      :loading="view.loading"
      :columns="view.columns"
      :items="view.listas.clientes"
      @clickRemove="(value) => onClickRemove(value)"
      @clickChange="(value) => onClickChange(value)"
    >
      <!-- Filtro -->
      <template v-slot:filtro>
        <v-row dense>
          <v-col cols="12">
            <v-row dense>
              <v-col cols="12" md="2">
                <CGIDatePicker
                  v-model="model.dataInicial"
                  label="Data Inicial"
                />
              </v-col>

              <v-col cols="12" md="2">
                <CGIDatePicker v-model="model.dataFinal" label="Data Final" />
              </v-col>

              <v-col cols="12" md="8">
                <CGIComboBox
                  v-model="model.clienteId"
                  route="/combo/cliente"
                  label="Cliente"
                  :setTodos="true"
                  :itemsAsync="true"
                />
              </v-col>
              <v-col cols="12" md="4">
                <CGIComboBox
                  v-model="model.listaStatusReuniaoId"
                  route="/combo/statusreuniao"
                  label="Status"
                  hint="Selecione o status"
                  multiple
                  small_chips
                  :itemsAsync="true"
                />
              </v-col>
              <v-col cols="12" md="4">
                <CGIComboBox
                  v-model="model.listaTipoReuniaoId"
                  route="/combo/tiporeuniao"
                  label="Tipo de Reunião"
                  hint="Selecione o tipo de reunião"
                  multiple
                  small_chips
                  :itemsAsync="true"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn color="info" @click="onClickAdd">Novo</v-btn>
            <v-btn
              :loading="view.loading"
              class="ma-2"
              color="primary"
              @click="filter"
            >
              Filtrar
              <v-icon right dark>mdi-filter</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </CGIDataTable>
    <Form ref="form" />
    <Delete titulo="Atenção" ref="excluir" />
  </div>
</template>
<script>
import { HttpRequestReuniao } from "./http";
import Form from "./Form";
import Delete from "@/components/CGIDelete";
import CGIDataTable from "@/components/CGIDataTable";
import EventBus from "@/plugins/event-bus";
import CGIDatePicker from "@/components/CGIDatePicker";
import CGIComboBox from "@/components/CGICombobox";

export default {
  name: "ListarReuniao",

  /**
   * Componentes filhos utilizados
   */
  components: {
    Form,
    Delete,
    CGIDataTable,
    CGIDatePicker,
    CGIComboBox,
  },

  /**
   * Variáveis do componente
   */
  data() {
    return {
      text: "",
      model: {
        nome: "",
        identificacao: "",
      },
      view: {
        columns: [
          {
            text: "Id",
            value: "id",
          },
          {
            text: "Status",
            value: "nomeStatus",
            type: "chip",
            config: {
              dark: true,
              label: true,
              color: (item) => {
                if (item.nomeStatus == "Agendada") return "blue";
                if (item.nomeStatus == "Concluída") return "green darken-3";
                if (item.nomeStatus == "Em andamento") return "#FFD700";
                if (item.nomeStatus == "Reagendada") return "blue darken-2";
                if (item.nomeStatus == "Cancelada") return "red";
                if (item.nomeStatus == "Cliente ausente") return "#FF8C00";
              },
            },
          },
          {
            text: "Dt. Cad.",
            value: "dataCadastro",
            type: "datetime",
          },
          {
            text: "Cliente",
            value: "nomeCliente",
          },
          {
            text: "Título",
            value: "titulo",
          },
          {
            text: "Tipo",
            value: "nomeTipoReuniao",
          },
          {
            text: "Resumo de Tickets",
            value: "resumoTickets",
            type: "html",
          },
          {
            text: "Datas",
            value: "resumoDatas",
            type: "html",
          },
          {
            text: "Participantes",
            value: "resumoParticipantes",
            type: "html",
          },
          {
            text: "",
            type: "actions",
          },
        ],
        loading: false,
        listas: {
          clientes: [],
        },
      },
    };
  },

  /**
   * Método chamado conforme ciclo de vida do vue
   */
  mounted() {
    this.load();
    /**
     * EventBus - CALL_LOAD
     * Usado para chamar os métodos de LOAD da página
     */
    var self = this;
    EventBus.$on("CALL_LOAD", () => {
      self.load();
    });
  },

  /**
   * Objeto onde declaramos métodos do componente
   */
  methods: {
    /**
     * Carregando dados iniciais
     */
    load() {
      this.view.loading = true;
      HttpRequestReuniao.get(this.model).then((response) => {
        this.view.listas.clientes = response.data.data;
        this.view.loading = false;
      });
    },

    /**
     * Ao clicar ação de filtrar
     */
    filter() {
      this.load();
    },

    /**
     * Ao clicar ação de remover um usuário
     */
    onClickRemove(usuario) {
      this.$refs.excluir.abrirModal(usuario, "/reuniao/excluir");
    },

    /**
     * Ao clicar ação de Incluir
     */
    onClickAdd() {
      this.$refs.form.add();
    },

    /**
     * Ao clicar ação de alterar
     */
    onClickChange(cliente) {
      this.$refs.form.change(cliente);
    },
  },

  /**
   * Ao destruir, desligamos o EventBus para não fazer chamadas desnecessárias
   */
  destroyed() {
    EventBus.$off("CALL_LOAD");
  },
};
</script>
<style></style>
