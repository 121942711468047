import httpApi from "@/plugins/http";

const router = "modulo";

export const HttpRequestModulo = {
  /**
   * Lista todos os Modulos com ou sem filtro
   */
  get: (filter) => {
    return httpApi.get(`${router}/listar`, {
      params: filter,
    });
  },

  /**
   * Busca um usuário por Id
   * @param {int} id
   */
  getById(id) {
    return httpApi.get(`${router}/${id}`);
  },

  postModulo: (params) => {
    return httpApi.post(`${router}`, params);
  },

  putModulo: (params) => {
    return httpApi.post(`${router}/alterar/${params.id}`, params);
  },
};
