<template>
  <div>
    <ModalBase
      :exibir="view.modal.exibir"
      :titulo="'Novo Histórico para o Cliente'"
      @clickCancelar="close()"
      @clickConfirmar="submit"
    >
      <template v-slot:body>
        <v-form ref="form" v-model="view.valid" lazy-validation>
          <v-row align="center">
            <v-col cols="12">
              <CGIComboBox
                v-model="model.clienteId"
                route="/combo/cliente"
                label="Cliente"
                hint="Selecione o cliente"
                :itemsAsync="true"
              />
            </v-col>

            <v-col cols="12">
              <CGITextField
                label="Título"
                type="text"
                hint="Entre com o título"
                :rules="[view.rules.required]"
                required
                v-model="model.titulo"
              />
            </v-col>

            <v-col cols="12">
              <v-textarea
                v-model="model.Obs"
                counter
                label="Obs"
                type="text"
                rows="4"
                maxlength="300"
                hint="Entre com a observação sobre esta solicitação"
                dense
                outlined
                :rules="[view.rules.required]"
              />
            </v-col>
          </v-row>
        </v-form>
      </template>
    </ModalBase>
  </div>
</template>
<script>
import ModalBase from "@/components/CGIModal";
import CGITextField from "@/components/CGITextField";
import CGIComboBox from "@/components/CGICombobox";
import { HttpRequestCliente } from "./http";

export default {
  name: "",

  components: {
    ModalBase,
    CGIComboBox,
    CGITextField
  },

  props: {},

  data() {
    return {
      model: {
        clienteId: 0,
        Titulo: "",
        Obs: ""
      },
      view: {
        modal: {
          exibir: false
        },
        rules: {
          required: value => !!value || "Campo Obrigatório."
        }
      }
    };
  },

  computed: {},

  watch: {},

  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.save();
    },

    save() {
      this.view.loading = true;

      HttpRequestCliente.postHistoricoCliente(this.model)
        .then(() => {
          this.model = {};
          this.view.modal.exibir = false;
          this.notify("Histórico incluído com sucesso", "success");
        })
        .catch(error => {
          let message = error.response.data.message;
          this.notify(message, "error");
        })
        .then(() => {
          this.view.loading = false;
        });
    },

    abrirModal() {
      this.view.modal.exibir = true;
    },

    /**
     * Precisamos limpar a model ao fechar modal
     */
    close() {
      this.view.modal.exibir = false;
      this.model = {};
    }
  }
};
</script>
<style></style>
