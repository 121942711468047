var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',{attrs:{"large":"","block":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.load()}}},[_c('v-badge',{attrs:{"left":"","color":"red","overlap":"","bordered":"","content":_vm.view.lists.items.length}},[_c('span',[_vm._v("..")])]),_vm._v(" "+_vm._s(_vm.view.title)+" "),_c('v-icon',[_vm._v("mdi-refresh")])],1),_vm._l((_vm.view.lists.items),function(item){return _c('div',{key:item.solicitacao},[_c('v-card',{staticClass:"mx-auto",attrs:{"raised":"","color":_vm.view.color}},[_c('v-card-text',[_c('div',[_c('v-chip',{attrs:{"dark":"","small":"","color":"indigo darken-1"}},[_vm._v(_vm._s(item.id))]),_c('h3',{staticClass:"mb-0"},[_vm._v(_vm._s(item.titulo))])],1),_c('div',{staticClass:"text--primary"},[_vm._v(_vm._s(item.nomeCliente))])]),_c('v-card-actions',[_c('v-row',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.service == 'tickets'),expression:"service == 'tickets'"}],staticClass:"ml-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","depressed":"","small":""},on:{"click":function($event){return _vm.onClickNewService(item)}}},on),[_c('v-icon',[_vm._v("mdi-account-voice")])],1)]}}],null,true)},[_c('span',[_vm._v("Informar Atendimento")])])],1),_c('div',{staticClass:"ml-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","depressed":"","small":""},on:{"click":function($event){return _vm.onClickViewTask(item)}}},on),[_c('v-icon',[_vm._v("mdi-comment-edit-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Visualizar/Editar Tarefa")])])],1),_c('div',{staticClass:"ml-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","depressed":"","small":""},on:{"click":function($event){return _vm.onClickEditTask(item)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Alterar Ticket")])])],1),_c('div',{staticClass:"ml-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"warning","depressed":"","small":""},on:{"click":function($event){return _vm.onClickSetOK(item)}}},on),[_c('v-icon',[_vm._v("mdi-check")])],1)]}}],null,true)},[_c('span',[_vm._v("Define este ticket já verificado por você")])])],1)])],1),_c('v-divider'),_c('div',[(_vm.service == 'tickets')?_c('v-card-text',{attrs:{"color":"red"},domProps:{"innerHTML":_vm._s(item.solicitacao)}}):_c('v-card-text',{domProps:{"innerHTML":_vm._s(item.obsAtendimento)}})],1)],1),_c('br')],1)}),_c('SetTask',{ref:"SetTask",on:{"onSuccess":function($event){return _vm.load()}}}),_c('UpdateTask',{ref:"UpdateTask",on:{"onSuccess":function($event){return _vm.load()}}}),_c('FormTicket',{ref:"FormTicket",on:{"onSuccess":function($event){return _vm.load()}}}),_c('InformService',{ref:"InformService",on:{"onSuccess":function($event){return _vm.load()}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }