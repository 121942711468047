<template>
  <div>
    <CGIModal
      :loading="view.loading"
      :exibir="view.showModal"
      :titulo="view.title"
      :fullScreen="true"
      :scrollable="true"
      @clickCancelar="close"
      @clickConfirmar="close"
    >
      <template v-slot:body>
        <div>
          <v-row>
            <v-col cols="12">
              <v-card outlined class="mb-4">
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" md="3">
                      <span>
                        <b>Número do Ticket:</b>
                        <v-spacer />
                        {{ model.dataTicket.data.id }}
                      </span>
                    </v-col>
                    <v-col cols="12" md="9">
                      <span>
                        <b>Título:</b>
                        <v-spacer />
                        {{ model.dataTicket.data.titulo }}
                      </span>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" md="3">
                      <span>
                        <b>Soliciante:</b>
                        <v-spacer />
                        {{ model.dataTicket.data.nomeClienteUsuario }}
                      </span>
                    </v-col>
                    <v-col cols="12" md="3">
                      <span>
                        <b>Cliente:</b>
                        <v-spacer />
                        {{ model.dataTicket.data.nomeCliente }}
                      </span>
                    </v-col>
                    <v-col cols="12" md="3">
                      <span>
                        <b>Tipo Ticket:</b>
                        <v-spacer />
                        {{ model.dataTicket.data.nomeTipoTicket }}
                      </span>
                    </v-col>
                    <v-col cols="12" md="3">
                      <span>
                        <b>Status Ticket:</b>
                        <v-spacer />
                        {{ model.dataTicket.data.nomeStatus }}
                      </span>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" md="3">
                      <span>
                        <b>Módulo Principal:</b>
                        <v-spacer />
                        {{ model.dataTicket.data.nomeModuloPrincipal }}
                      </span>
                    </v-col>
                    <v-col cols="12" md="3">
                      <span>
                        <b>Privacidade:</b>
                        <v-spacer />
                        {{ model.dataTicket.data.internoExterno }}
                      </span>
                    </v-col>
                    <v-col cols="12" md="3">
                      <span>
                        <b>Tarefa DEV:</b>
                        <v-spacer />
                        {{ model.dataTicket.data.ehTarefa }}
                      </span>
                    </v-col>
                    <v-col cols="12" md="3">
                      <span>
                        <b>Usuário:</b>
                        <v-spacer />
                        {{ model.dataTicket.data.nomeUsuario }}
                      </span>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" md="3">
                      <span>
                        <b>Data Cadastro:</b>
                        <v-spacer />
                        {{ model.dataTicket.data.dataCadastro }}
                      </span>
                    </v-col>
                    <v-col cols="12" md="3">
                      <span>
                        <b>Data Previsão:</b>
                        <v-spacer />
                        {{ model.dataTicket.data.dataPrevisao }}
                      </span>
                    </v-col>

                    <v-col cols="12" md="2">
                      <span>
                        <b>Prioridade:</b>
                        <v-spacer />
                        {{ model.dataTicket.data.prioridade }}
                      </span>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12">
                      <CGITextEditor
                        class="mb-4"
                        v-model="model.dataTicket.data.solicitacao"
                        label="Solicitação"
                        disabled
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-row>
            <v-col cols="12">
              <p class="title text-center">Atendimentos</p>
              <CGIDataTable
                :columns="view.colunas.historicos"
                :items="model.dataTicket.historico"
                :expandedFilter="false"
              />
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:actions class="text-right">
        <v-spacer />
        <v-btn right color="primary" @click="close">Ok</v-btn>
      </template>
    </CGIModal>
  </div>
</template>

<script>
import { HttpRequestTicket } from "../ticket/http";
import CGIModal from "@/components/CGIModal";
//import CGITextField from "@/components/CGITextField";
//import CGIComboBox from "@/components/CGICombobox";
import CGIDataTable from "@/components/CGIDataTable";
//import CGIDatePicker from "@/components/CGIDatePicker";
import CGITextEditor from "@/components/CGITextEditor";
//import CGIFileUpload from "@/components/CGIFileUpload";
//import CGICurrency from "@/components/CGICurrency";

import moment from "moment";

export default {
  components: {
    CGIModal,
    //CGITextField,
    //CGIComboBox,
    CGIDataTable,
    //CGIDatePicker,
    CGITextEditor,
    //CGIFileUpload,
    //CGICurrency,
  },
  data() {
    return {
      model: {
        id: 0,
        dataTicket: {
          data: {},
          historico: [""],
        },
      },
      view: {
        showModal: false,
        loading: true,
        title: "",
        colunas: {
          historicos: [
            {
              text: "Id",
              value: "id",
            },
            {
              text: "Data",
              value: "dataCadastro",
              type: "datetime",
            },
            {
              text: "Título",
              value: "titulo",
            },
            {
              text: "Obs.",
              value: "obs",
              type: "html",
            },
            {
              text: "Usuário",
              value: "nomeUsuario",
            },
          ],
        },
      },
    };
  },

  methods: {
    openModal(id) {
      this.clearModel();
      this.model.id = Object.assign(id);
      this.view.title = `Visualisar Ticket [${id}]`;
      this.view.showModal = true;
      this.view.loading = true;
      this.load(id);
    },
    close() {
      this.clearModel();
      this.view.showModal = false;
    },
    load(id) {
      let proTicket = HttpRequestTicket.getQuickViewById(id);
      let proHistTicket = HttpRequestTicket.getHistorico(id);

      Promise.all([proTicket, proHistTicket]).then((response) => {
        this.model.dataTicket.data = this.dataProcessing(response[0].data.data);
        this.model.dataTicket.historico = response[1].data.data;
        console.log(this.model.dataTicket.historico)
        this.view.loading = false;
      });
    },

    dataProcessing(data) {
      data.dataCadastro = moment(data.dataCadastro).format(
        "DD/MM/YYYY hh:mm:ss"
      );
      data.dataPrevisao = moment(data.dataPrevisao).format("DD/MM/YYYY");
      data.ehTarefa = data.ehTarefa ? "Sim" : "Não";

      return data;
    },

    clearModel() {
      this.model = {
        id: 0,
        dataTicket: {
          data: {},
          historic: [],
        },
        dataTask: {
          data: {},
          historic: [],
        },
      };
    },
  },
};
</script>

<style>
</style>