<template>
  <div>
    <CGIModal
      :loading="view.loading"
      :exibir="view.modal.show"
      :titulo="view.action"
      :fullScreen="false"
      :maxWidth="'1000px'"
      :scrollable="true"
      @clickCancelar="close"
      @clickConfirmar="submit"
    >
      <template v-slot:body>
        <v-form ref="form" v-model="view.valid" lazy-validation>
          <v-row dense>
            <v-col cols="12" md="4">
              <CGITextField label="Id" hint="Id" v-model="model.id" disabled />
            </v-col>

            <v-col cols="12" md="8">
              <CGITextField
                label="Nome"
                type="text"
                hint="Entre com o nome"
                :rules="[view.rules.required]"
                required
                v-model="model.nome"
              />
            </v-col>

            <v-col cols="12" md="4">
              <CGITextField
                label="Login"
                type="text"
                hint="Entre com a login"
                :rules="[view.rules.required]"
                required
                v-model="model.login"
              />
            </v-col>

            <v-col v-if="model.id == 0" cols="12" md="3">
              <CGITextField
                label="Senha de acesso"
                type="password"
                hint="Entre com a senha do usuário"
                :rules="[view.rules.required]"
                required
                v-model="model.senha"
              />
            </v-col>

            <v-col cols="12" md="5">
              <CGITextField
                label="E-Mail"
                type="text"
                hint="Entre com o e-mail"
                :rules="[view.rules.required]"
                required
                v-model="model.eMail"
              />
            </v-col>

            <v-col cols="12" md="4">
              <CGIComboBox
                v-model="model.TipoUsuarioId"
                route="/combo/internoexterno"
                label="Tipo de Usuário"
                hint="Defina qual o tipo de usuário"
                :itemsAsync="true"
                required
                :rules="[view.rules.required]"
              />
            </v-col>

            <v-col cols="12" md="6" v-if="model.TipoUsuarioId == 2">
              <CGIComboBox
                v-model="model.clienteId"
                route="/combo/clienteusuario"
                label="Cliente"
                hint="Defina o Cliente que o usuário pertence"
                @input="(value) => changeCombo(value)"
                :itemsAsync="true"
                required
                :rules="[view.rules.required]"
              />
            </v-col>

            <v-col cols="12" md="2">
              <v-checkbox
                dense
                class="mt-1 ml-1"
                v-model="model.ativo"
                label="Ativo"
                hint="Informe se o cliente está ativo"
              />
            </v-col>
            <v-col cols="12" md="2">
              <v-checkbox
                dense
                class="mt-1 ml-1"
                v-model="model.ehDev"
                label="Desenvolvedor"
                hint="Informe se o usuario é um Desenvolvedor"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <CGIDataTable
                :columns="view.columns.projects"
                :items="view.lists.projects"
              />
            </v-col>
          </v-row>
        </v-form>
      </template>
    </CGIModal>
  </div>
</template>
<script>
import { HttpRequestUsuario } from "./http";
import CGIModal from "@/components/CGIModal";
import CGITextField from "@/components/CGITextField";
import CGIDataTable from "@/components/CGIDataTable";
import { mask } from "vue-the-mask";
import CGIComboBox from "@/components/CGICombobox";

const objectModel = {
  id: 0,
  nome: "",
  login: "",
  eMail: "",
  ativo: true,
  ehDev: false,
  TipoUsuarioId: 0,
  clienteId: null,
  tipoUsuarioStr: "",
};

export default {
  name: "",

  directives: {
    mask,
  },

  components: {
    CGIModal,
    CGITextField,
    CGIDataTable,
    CGIComboBox,
  },

  props: {},

  data() {
    return {
      model: objectModel,
      view: {
        action: "Incluindo novo usuário",
        loading: false,
        lists: {
          projects: [],
        },
        columns: {
          projects: [
            {
              text: "",
              value: "selecionado",
              type: "checkbox",
              config: {
                disabled: false,
              },
              width: 30,
            },
            {
              text: "Nome",
              value: "nome",
            },
          ],
        },
        modal: {
          show: false,
        },
        rules: {
          required: (value) => !!value || "Campo Obrigatório.",
        },
      },
    };
  },

  computed: {},

  watch: {},

  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (
        this.model.id == undefined ||
        this.model.id == null ||
        this.model.id == 0
      ) {
        this.save();
      } else {
        this.update();
      }
    },

    save() {
      this.view.loading = true;

      this.model.projetos = this.view.lists.projects;

      HttpRequestUsuario.postUsuario(this.model)
        .then(() => {
          this.view.modal.show = false;
          this.notify("Usuário incluído com sucesso", "success");
          this.$emit("onSuccess");
          close();
        })
        .catch((error) => {
          let message = error.response.data.message;
          this.notify(message, "error");
        })
        .then(() => {
          this.view.loading = false;
        });
    },
    update() {
      this.model.projetos = this.view.lists.projects;

      HttpRequestUsuario.putUsuario(this.model)
        .then(() => {
          this.notify("Usuário alterado com sucesso", "success");
          this.view.modal.show = false;
          this.$emit("onSuccess");
          close();
        })
        .catch((error) => {
          let message = error.response.data.message;
          this.notify(message, "error");
        })
        .then(() => {
          this.view.loading = false;
          this.close();
        });
    },

    close() {
      this.model = {};
      this.view.modal.show = false;
    },

    add() {
      this.model = Object.assign({}, objectModel);
      this.view.modal.show = true;

      this.view.loading = true;

      let proModulo = HttpRequestUsuario.getProjetos(0);

      Promise.all([proModulo]).then((response) => {
        this.view.lists.projects = response[0].data.data;

        this.view.loading = false;
      });
    },

    change(usuario) {
      this.view.modal.show = true;
      this.view.action = "Alterando usuário [" + usuario.nome + "]";

      this.view.loading = true;

      let proCliente = HttpRequestUsuario.getById(usuario.id);
      let proModulo = HttpRequestUsuario.getProjetos(usuario.id);

      Promise.all([proCliente, proModulo]).then((response) => {
        this.model = response[0].data.data;
        console.log(this.model.tipoUsuarioStr)
        this.view.lists.projects = response[1].data.data;

        this.view.loading = false;
        this.dataProcessing();
      });
      this.$forceUpdate();
    },
    dataProcessing() {
      console.log(this.model.tipoUsuarioStr);
      switch (this.model.tipoUsuarioStr) {
        case "I":
          this.model.TipoUsuarioId = 1;
          break;
        case "E":
          this.model.TipoUsuarioId = 2;
          break;
        default:
          this.model.TipoUsuarioId = 1;
         break;
      }
    },
  },
};
</script>
<style></style>
