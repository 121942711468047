<template>
  <div>
    <CGIDataTable
      :loading="view.loading"
      :columns="view.columns"
      :items="view.lists.tickets"
      :itemsPerPage="50"
      :showStandardActions="false"
    >
      <!-- Filtro -->
      <template v-slot:filtro>
        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="12" md="4">
              <v-radio-group mandatory v-model="model.StatusId">
                <v-radio label="Todos" :value="0" />
                <v-radio label="Enviado Para Dev" :value="4" />
                <v-radio label="Pré-Ticket" :value="19" />
                <v-radio label="Pré-Ticket Devolvido" :value="20" />
                <v-radio label="Concluídas" :value="5" />
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="8">
              <v-row dense>
                <v-col class="mb-n5" cols="12" md="12">
                  <CGITextField
                    label="Número do ticket"
                    v-model="model.ticketId"
                    type="int"
                  />
                </v-col>

                <v-col class="mb-n5" cols="12" md="6">
                  <CGIDatePicker
                    v-model="model.dataInicial"
                    label="Data Inicial"
                  />
                </v-col>

                <v-col class="mb-n5" cols="12" md="6">
                  <CGIDatePicker v-model="model.dataFinal" label="Data Final" />
                </v-col>

                <v-col class="mb-n5" cols="12" md="6">
                  <CGIDatePicker
                    v-model="model.dataInicialPrevisao"
                    label="Data Inical Previsão"
                  />
                </v-col>
                <v-col class="mb-n5" cols="12" md="6">
                  <CGIDatePicker
                    v-model="model.dataFinalPrevisao"
                    label="Data Final Previsão"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="2">
          <v-card>
            <v-card-title> Tickets </v-card-title>
            <v-divider class="mx-4"></v-divider>
            <v-card-text>
              Pré-Tickets
              <v-badge
                v-if="view.CountPreTicket > 0"
                class="ml-1"
                :content="view.CountPreTicket"
              />
            </v-card-text>
            <v-divider class="mx-4"></v-divider>
            <v-card-text>
              Enviado Para Dev
              <v-badge
                v-if="view.CountTicketEnviadoParaDev > 0"
                class="ml-1"
                :content="view.CountTicketEnviadoParaDev"
              />
            </v-card-text>
            <v-divider class="mx-4"></v-divider>
            <v-card-text>
              Concluídos
              <v-badge
                v-if="view.CountTicketConcluido > 0"
                class="ml-1"
                :content="view.CountTicketConcluido"
              />
            </v-card-text>
            <v-divider class="mx-4"></v-divider>
            <v-card-text>
              Pré-Tickets Devolvidos
              <v-badge
                v-if="view.CountPreTicketDevoltidos > 0"
                color="red"
                class="ml-1"
                :content="view.CountPreTicketDevoltidos"
              />
            </v-card-text>
            <v-divider class="mx-4"></v-divider>
          </v-card>
        </v-col>

        <!-- BOTÕES DE AÇÃO NO BLOCO DE FILTRO-->
        <v-col cols="12" class="text-right">
          <v-btn color="info" @click="onClickAdd">Novo Ticket</v-btn>
          <v-btn
            :loading="view.loading"
            class="ma-2"
            color="primary"
            @click="filter"
          >
            Filtrar
            <v-icon right dark>mdi-filter</v-icon>
          </v-btn>
        </v-col>
      </template>
      <!-- Fim do template de FILTRO -->

      <!-- Açoes no Grid  -->
      <template v-slot:outrasAcoes="{ item }">
        <v-menu bottom :offset-y="true">
          <template v-slot:activator="{ on }">
            <v-btn fab dark small color="secondary" icon v-on="on">
              <v-icon>mdi-expand-all</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item-group color="primary">
              <div v-for="(itemM, i) in view.lists.actionMenus" :key="i">
                <v-list-item v-if="itemM.type === 'Visualizar'">
                  <v-list-item-content>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          v-on="on"
                          :color="itemM.color"
                          @click="handle_function_call(itemM.action, item)"
                        >
                          <v-icon>{{ itemM.icon }}</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ itemM.tooltip }}</span>
                    </v-tooltip>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-if="
                    itemM.type === 'Alterar' &&
                    (item.nomeStatus === 'Pré-Ticket' ||
                      item.nomeStatus === 'Pré-Ticket Devolvido')
                  "
                >
                  <v-list-item-content>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          v-on="on"
                          :color="itemM.color"
                          @click="handle_function_call(itemM.action, item)"
                        >
                          <v-icon>{{ itemM.icon }}</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ itemM.tooltip }}</span>
                    </v-tooltip>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </template>
    </CGIDataTable>
    <ViewTicketClient ref="ViewTicketClient" />
    <Form ref="form" />
  </div>
</template>

<script>
import { HttpRequestTicket } from "./http";
import CGIDataTable from "@/components/CGIDataTable";
import CGITextField from "@/components/CGITextField";
import ViewTicketClient from "../../app/view-task-ticket/ViewTicketClient";
import CGIDatePicker from "@/components/CGIDatePicker";
import Form from "./Form";

//import CGIComboBox from "@/components/CGICombobox";

export default {
  components: {
    CGIDataTable,
    CGITextField,
    CGIDatePicker,
    ViewTicketClient,
    //CGIComboBox,
    Form,
  },

  data() {
    return {
      model: {
        clienteId: 0,
        clienteUsuarioId: 0,
        usuarioId: 0,
        dataInicial: "",
        dataFinal: "",
        dataInicialPrevisao: "",
        dataFinalPrevisao: "",
        prioridadeId: 0,
        excluidosId: 2,
        internoExternoId: 2, // 2 - Externo
        tipoTicketId: 0,
        moduloPrincipalId: 0,
        listaStatusId: "1,2,4,5,15,19,20",
        //somenteTarefas: true, // No caso de clientes, eles só vão ter acesso aos tickets marcados para desenvolvimento
        StatusId: 0,
      },
      view: {
        CountPreTicketDevoltidos: 0,
        CountPreTicket: 0,
        CountTicketConcluido: 0,
        CountTicketEnviadoParaDev: 0,
        loading: false,
        columns: [
          {
            text: "Id",
            value: "id",
          },
          {
            text: "Dt. Cad.",
            value: "dataCadastro",
            type: "datetime",
          },
          {
            text: "Cliente",
            value: "clienteESolicitante",
            type: "html",
          },
          {
            text: "Usuários",
            value: "usuarioEUsuarioTarefa",
            type: "html",
          },
          {
            text: "Status",
            value: "nomeStatus",
            type: "chip",
            config: {
              dark: true,
              label: true,
              color: (item) => {
                if (item.nomeStatus == "Incluído") return "green";
                if (item.nomeStatus == "Em andamento") return "yellow darken-3";
                if (item.nomeStatus == "Aguardando cliente")
                  return "orange darken-3";
                if (item.nomeStatus == "Enviado para Dev") return "cyan";
                if (item.nomeStatus == "Devolvido para Suporte") return "pink";
                if (item.nomeStatus == "Concluído") return "lightblue";
                if (item.nomeStatus == "Em Release") return "darkblue";
                if (item.nomeStatus == "Entregue") return "gray";
                if (item.nomeStatus == "Pré-Ticket") return "lime accent-4";
                if (item.nomeStatus == "Pré-Ticket Devolvido")
                  return "deep-orange accent-2";
              },
            },
          },
          {
            text: "Título",
            value: "tituloETarefaCriada",
            type: "html",
          },
          {
            text: "Dt. Prev.",
            value: "dataPrevisao",
            type: "date",
          },
          {
            text: "Dt. Conclusão",
            value: "dataConclusao",
            type: "date",
          },
          {
            text: "",
            type: "actions",
          },
        ],
        lists: {
          tickets: [],
          actionMenus: [
            {
              action: "onClickOpenTicketTask",
              tooltip: "Visualiar Ticket/Tarefa",
              icon: "mdi-file-eye",
              color: "success",
              type: "Visualizar",
            },
            {
              action: "onClickChange",
              tooltip: "Alterar Pré-Ticket",
              icon: "mdi-pen",
              color: "primary",
              type: "Alterar",
            },
          ],
        },
      },
    };
  },

  /**
   * Método chamado conforme ciclo de vida do vue
   */
  mounted() {
    this.load();
  },

  methods: {
    /**
     * Carregando dados iniciais
     */
    onClickChange(user) {
      this.$refs.form.change(user);
    },
    onClickAdd() {
      this.$refs.form.add();
    },
    load() {
      let user = JSON.parse(localStorage.getItem("user"));
      this.view.loading = true;
      // Forçando o ClienteId cadastrado no banco
      this.model.clienteId = user.clienteId;
      this.model.EhExterno = true;

      HttpRequestTicket.get(this.model)
        .then((response) => {
          this.view.lists.tickets = response.data.data;
          if (this.model.StatusId == 0) this.cardProcessing();

          this.dataProcessing();
          this.view.loading = false;
          this.model.dataInicial = "";
          this.model.dataFinal = "";
          this.model.dataInicialPrevisao = "";
          this.model.dataFinalPrevisao = "";
        })
        .catch((error) => {
          if (error.response.status != 500) {
            this.view.loading = false;
            let message = error.response.data.message;
            this.notify(message, "error");
          }
        });
    },

    /**
     * Ao clicar ação de filtrar
     */
    filter() {
      this.load();
    },
    onClickOpenTicketTask(item) {
      this.$refs.ViewTicketClient.openModal(item.id);
    },
    handle_function_call(function_name, param) {
      console.log(param);
      this[function_name](param);
    },

    dataProcessing() {
      this.view.lists.tickets.map((item) => {
        if (item.ehTarefa && item.nomeStatusTarefa) {
          item.tituloETarefaCriada =
            item.titulo +
            `<br /> <span title="Tarefa criada." style="color: blue; font-weight: bold;"> <i class="mdi mdi-thumb-up-outline"></i> - ${item.nomeStatusTarefa}</span>`;

          if (item.versaoRelease) {
            item.tituloETarefaCriada += `<span style="color: grey;"><i> - <i class="mdi mdi-send-check"></i> ${item.versaoRelease}</i></span>`;
          }
        }

        if (item.ehTarefa && item.nomeStatusTarefa == undefined) {
          item.tituloETarefaCriada =
            item.titulo +
            `<br /> <span title="Marcado, mas ainda sem tarefa." style="color: red; font-weight: bold;"> <i class="mdi mdi-information-outline"></i></span>`;
        }
      });
    },
    cardProcessing() {
      this.view.CountPreTicketDevoltidos = 0;
      this.view.CountPreTicket = 0;
      this.view.CountTicketConcluido = 0;
      this.view.CountTicketEnviadoParaDev = 0;
      console.log(this.view.lists.tickets);
      this.view.lists.tickets.filter((e) => {
        switch (e.nomeStatus) {
          case "Concluído":
            this.view.CountTicketConcluido++;
            break;
          case "Pré-Ticket":
            this.view.CountPreTicket++;
            break;
          case "Pré-Ticket Devolvido":
            this.view.CountPreTicketDevoltidos++;
            break;
          case "Enviado para Dev":
            this.view.CountTicketEnviadoParaDev++;
            break;
          default:
            break;
        }
      });
    },
  },
};
</script>

<style>
</style>