<template>
  <div>
    <CGIModal
      :loading="view.loading"
      :exibir="view.modal.show"
      :titulo="view.action"
      :maxWidth="'1200'"
      :scrollable="true"
      @clickCancelar="close"
      @clickConfirmar="submit"
    >
      <template v-slot:body>
        <v-form ref="form" v-model="view.valid" lazy-validation>
          <v-row dense>
            <v-col cols="12" md="2">
              <CGITextField label="Projeto" hint="Projeto" v-model="model.projeto" disabled />
            </v-col>
            <v-col cols="12" md="2">
              <CGITextField label="Id" hint="Id do Release" v-model="model.id" disabled />
            </v-col>
            <v-col cols="12" md="4">
              <CGITextField
                label="Versão"
                type="text"
                hint="Entre com a versão"
                :rules="[view.rules.required]"
                required
                v-model="model.versao"
              />
            </v-col>
            <v-col cols="12" md="4">
              <CGIDatePicker
                v-model="model.dataInicio"
                label="Data de Início"
                :required="true"
                :rules="[view.rules.required]"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <CGIDataTable
                :loading="view.loading"
                :columns="view.columns.tasks"
                :items="view.lists.tasks"
                :showStandardActions="false"
              >
                <!-- Container de Ações -> Coluna de ações dentro do GRID-->
                <template v-slot:outrasAcoes="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="ma-1"
                        v-on="on"
                        fab
                        x-small
                        dark
                        color="success"
                        @click="onClickOpenTicketTask(item)"
                      >
                        <v-icon>mdi-file-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>Visualiar Ticket/Tarefa</span>
                  </v-tooltip>
                </template>
              </CGIDataTable>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </CGIModal>

    <ViewTaskTicket ref="ViewTaskTicket" />
  </div>
</template>
<script>
import { HttpRequestRelease } from "./http";
import CGIModal from "@/components/CGIModal";
import CGITextField from "@/components/CGITextField";
import CGIDataTable from "@/components/CGIDataTable";
import CGIDatePicker from "@/components/CGIDatePicker";
import { mask } from "vue-the-mask";
import ViewTaskTicket from "../view-task-ticket/ViewTaskTicket";

const objectModel = {
  id: 0,
  versao: "",
  dataInicio: "",
  projeto: "",
  projetoId: 0
};

export default {
  name: "",

  directives: {
    mask
  },

  components: {
    CGIModal,
    CGITextField,
    CGIDataTable,
    CGIDatePicker,
    ViewTaskTicket
  },

  props: {},

  data() {
    return {
      model: objectModel,
      view: {
        action: "Incluindo novo Release",
        loading: false,
        lists: {
          tasks: []
        },
        columns: {
          tasks: [
            {
              text: "",
              value: "selecionado",
              type: "checkbox",
              config: {
                disabled: false
              }
            },
            {
              text: "Tarefa Id",
              value: "id"
            },
            {
              text: "Dt. Cadastro",
              value: "dataCadastro",
              type: "date"
            },
            {
              text: "Tarefa",
              value: "tituloTarefa"
            },
            {
              text: "Cliente",
              value: "nomeCliente"
            },
            {
              text: "Solicitante",
              value: "nomeClienteUsuario"
            },
            {
              text: "Módulo",
              value: "nomeModuloPrincipal"
            },
            {
              text: "Usuário",
              value: "nomeUsuarioTarefa"
            },
            {
              text: "Dt. Previsão",
              value: "dataPrevisao",
              type: "date"
            },
            {
              text: "",
              type: "actions"
            }
          ]
        },
        modal: {
          show: false
        },
        rules: {
          required: value => !!value || "Campo Obrigatório."
        }
      }
    };
  },

  computed: {},

  watch: {},

  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (
        this.view.id == undefined ||
        this.view.id == null ||
        this.view.id == 0
      ) {
        this.save();
      } else {
        this.update();
      }
    },

    save() {
      this.view.loading = true;

      this.model.tarefas = this.view.lists.tasks;

      HttpRequestRelease.postRelease(this.model)
        .then(() => {
          this.view.modal.show = false;
          this.notify("Release incluído com sucesso", "success");
          close();
        })
        .catch(error => {
          let message = error.response.data.message;
          this.notify(message, "error");
        })
        .then(() => {
          this.view.loading = false;
        });
    },
    update() {
      HttpRequestRelease.putRelease(this.model)
        .then(() => {
          this.notify("Release alterado com sucesso", "success");
          this.view.modal.show = false;
          close();
        })
        .catch(error => {
          let message = error.response.data.message;
          this.notify(message, "error");
        })
        .then(() => {
          this.view.loading = false;
          this.close();
        });
    },

    close() {
      this.model = {};
      this.view.modal.show = false;
      this.view.columns.tasks[0].config.disabled = false;
    },

    add() {
      this.view.modal.show = true;

      let proTarefas = HttpRequestRelease.getTarefasParaEdicao(0);

      Promise.all([proTarefas]).then(response => {
        this.view.lists.tasks = response[0].data.data;
        this.view.loading = false;
        this.getCurrentProject();
      });
    },

    change(release) {
      this.view.modal.show = true;
      this.view.action = "Alterando Release [" + release.id + "]";

      this.view.loading = true;

      let proRelease = HttpRequestRelease.getById(release.id);
      let proModulo = HttpRequestRelease.getTarefasParaEdicao(release.id);

      Promise.all([proRelease, proModulo]).then(response => {
        this.model = response[0].data.data;
        this.view.lists.tasks = response[1].data.data;

        if (this.model.dataPublicacao) {
          this.view.columns.tasks[0].config.disabled = true;
        }

        this.view.loading = false;
        this.getCurrentProject();
      });
    },

    getCurrentProject() {
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.model.projeto = currentProject.nome;
      this.model.projetoId = currentProject.id;
    },

    onClickOpenTicketTask(item) {
      this.$refs.ViewTaskTicket.openModal(item.id);
    }
  }
};
</script>
<style></style>
