import httpApi from "@/plugins/http";

const router = "tarefa";

export const HttpRequestTask = {
  putSetTask: (params) => {
    return httpApi.put(`${router}/set-task/${params.id}`, params);
  },

  putUpdateTask: (params) => {
    return httpApi.put(`${router}/update-task/${params.id}`, params);
  },

  getTasksForResume(service) {
    return httpApi.get(`${router}/resume/${service}`);
  },

  putChangeTaskStatus(id, status) {
    return httpApi.put(`${router}/change-task-status/${id}/${status}`);
  },

  getById(id) {
    return httpApi.get(`${router}/${id}`);
  },

  /**
  * Busca os dados da tarefa para uma visualização rápida
  * @param {int} id
  */
  getQuickViewById(id) {
    return httpApi.get(`${router}/visualizacao-rapida/${id}`);
  },

  getHistorico(id) {
    return httpApi.get(`${router}/historicos/${id}`);
  },
  getTestesTarefa(id) {
    return httpApi.get(`${router}/test-task/${id}`);
  },
  postTestesTarefa(param) {
    console.log(param)
    return httpApi.post(`${router}/test-task/${param.id}`,param);
  }

};

