<template>
  <div>
    <CGIModal
      :exibir="view.modal.show"
      :titulo="view.title"
      :fullScreen="false"
      :exibirBotaoCancelar="false"
      :exibirBotaoConfirmar="false"
      @clickCancelar="close()"
    >
      <template v-slot:body>
        <v-form ref="form" v-model="view.valid" lazy-validation>
          <v-card outlined class="mb-4">
            <v-card-text>
              <v-row dense>
                <v-col cols="12" md="6">
                  <span>
                    <b>Número do ticket:</b>
                    <br />
                    {{ model.id }}
                  </span>
                </v-col>
                <v-col cols="12" md="6">
                  <span>
                    <b>Titulo:</b>
                    <br />
                    {{ model.titulo }}
                  </span>
                </v-col>
                <v-col cols="12" md="12">
                  <span>
                    <b>Obs:</b>
                    <br />
                    <div v-html="model.obsTarefa"></div> </span
                ></v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card outlined class="mb-4">
            <v-list>
              <v-list-item-group>
                <template v-for="(item, i) in model.listaTarefasTeste">
                  <v-list-item :key="`item-${i}`" :value="item">
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-wrap"
                        v-text="item.descricao"
                      ></v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-checkbox
                        :disabled="view.modal.CheckDisable"
                        :input-value="item.aprovado"
                        v-model="item.aprovado"
                      ></v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card>
          <template>
            <v-card>
              <v-col cols="12" md="12">
                <v-col v-if="view.modal.btnReprovar" cols="12" md="12">
                  <CGITextEditor
                    required
                    :rules="[view.rules.required]"
                    class="mb-4"                    
                    v-model="model.obsTeste"
                    label="Observações do Teste"
                    placeholder="Entre com as observações do teste da tarefa"
                  />
                </v-col>
                <v-col cols="12" md="12">
                  <CGICurrency
                    label="Tempo (minutos) do Atendimento do Teste"
                    hint="Entre com o tempo, em minutos, do atendimento"
                    v-model="model.tempo"
                    :rules="[view.rules.requiredTempo]"
                    type="int"
                  />
                </v-col>
                <v-row justify="center">
                  <v-btn
                    :loading="view.modal.loading"
                    v-if="view.modal.btnReprovar"
                    dark
                    @click="Reprovar"
                    color="red"
                    >Reprovar</v-btn
                  >
                  <v-btn
                    v-if="view.modal.btnAprovar"
                    :loading="view.modal.loading"
                    @click="Aprovar"
                    dark
                    color="green"
                    >Aprovado</v-btn
                  >
                  <v-btn
                    v-if="view.modal.btnConfirmar"
                    :loading="view.modal.loading"
                    dark
                    color="blue"
                    @click="Confirmar"
                    >Confirmar</v-btn
                  >
                </v-row>
              </v-col>
            </v-card>
          </template>
        </v-form>
      </template>
    </CGIModal>
  </div>
</template>

<script>
import CGIModal from "@/components/CGIModal";
import { HttpRequestTask } from "./http";
import CGITextEditor from "@/components/CGITextEditor";
import CGICurrency from "@/components/CGICurrency";

const objectModel = {
  id: 0,
  titulo: "",
  obsTarefa: "",
  obsTeste: "",
  tempo: 0,
  listaTarefasTeste: []
};

export default {
  name: "TestTask",

  components: {
    CGIModal,
    CGITextEditor,
    CGICurrency,
  },

  data() {
    return {
      model: Object.assign({}, objectModel),
      view: {
        modal: {
          show: false,
          btnAprovar: false,
          btnReprovar: false,
          btnConfirmar: true,
          loading: false,
          CheckDisable: false,
        },
        rules: {
          required: (value) => !!value || "Campo Obrigatório.",
          requiredTempo: (value) => value == 0 || "Informe o valor",
        },
      },
    };
  },

  props: {},

  methods: {
    openModal(ticketId) {
      this.view.modal.show = true;
      this.view.title = `Tarefa [${ticketId}]`;
      this.model.ticketId = Object.assign(ticketId);
      this.load(ticketId);
    },
    close() {
      this.view.modal.btnConfirmar = true;
      this.view.modal.btnAprovar = false;
      this.view.modal.btnReprovar = false;
      this.view.modal.CheckDisable = false;
      this.model = Object.assign({}, objectModel);
      this.view.modal.show = false;
    },
    load(ticketId) {
      let proTicket = HttpRequestTask.getTestesTarefa(ticketId);

      Promise.all([proTicket]).then((response) => {
        this.model = response[0].data.data;
        console.log(this.model);
      });
    },
    Reprovar() {
      if (!this.$refs.form.validate()) {
        return;
      }

      console.log('MODEL', this.model);
      this.view.modal.loading = true;
      HttpRequestTask.postTestesTarefa(this.model)
        .then(() => {
          this.notify("Testes Aprovados", "success");
          this.close();
          this.$emit("onSuccess");
        })
        .catch((error) => {
          console.log(error);
          let message = error.response.data.message;
          this.notify(message, "error");
          return;
        })
        .finally(() => {
          this.view.modal.loading = false;
        });
    },
    Aprovar() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.view.modal.loading = true;

      HttpRequestTask.postTestesTarefa(this.model)
        .then(() => {
          this.notify("Testes Reprovados", "error");
          this.close();
          this.$emit("onSuccess");
        })
        .catch((error) => {
          console.log(error);
          let message = error.response.data.message;
          this.notify(message, "error");
          return;
        })
        .finally(() => {
          this.view.modal.loading = false;
        });
    },
    Confirmar() {
      this.view.modal.CheckDisable = true;

      var QtdAprovado = this.model.listaTarefasTeste.filter(
        (e) => e.aprovado == true
      ).length;
      if (QtdAprovado == this.model.listaTarefasTeste.length) {
        this.view.modal.btnAprovar = true;
        this.view.modal.btnConfirmar = false;
      } else {
        this.view.modal.btnReprovar = true;
        this.view.modal.btnConfirmar = false;
      }
    },
  },
};
</script>

<style></style>
