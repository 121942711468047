import httpApi from "@/plugins/http";

const router = "ticket";

export const HttpRequestTicket = {
  /**
   * Lista todos os Ticket com ou sem filtro
   */
  get: filter => {
    return httpApi.get(`${router}/listar`, {
      params: filter
    });
  },
  getById(id) {
    return httpApi.get(`${router}/${id}`);
  },
  postTicket: params => {
    return httpApi.post(`${router}/preticket`, params);
  },
  putTicket: params => {
    return httpApi.put(`${router}/preticket/${params.id}`, params);
  },
};
