<template>
  <v-row justify="center">
    <v-dialog
      v-if="exibir"
      v-model="exibir"
      persistent
      :scrollable="scrollable"
      :fullscreen="fullScreen"
      :max-width="maxWidth"
    >
      <v-card>
        <v-card-title class="card-header-container">
          <v-app-bar dark color="primary">
            <slot name="header">
              <span>{{ titulo }}</span>

              <v-spacer />

              <v-btn fab text small @click="onClickCancel">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </slot>
          </v-app-bar>
        </v-card-title>
        <v-card-text height="100%">
          <slot name="body" />
        </v-card-text>
        <v-divider />
        <v-card-actions v-if="exibirAcoes">
          <slot name="actions">
            <v-spacer></v-spacer>
            <CGIButton
              v-if="exibirBotaoCancelar"
              :loading="loading"
              color="error"
              type="cancel"
              @click="onClickCancel"
            />
            <CGIButton
              v-if="exibirBotaoConfirmar"
              :loading="loading"
              color="primary"
              type="confirm"
              @click="onClickConfirm"
            />
          </slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import CGIButton from "@/components/CGIButton";

export default {
  name: "CGIGenericModal",

  components: {
    CGIButton
  },

  props: {
    exibir: {
      default: false,
      type: Boolean
    },

    exibirAcoes: {
      default: true,
      type: Boolean
    },

    exibirBotaoCancelar: {
      default: true,
      type: Boolean
    },

    exibirBotaoConfirmar: {
      default: true,
      type: Boolean
    },

    maxWidth: {
      default: "800px",
      type: String
    },

    titulo: {
      default: "",
      type: String
    },

    fullScreen: {
      default: false,
      type: Boolean
    },

    loading: {
      default: false,
      type: Boolean
    },

    /**
     * Prop para manter AppBar e Footer fixos
     */
    scrollable: {
      default: true,
      type: Boolean
    }
  },

  data() {
    return {};
  },

  computed: {},

  watch: {},

  methods: {
    onClickCancel() {
      this.$emit("clickCancelar");
    },

    onClickConfirm() {
      this.$emit("clickConfirmar");
    }
  }
};
</script>
<style scoped>
.card-header-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
}
</style>
