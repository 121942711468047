<template>
  <div>
    <v-dialog v-if="show" v-model="show" :persistent="persistent" :max-width="maxWidth">
      <v-card>
        <v-card-title class="card-header-container">
          <v-app-bar dark :color="color">
            <slot name="header">
              <span>{{ title }}</span>
              <v-spacer />
              <v-btn fab text small @click="clickCancel">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </slot>
          </v-app-bar>
        </v-card-title>
        <v-card-text height="100%">
          <div class="mt-3 mb-n1 text-center body-1" v-html="message"></div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="onlyOK" color="primary" @click="clickConfim">OK</v-btn>
          <v-btn v-if="!onlyOK" color="error" @click="clickCancel">Não</v-btn>
          <v-btn v-if="!onlyOK" color="primary" @click="clickConfim">Sim</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  components: {},

  props: {
    show: {
      default: false,
      type: Boolean
    },
    maxWidth: {
      default: "450px",
      type: String
    },
    persistent: {
      default: true,
      type: Boolean
    },
    onlyOK: {
      default: false,
      type: Boolean
    },
    title: {
      default: "Atenção!",
      type: String
    },
    message: {
      default: "Confirma que deseja executar essa ação?",
      type: String
    },
    color: {
      default: "warning",
      type: String
    }
  },

  data() {
    return {
      view: {
        show: false
      }
    };
  },

  methods: {
    clickConfim() {
      this.$emit("clickConfim");
    },
    clickCancel() {
      this.$emit("clickCancel");
    }
  }
};
</script>

<style scoped>
.card-header-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
}
</style>