import httpApi from "@/plugins/http";

const router = "cliente";

export const HttpRequestCliente = {
  /**
   * Lista todos os usuário com ou sem filtro
   */
  get: (filter) => {
    return httpApi.get(`${router}/listar`, {
      params: filter,
    });
  },

  /**
   * Busca um usuário por Id
   * @param {int} id
   */
  getById(id) {
    return httpApi.get(`${router}/${id}`);
  },

  /**
   * Serviço para criar um usuário
   */
  postHistoricoCliente: (params) => {
    return httpApi.post(`${router}/incluirhistorico`, params);
  },

  getHistorico(id) {
    return httpApi.get(`${router}/historicos/${id}`);
  },

  getModulos(id) {
    return httpApi.get(`${router}/modulos/${id}`);
  },

  postCliente: (params) => {
    return httpApi.post(`${router}`, params);
  },

  /**
   * Serviço para alterar um usuário
   */
  putCliente: (params) => {
    return httpApi.post(`${router}/alterar/${params.id}`, params);
  },

  getListCerts(id) {
    return httpApi.get(`${router}/certificados/${id}`)
  },
  getClienteModulos(id) {
    return httpApi.get(`${router}/clientemodulos/${id}`)
  }
};
