import httpApi from "../../plugins/http";

const router = "home";

export const HttpRequestAuth = {
  login: (params = {}) => {
    return httpApi.post(`${router}/login`, params);
  },

  changePassword: (params = {}) => {
    return httpApi.post(`${router}/changePassword`, params);
  },
};
