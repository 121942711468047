<template>
  <div>
    <CGIModal
      :loading="view.loading"
      :exibir="view.modal.show"
      :titulo="view.action"
      :fullScreen="false"
      :maxWidth="'1000px'"
      :scrollable="true"
      @clickCancelar="close"
      @clickConfirmar="submit"
    >
      <template v-slot:body>
        <v-form ref="form" v-model="view.valid" lazy-validation>
          <v-row dense>
            <v-col cols="12" md="2">
              <CGITextField label="Id" hint="Id" v-model="model.id" disabled />
            </v-col>

            <v-col cols="12" md="5">
              <CGITextField
                label="Nome"
                type="text"
                hint="Entre com o nome"
                :rules="[view.rules.required]"
                required
                v-model="model.nome"
              />
            </v-col>

            <v-col cols="12" md="5">
              <CGITextField
                label="Grupo"
                type="text"
                hint="Entre com o  Grupo"
                :rules="[view.rules.required]"
                required
                v-model="model.grupo"
              />
            </v-col>
            <v-col cols="12" md="2">
              <v-checkbox
                dense
                class="mt-1 ml-1"
                v-model="model.ativo"
                label="Ativo"
                hint="Informe se o cliente está ativo"
              />
            </v-col>
            <v-col cols="12" md="12">
              <CGIDataTable
                :showSearch="true"
                :itemsPerPage="5"
                :columns="view.columns.clientes"
                :items="view.lists.clientes"
              />
            </v-col>
          </v-row>
        </v-form>
      </template>
    </CGIModal>
  </div>
</template>
<script>
import { HttpRequestModulo } from "./http";
import { HttpRequestCliente } from "../client/http";
import CGIModal from "@/components/CGIModal";
import CGITextField from "@/components/CGITextField";
import CGIDataTable from "@/components/CGIDataTable";
import { mask } from "vue-the-mask";
//import CGIComboBox from "@/components/CGICombobox";

const objectModel = {
  id: 0,
  nome: "",
  grupo: "",
  projeto: "",
  ativo: true,
  GrupoId: null,
  ModuloClientes: "",
};

export default {
  name: "",

  directives: {
    mask,
  },

  components: {
    CGIModal,
    CGITextField,
    CGIDataTable,
  },

  props: {},

  data() {
    return {
      model: objectModel,
      view: {
        action: "Incluir novo módulo",
        loading: false,
        lists: {
          clientes: [],
        },
        columns: {
          clientes: [
            {
              text: "",
              value: "selecionado",
              type: "checkbox",
              config: {
                disabled: false,
              },
              width: 30,
            },
            {
              text: "Notificar",
              value: "notificar",
              type: "checkbox",
              config: {
                disabled: false,
              },
              width: 30,
            },
            {
              text: "Id",
              value: "id",
            },
            {
              text: "Nome",
              value: "nome",
            },
          ],
        },
        modal: {
          show: false,
        },
        rules: {
          required: (value) => !!value || "Campo Obrigatório.",
        },
      },
    };
  },

  computed: {},

  watch: {},

  methods: {
    submit() {
      this.model.ModuloClientes = this.view.lists.clientes;
      if (!this.$refs.form.validate()) {
        return;
      }
      console.log(this.model.listaClienteModulos);
      if (
        this.model.id == undefined ||
        this.model.id == null ||
        this.model.id == 0
      ) {
        this.save();
      } else {
        this.update();
      }
    },

    save() {
      this.view.loading = true;

      HttpRequestModulo.postModulo(this.model)
        .then(() => {
          this.view.modal.show = false;
          this.notify("Módulo incluído com sucesso", "success");
          this.$emit("onSuccess");
          close();
        })
        .catch((error) => {
          let message = error.response.data.message;
          this.notify(message, "error");
        })
        .then(() => {
          this.view.loading = false;
        });
    },
    update() {
      HttpRequestModulo.putModulo(this.model)
        .then(() => {
          this.notify("Usuário alterado com sucesso", "success");
          this.view.modal.show = false;
          this.$emit("onSuccess");
          close();
        })
        .catch((error) => {
          let message = error.response.data.message;
          this.notify(message, "error");
        })
        .then(() => {
          this.view.loading = false;
          this.close();
        });
    },

    close() {
      this.model = {};
      this.view.modal.show = false;
    },

    add() {
      this.model = Object.assign({}, objectModel);
      this.view.modal.show = true;

      let proClienteModulo = HttpRequestCliente.getClienteModulos(0);

      Promise.all([proClienteModulo]).then((response) => {
        this.view.lists.clientes = response[0].data.data;

        this.view.loading = false;
      });
    },

    change(modulo) {
      this.view.modal.show = true;
      this.view.action = "Alterando usuário [" + modulo.nome + "]";

      this.view.loading = true;

      let proCliente = HttpRequestModulo.getById(modulo.id);
      let proClienteModulo = HttpRequestCliente.getClienteModulos(modulo.id);

      Promise.all([proCliente, proClienteModulo]).then((response) => {
        this.model = response[0].data.data;
        this.view.lists.clientes = response[1].data.data;

        this.view.loading = false;
      });

      this.$forceUpdate();
    },
  },
};
</script>
<style></style>
