import ResumeFULL from "./ResumeFULL";
import Resume from "./Resume";

export default [
  {
    path: "/task/resume/",
    meta: {
      public: false,
    },
    name: "Resumo de Tarefas",
    component: Resume,
  },
  {
    path: "/task/resumefull/",
    meta: {
      public: false,
    },
    name: "Resumo de Tarefas - FULLSTACK",
    component: ResumeFULL,
  },
];
