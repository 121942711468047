<template>
  <div>
    <CGIModal
      :exibir="view.modal.show"
      :titulo="view.title"
      :fullScreen="true"
      @clickCancelar="close()"
      @clickConfirmar="submit()"
    >
      <template v-slot:body>
        <v-form ref="form" v-model="view.valid" lazy-validation>
          <v-card outlined class="mb-4">
            <v-card-text>
              <v-row dense>
                <v-col cols="12" md="2">
                  <span>
                    <b>Número do ticket:</b>
                    <br />
                    {{ model.id }}
                  </span>
                </v-col>
                <v-col cols="12" md="2">
                  <span>
                    <b>Cliente:</b>
                    <br />
                    {{ model.nomeCliente }}
                  </span>
                </v-col>
                <v-col cols="12" md="2">
                  <span>
                    <b>Soliciante:</b>
                    <br />
                    {{ model.nomeClienteUsuario }}
                  </span>
                </v-col>
                <v-col cols="12" md="2">
                  <span>
                    <b>Módulo:</b>
                    <br />
                    {{ model.nomeModuloPrincipal }}
                  </span>
                </v-col>
                <v-col cols="12" md="2">
                  <span>
                    <b>Título:</b>
                    <br />
                    {{ model.titulo }}
                  </span>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" md="8">
                  <span>
                    <b>Solicitação:</b>
                    <br />
                    <span v-html="model.solicitacao"></span>
                  </span>
                </v-col>
                <v-col cols="12" md="2">
                  <span>
                    <b>Criador por:</b>
                    <br />
                    {{ model.nomeUsuarioCadastro }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-row dense>
            <v-col cols="12" md="8">
              <CGITextField
                label="Título da Tarefa"
                type="text"
                hint="Entre com o título da Tarefa"
                v-model="model.tituloTarefa"
                required
                :rules="[view.rules.required]"
                counter="100"
              />
            </v-col>
            <v-col cols="12" md="4">
              <CGIComboBox
                v-model="model.statusTarefaId"
                route="/combo/statustarefa"
                label="Status"
                hint="Selecione o status da atualização da tarefa"
                :itemsAsync="true"
                required
                :rules="[view.rules.required]"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="6" dense>
              <v-col cols="12" md="12">
                <CGITextEditor
                  class="mb-4"
                  v-model="model.obsTarefa"
                  :dateModel="view.obsTarefa"
                  label="Observações da Tarefa"
                  placeholder="Entre com as observações da tarefa"
                />
              </v-col>
              <v-col cols="12" md="12">
                <v-row dense>
                  <v-col cols="12" md="3">
                    <v-checkbox
                      v-model="model.criarBaseConhecimento"
                      :label="`Criar Base de Conhecimento`"
                  /></v-col>
                  <v-col cols="12" md="3">
                    <v-checkbox
                      v-model="model.alterarMenu"
                      :label="`Alterar Menu`"
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-checkbox
                      v-model="model.implantarTarefa"
                      :label="`Implementar Cliente`"
                  /></v-col>
                  <v-col cols="12" md="3">
                    <v-checkbox
                      v-model="model.notificarCliente"
                      :label="`Notificar Cliente`"
                  /></v-col>

                  <v-col cols="12" md="3">
                    <v-checkbox
                      v-model="model.cobrarHoraTecnica"
                      :label="`Cobrar Hora Técnica`"
                  /></v-col>
                  <v-col cols="12" md="3">
                    <CGICurrency
                      label="Qtd. Horas"
                      v-model="model.numeroHorasTecnicas"
                      type="int"
                  /></v-col>
                  <v-col cols="12" md="3">
                    <CGICurrency
                      label="Valor Hora"
                      v-model="model.valorHoraTecnica"
                      type="currency"
                  /></v-col>
                  <v-col cols="12" md="12">
                  <v-textarea
                    v-model="model.ObsCobrancaHoraTecnica"
                    label="Obs. da Cobrança de Hora Técnica"
                    type="text"
                    rows="4"
                    maxlength="600"
                    hint="Detalhamento das informações de cobrança."
                    dense
                    outlined
                    required
                    counter="600"                    
                  /></v-col>
                  <v-col cols="12" md="3">
                    <v-checkbox
                      v-model="model.cobrancaHoraTecnicaDefinida"
                      :label="`Cobrança Definida`"
                  /></v-col>
                  <v-col cols="12" md="3">
                    <v-checkbox
                      v-model="model.cobrancaGerada"
                      :label="`Cobrança Gerada`"
                  /></v-col>
                </v-row>
                <v-col v-show="model.alterarMenu" cols="12" md="6">
                  <CGITextField
                    label="Menu"
                    type="text"
                    hint="Entre com o Menu"
                    v-model="model.menuTarefa"
                  />
                </v-col>
                <v-col v-show="model.criarBaseConhecimento" cols="12" md="6">
                  <CGITextField
                    label="Título Base de Conhecimento"
                    type="text"
                    hint="Entre com o Título Base de Conhecimento"
                    v-model="model.TituloBaseConhecimento"
                  />
                </v-col>
              </v-col>
            </v-col>
            <v-col cols="12" md="6">
              <v-col cols="12" md="12">
                <CGITextEditor
                  class="mb-4"
                  v-model="model.obsTarefaFRONT"
                  :dateModel="view.obsTarefaFRONT"
                  label="Observações da Tarefa para FRONT"
                  placeholder="Entre com as observações da tarefa para FRONT"
                  rows="8"
                />
              </v-col>
              <v-textarea
                v-model="model.testesTarefa"
                label="Testes da Tarefa"
                type="text"
                rows="4"
                maxlength="600"
                hint="Entre com os testes para a tarefa"
                dense
                outlined
                required
                counter="600"
                :rules="[view.rules.required]"
              />
              <v-textarea
                v-model="model.resumoCliente"
                label="Resumo para o Cliente"
                type="text"
                rows="2"
                maxlength="600"
                hint="Notificação que o cliente receberá quando a tarefa for lançada em Produção."
                dense
                outlined
                required
                counter="600"
                :rules="[view.rules.required]"
              />
              <v-row dense>
                <v-col cols="12" md="6">
                  <CGIComboBox
                    v-model="model.usuarioTarefaId"
                    route="/combo/usuarioDev"
                    label="Usuário (BACK)"
                    hint="Selecione o usuário"
                    :itemsAsync="true"
                    required
                    :rules="[view.rules.required]"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <CGIComboBox
                    v-model="model.usuarioTarefaFrontId"
                    route="/combo/usuarioDev"
                    label="Usuário (FRONT)"
                    hint="Selecione o usuário"
                    :itemsAsync="true"
                    required
                    :rules="[view.rules.required]"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <CGICurrency
                    label="Tempo (minutos) do Atendimento"
                    hint="Entre com o tempo, em minutos, do atendimento"
                    v-model="model.tempo"
                    :rules="[view.rules.required]"
                    type="int"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" md="6">
                  <CGIComboBox
                    v-model="model.prioridade"
                    route="/combo/prioridade"
                    label="Prioridade"
                    hint="Defina a prioridade do ticket"
                    :itemsAsync="true"
                    required
                    :rules="[view.rules.required]"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <CGIDatePicker
                    v-model="model.dataPrevisao"
                    label="Data Previsão"
                    :required="true"
                    :rules="[view.rules.required]"
                    :loadTodayPlus="3"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </CGIModal>
  </div>
</template>

<script>
import { HttpRequestTask } from "./http";
import { HttpRequestTicket } from "../ticket/http";
import CGIModal from "@/components/CGIModal";
import CGITextField from "@/components/CGITextField";
import CGIComboBox from "@/components/CGICombobox";
import CGICurrency from "@/components/CGICurrency";

import CGITextEditor from "@/components/CGITextEditor";
import CGIDatePicker from "@/components/CGIDatePicker";

const objectModel = {
  id: 0,

  nomeCliente: "",
  nomeClienteUsuario: "",
  titulo: "",
  solicitacao: "",
  dataCadastro: "",
  nomeModulo: "",

  tituloTarefa: "",
  obsTarefa: "",
  obsTarefaFRONT: "",
  testesTarefa: "",
  usuarioTarefaId: 0,
  statusTarefaId: 0,
  tempo: 0,

  criarBaseConhecimento: false,
  alterarMenu: false,
  implantarTarefa: false,
  notificarCliente: false,

  menuTarefa: "",
};

export default {
  name: "SetTask",

  components: {
    CGIModal,
    CGITextField,
    CGIComboBox,
    CGICurrency,
    CGITextEditor,
    CGIDatePicker,
  },

  data() {
    return {
      model: Object.assign({}, objectModel),
      view: {
        modal: {
          show: false,
        },
        rules: {
          required: (value) => !!value || "Campo Obrigatório.",
          requiredTempo: (value) => value == 0 || "Informe o valor",
        },
      },
    };
  },

  props: {},

  methods: {
    openModal(ticketId) {
      // this.model = Object.assign({}, objectModel);
      this.view.modal.show = true;
      this.view.title = `Tarefa [${ticketId}]`;
      this.model.ticketId = Object.assign(ticketId);
      this.load(ticketId);
    },
    close() {
      this.model = Object.assign({}, objectModel);
      this.view.modal.show = false;
    },
    load(ticketId) {
      let proTicket = HttpRequestTicket.getById(ticketId);

      Promise.all([proTicket]).then((response) => {
        this.model = response[0].data.data;
        this.view.obsTarefa = this.model.obsTarefa;
        this.view.obsTarefaFront = this.model.obsTarefaFront;
        this.view.loading = false;

        this.dataProcessing();
      });
    },
    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      HttpRequestTask.putSetTask(this.model)
        .then(() => {
          this.notify("Tarefa definida com sucesso!", "success");
          this.close();
          this.$emit("onSuccess");
        })
        .catch((error) => {
          console.log(error);
          let message = error.response.data.message;
          this.notify(message, "error");
          return;
        });
    },
    dataProcessing() {
      switch (this.model.prioridade) {
        case "U":
          this.model.prioridade = 1;
          break;
        case "A":
          this.model.prioridade = 2;
          break;
        case "M":
          this.model.prioridade = 3;
          break;
        case "B":
          this.model.prioridade = 4;
          break;
      }
    },
  },
};
</script>

<style></style>
