<template>
  <div>
    <CGIModal
      :exibir="view.modal.show"
      :titulo="view.title"
      :loading="view.loading"
      @clickCancelar="close()"
      @clickConfirmar="submit()"
    >
      <template v-slot:body>
        <v-form ref="form" v-model="view.valid" lazy-validation>
          <v-row dense>
            <v-col cols="12" md="6">
              <CGITextField
                label="Resumo/Título do Atendimento"
                type="text"
                hint="Entre com o resumo do atendimento"
                v-model="model.tituloAtualizacao"
                counter="100"
              />
            </v-col>
            <v-col cols="12" md="6" v-if="view.ticketPrivacy == 'E'">
              <v-checkbox
                class="mt-1 ml-1"
                v-model="model.notificarCliente"
                label="Informar Cliente"
                hint="Um email será enviado com as informações desse atendimento"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <CGITextEditor
                v-model="model.obsAtualizacao"
                label="Obs do atendimento"
                placeholder="Entre com a obs. do atendimento"
              />
              <br />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="5">
              <CGIComboBox
                v-model="model.statusAtualizacaoId"
                route="/combo/statusticket"
                label="Status (opcional)"
                hint="Selecione o status da atualização do ticket"
                :itemsAsync="true"
              />
            </v-col>
            <v-col cols="12" md="4">
              <CGICurrency
                label="Tempo (minutos) do Atendimento"
                hint="Entre com o tempo, em minutos, do atendimento"
                v-model="model.tempoAtualizacao"
                :rules="[view.rules.requiredTempo]"
                type="int"
              />
            </v-col>
            <v-col cols="12" md="3">
              <CGIDatePicker
                v-model="model.dataCadastro"
                label="Data Cadastro"
                :required="true"
                :loadToday="true"
                :rules="[view.rules.required]"
              />
            </v-col>
           <v-col dense cols="12" md="5">
              <CGIComboBox
                v-model="model.NovoUsuarioId"
                route="/combo/usuario"
                label="Usuario (Opcional)"
                hint="Selecione o Usuario do Ticket"
                :itemsAsync="true"
              />
            </v-col>
          </v-row>
        </v-form>
      </template>
    </CGIModal>
  </div>
</template>

<script>
import { HttpRequestTicket } from "./http";
import CGIModal from "@/components/CGIModal";
import CGITextField from "@/components/CGITextField";
import CGICurrency from "@/components/CGICurrency";
import CGIComboBox from "@/components/CGICombobox";
import CGIDatePicker from "@/components/CGIDatePicker";

import CGITextEditor from "@/components/CGITextEditor";

const objectModel = {
  tituloAtualizacao: "",
  statusAtualizacaoId: 0,
  tempoAtualizacao: 0,
  obsAtualizacao: "",
  ticketId: 0,
  notificarCliente: false,
  DevolverParaSuporte:false,
  ObsSuporte:"",
  NovoUsuarioId:0
};

export default {
  name: "InformService",

  components: {
    CGIModal,
    CGITextField,
    CGICurrency,
    CGIComboBox,
    CGIDatePicker,
    CGITextEditor,
  },

  data() {
    return {
      model: Object.assign({}, objectModel),
      view: {
        modal: {
          show: false,
        },
        loading: false,
        rules: {
          required: (value) => !!value || "Campo Obrigatório.",
          requiredTempo: (value) => value == 0 || "Informe o valor",
        },
      },
    };
  },

  props: {},

  methods: {
    openModal(ticketId, ticketTitle, ticketPrivacy) {
      this.model = Object.assign({}, objectModel);
      this.view.modal.show = true;

      this.view.title = `Informar Atendimento - ${ticketTitle} [${ticketId}]`;
      this.model.tituloAtualizacao = ticketTitle;
      this.view.ticketPrivacy = ticketPrivacy;
      this.model.ticketId = Object.assign(ticketId);
    },
    close() {
      this.model = Object.assign({}, objectModel);
      this.view.modal.show = false;
    },
    submit() {
      this.view.loading = true;
      if (!this.$refs.form.validate()) {
        this.view.loading = false;
        return;
      }

      HttpRequestTicket.postInformService(this.model)
        .then(() => {
          this.notify("Atendimento incluído com sucesso!", "success");
          this.close();
          this.$emit("onSuccess");
          this.view.loading = false;
        })
        .catch((error) => {
          console.log(error);
          let message = error.response.data.message;
          this.view.loading = false;
          this.notify(message, "error");
          return;
        });
    },
  },
};
</script>

<style></style>
