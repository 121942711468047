import Form from './Form'

export default [
  {
    path: '/auth',
    meta: {
      public: true
    },
    name: 'Auth',
    component: Form
  }
]