<template>
  <div>
    <div></div>
    <CGIDataTable
      :loading="view.loading"
      :columns="view.columns"
      :items="view.listas.clientes"
      @clickRemove="(value) => onClickRemove(value)"
      @clickChange="(value) => onClickChange(value)"
    >
      <!-- Filtro -->
      <template v-slot:filtro>
        <v-row dense>
          <v-col cols="12">
            <v-row dense>
              <v-col cols="12" md="3">
                <CGITextField label="Identificação" v-model="model.identificacao" />
              </v-col>
              <v-col cols="12" md="3">
                <CGITextField label="Nome" v-model="model.nome" />
              </v-col>
              <v-col cols="12" md="3">
                <CGITextField label="Link" v-model="model.link" />
              </v-col>
              <v-col cols="12" md="3">
                <CGITextField label="Email p/ Notificação" v-model="model.eMailNotificacao" />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn class="ma-2" color="info" @click="onClickNovoHistorico">Lançar Histórico Cliente</v-btn>
            <v-btn color="info" @click="onClickAdd">Novo</v-btn>
            <v-btn :loading="view.loading" class="ma-2" color="primary" @click="filter">
              Filtrar
              <v-icon right dark>mdi-filter</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <template v-slot:outrasAcoes="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" color="orange" @click="onClickUpdateCert(item)">
              <v-icon>mdi-shield-key</v-icon>
            </v-btn>
          </template>
          <span>Atualizar Certificado Digital do cliente</span>
        </v-tooltip>
      </template>
    </CGIDataTable>

    <NovoHistoricoCliente ref="novoHistoricoCliente" />
    <Form ref="form" />
    <Delete titulo="Atenção" ref="excluir" />
    <UpdateCertFile ref="UpdateCertFile" />
  </div>
</template>
<script>
import { HttpRequestCliente } from "./http";
import Form from "./Form";
import Delete from "@/components/CGIDelete";
import CGIDataTable from "@/components/CGIDataTable";
import CGITextField from "@/components/CGITextField";
import NovoHistoricoCliente from "../client/NewHistoric";
import EventBus from "@/plugins/event-bus";
import UpdateCertFile from "./components/update-cert-file/UpdateCertFile";

export default {
  name: "ListarTicket",

  /**
   * Componentes filhos utilizados
   */
  components: {
    Form,
    Delete,
    CGIDataTable,
    CGITextField,
    NovoHistoricoCliente,
    UpdateCertFile,
    
  },

  /**
   * Variáveis do componente
   */
  data() {
    return {
      text: "",
      model: {
        nome: "",
        identificacao: ""
      },
      view: {
        columns: [
          {
            text: "Id",
            value: "id"
          },
          {
            text: "Dt. Cad.",
            value: "dataCadastro",
            type: "datetime"
          },
          {
            text: "Identificação",
            value: "identificacao"
          },
          {
            text: "Nome",
            value: "nome"
          },
          {
            text: "Link",
            value: "link"
          },
          {
            text: "Email Contato",
            value: "eMailNotificacao"
          },
          {
            text: "Ativo",
            value: "ativo",
            type: "checkbox"
          },
          {
            text: "",
            type: "actions"
          }
        ],
        loading: false,
        listas: {
          clientes: []
        }
      }
    };
  },

  /**
   * Método chamado conforme ciclo de vida do vue
   */
  mounted() {
    this.load();
    /**
     * EventBus - CALL_LOAD
     * Usado para chamar os métodos de LOAD da página
     */
    var self = this;
    EventBus.$on("CALL_LOAD", () => {
      self.load();
    });
  },

  /**
   * Objeto onde declaramos métodos do componente
   */
  methods: {
    /**
     * Carregando dados iniciais
     */
    load() {
      this.view.loading = true;
      HttpRequestCliente.get(this.model).then(response => {
        this.view.listas.clientes = response.data.data;
        this.view.loading = false;
      });
      
    },

    /**
     * Ao clicar ação de filtrar
     */
    filter() {
      this.load();
    },

    /**
     * Ao clicar ação de remover um usuário
     */
    onClickRemove(usuario) {
      this.$refs.excluir.abrirModal(usuario, "/cliente/excluir");
    },

    /**
     * Ao clicar ação de Copiar permissão de outro usuário
     */
    onClickNovoHistorico() {
      this.$refs.novoHistoricoCliente.abrirModal();
    },

    /**
     * Ao clicar ação de Incluir
     */
    onClickAdd() {
      this.$refs.form.add();
    },

    /**
     * Ao clicar ação de alterar
     */
    onClickChange(cliente) {
      this.$refs.form.change(cliente);
    },
    onClickUpdateCert(client) {
      this.$refs.UpdateCertFile.openModal(client);
    }
  },

  /**
   * Ao destruir, desligamos o EventBus para não fazer chamadas desnecessárias
   */
  destroyed() {
    EventBus.$off("CALL_LOAD");
  }
};
</script>
<style></style>
