<template>
  <div>
    <v-card>
      <v-row>
        <v-col cols="12" md="3">
          <CGIComboBox
            v-model="model.usuarioId"
            route="/combo/usuario"
            label="Usuário"
            hint="Selecione o usuário"
            :itemsAsync="true"
          />
        </v-col>
        <v-col cols="12" md="3">
          <CGIComboBox
            v-model="model.clienteId"
            route="/combo/cliente"
            label="Cliente"
            hint="Selecione o Cliente"
            :itemsAsync="true"
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-btn color="primary" @click="CarregarDados()">
            Filtrar
            <v-icon right dark>mdi-filter</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-tabs background-color="primary" center-active grow dark>
        <v-tab @click="onChanged('EmAndamento', 2)">
          Em Andamento
        </v-tab>
        <v-tab @click="onChanged('AguardandoCliente', 3)">
          Aguardando Cliente
        </v-tab>
        <v-tab @click="onChanged('Ticket')">
          Tickets
        </v-tab>
        <v-tab-item>
          <v-row>
            <v-col cols="12" md="3">
              <ResumeList
                ref="ResumeVisitsMeetings"
                type="VisitsMeetings"
                @clickChange="onChanged('EmAndamento', 2)"
              />
            </v-col>
            <v-col cols="12" md="3">
              <ResumeList
                ref="ResumeImplantation"
                type="Implantation"
                @clickChange="onChanged('EmAndamento', 2)"
              />
            </v-col>
            <v-col cols="12" md="3">
              <ResumeList
                ref="ResumeTraining"
                type="Training"
                @clickChange="onChanged('EmAndamento', 2)"
              />
            </v-col>
            <v-col cols="12" md="3">
              <ResumeList
                ref="ResumeOthers"
                type="Others"
                @clickChange="onChanged('EmAndamento', 2)"
                :showArrows="false"
              />
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-row>
            <v-col cols="12" md="3">
              <ResumeList
                ref="ResumeVisitsMeetings"
                type="VisitsMeetings"
                @clickChange="onChanged('AguardandoCliente', 3)"
              />
            </v-col>
            <v-col cols="12" md="3">
              <ResumeList
                ref="ResumeImplantation"
                type="Implantation"
                @clickChange="onChanged('AguardandoCliente', 3)"
              />
            </v-col>
            <v-col cols="12" md="3">
              <ResumeList
                ref="ResumeTraining"
                type="Training"
                @clickChange="onChanged('AguardandoCliente', 3)"
              />
            </v-col>
            <v-col cols="12" md="3">
              <ResumeList
                ref="ResumeOthers"
                type="Others"
                @clickChange="CarregarDados()"
              />
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-row>
            <v-col cols="12" md="3">
              <ResumeList
                ref="ResumePreTicket"
                type="PreTicket"
                @clickChange="CarregarDados()"
              />
            </v-col>
            <v-col cols="12" md="3">
              <ResumeList
                ref="ResumePreTicketDevolvido"
                type="PreTicketDevolvido"
                @clickChange="CarregarDados()"
              />
            </v-col>
            <v-col cols="12" md="3">
              <ResumeList
                ref="ResumeTicketDevolvido"
                type="TicketDevolvido"
                @clickChange="CarregarDados()"
              />
            </v-col>
            <v-col cols="12" md="3">
              <ResumeList
                ref="ResumeTicketNotificacao"
                type="TicketNotificacao"
                @clickChange="CarregarDados()"
              />
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import ResumeList from "@/components/CGIKanbanListCard";
import CGIComboBox from "@/components/CGICombobox";

export default {
  name: "Dash",
  data() {
    return {
      model: {
        usuarioId: 0,
        clienteId: 0,
        TabName: "EmAndamento",
        statusid: 2,
      },
    };
  },
  mounted() {
    this.CarregarDados();
  },

  components: {
    ResumeList,
    CGIComboBox,
  },

  methods: {
    CarregarDados() {
      setTimeout(() => {
        switch (this.model.TabName) {
          case "EmAndamento":
            this.$refs.ResumeVisitsMeetings.load(
              this.model.usuarioId,
              this.model.statusid,
              this.model.clienteId
            );
            this.$refs.ResumeImplantation.load(
              this.model.usuarioId,
              this.model.statusid,
              this.model.clienteId
            );
            this.$refs.ResumeTraining.load(
              this.model.usuarioId,
              this.model.statusid,
              this.model.clienteId
            );
            this.$refs.ResumeOthers.load(
              this.model.usuarioId,
              this.model.statusid,
              this.model.clienteId
            );

            break;
          case "AguardandoCliente":
            this.$refs.ResumeVisitsMeetings.load(
              this.model.usuarioId,
              this.model.statusid,
              this.model.clienteId
            );
            this.$refs.ResumeImplantation.load(
              this.model.usuarioId,
              this.model.statusid,
              this.model.clienteId
            );
            this.$refs.ResumeTraining.load(
              this.model.usuarioId,
              this.model.statusid,
              this.model.clienteId
            );
            this.$refs.ResumeOthers.load(
              this.model.usuarioId,
              this.model.statusid,
              this.model.clienteId
            );
            break;
          case "Ticket":
            this.$refs.ResumePreTicket.load(
              this.model.usuarioId,
              this.model.statusid,
              this.model.clienteId
            );
            this.$refs.ResumePreTicketDevolvido.load(
              this.model.usuarioId,
              this.model.statusid,
              this.model.clienteId
            );
            this.$refs.ResumeTicketDevolvido.load(
              this.model.usuarioId,
              this.model.statusid,
              this.model.clienteId
            );
            this.$refs.ResumeTicketNotificacao.load(
              this.model.usuarioId,
              17,
              this.model.clienteId
            );
            break;
          default:
            break;
        }
      }, 100);
    },
    onChanged(nameTab, statusid) {
      this.model.TabName = nameTab;
      this.model.statusid = statusid;
      this.CarregarDados();
    },
  },
};
</script>
<style></style>
