<template>
  <div>
    <CGIModal
      :loading="view.loading"
      :exibir="view.modal.show"
      :titulo="view.title"
      :max-width="'1100px'"
      :scrollable="true"
      @clickCancelar="close"
      @clickConfirmar="close"
      :exibirAcoes="false"
    >
      <template v-slot:body>
        <v-data-table
          class="list-item-card"
          dense
          hide-default-footer
          disable-pagination
          disable-sort
          :headers="view.lists.headers"
          :items="view.lists.items"
        >
          <template v-slot:item.action="{ item }">
            <v-icon small class="mr-2" @click="onClickRow(item)">mdi-magnify</v-icon>
          </template>
        </v-data-table>
      </template>
    </CGIModal>

    <ViewTaskTicket ref="ViewTaskTicket" />
  </div>
</template>
<script>
import http from "@/plugins/http";
import CGIModal from "@/components/CGIModal";
import ViewTaskTicket from "../../view-task-ticket/ViewTaskTicket";

const objectModel = {
  startDate: "",
  endDate: "",
  type: "",
  icon: "",
  info: ""
};

export default {
  name: "",

  components: {
    CGIModal,
    ViewTaskTicket
  },

  props: {},

  data() {
    return {
      model: Object.assign({}, objectModel),
      view: {
        windowSize: 1000,
        loading: false,
        modal: {
          show: false
        },
        lists: {
          items: [],
          headers: []
        }
      }
    };
  },

  computed: {},

  watch: {},

  methods: {
    setGrid() {
      this.view.lists.items = [];
      switch (this.model.type) {
        case "ticket_total_pending":
          this.view.title = "Tickets Pendentes";
          this.view.lists.headers = [
            { text: "Id", value: "id" },
            { text: "Tarefa", value: "titulo" },
            { text: "Tipo", value: "nomeTipo" },
            { text: "Cliente", value: "nomeCliente" },
            { text: "Usuário", value: "nomeUsuario" }
          ];
          break;

        case "task_total_pending":
          this.view.title = "Tarefas Pendentes";
          this.view.lists.headers = [
            { text: "Id", value: "id" },
            { text: "Tarefa", value: "titulo" },
            { text: "Tipo", value: "nomeModelo" },
            { text: "Cliente", value: "nomeCliente" },
            { text: "Usuário", value: "nomeUsuario" }
          ];
          break;

        case "resume_users":
          this.view.title = "Usuários";
          this.view.lists.headers = [
            { text: "Id Atend.", value: "id" },
            { text: "Id Tarefa", value: "ticketId" },
            { text: "Atendimento", value: "titulo" },
            { text: "Cliente", value: "nomeCliente" },
            { text: "Tempo", value: "tempo" }
          ];
          break;

        case "resume_clients":
          this.view.title = "Clientes";
          this.view.lists.headers = [
            { text: "Id", value: "id" },
            { text: "Tarefa", value: "titulo" },
            { text: "Solicitante", value: "nomeSolicitante" }
          ];
          break;

        case "resume_requesters":
          this.view.title = "Solicitantes";
          this.view.lists.headers = [
            { text: "Id", value: "id" },
            { text: "Tarefa", value: "titulo" },
            { text: "Atendente", value: "nomeUsuario" }
          ];
          break;
      }

      this.view.lists.headers.push({ width: "2", value: "action" });
    },

    close() {
      this.view.modal.show = false;
    },

    load(startDate, endDate, type, icon, title, info) {
      this.model.startDate = startDate;
      this.model.endDate = endDate;
      this.model.type = type;
      this.model.icon = icon;
      this.model.info = info;

      this.view.loading = true;
      this.setGrid();

      this.view.title = this.view.title + " - " + title;
      this.view.modal.show = true;

      this.startDate = startDate;
      this.endDate = endDate;

      let Url = `ticket/dashboard-detail/${type}/${info}/${startDate}/${endDate}/0`;

      http.get(Url).then(response => {
        this.view.lists.items = response.data.data;
        this.view.loading = false;
      });
    },

    onClickRow(item) {
      this.$refs.ViewTaskTicket.openModal(item.ticketId);
    }
  }
};
</script>
<style>
</style>
