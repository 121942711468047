import axios from "axios";
// const https = require("https");

// Configuração default para todas as requisições
const http = axios.create({
  // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
    //baseURL: "https://localhost:44368",
   baseURL:"https://suporteapi.headthink.online/",

  headers: {
    "Content-Type": "application/json",
    Authorization: {
      toString() {
        // TODO ALAN
        let token = localStorage.getItem("token");
        if (token) {
          return `Bearer ${token}`;
        }
      },
    },
    "Allow-Control-Allow-Origin": "*",
  },
});

http.interceptors.request.use((config) => {
  let currentProject = JSON.parse(localStorage.getItem("currentProject"));
  config.headers.get["projectId"] =
    currentProject != null ? currentProject.id : null;
  //config.headers.put["projectId"] = currentProject != null ? currentProject.id : null;
  //config.headers.post["projectId"] = currentProject != null ? currentProject.id : null;
  return config;
});

http.interceptors.request.use((config) => {
  let currentProject = JSON.parse(localStorage.getItem("currentProject"));
  //config.headers.get["projectId"] = currentProject != null ? currentProject.id : null;
  //config.headers.put["projectId"] = currentProject != null ? currentProject.id : null;
  config.headers.post["projectId"] =
    currentProject != null ? currentProject.id : null;
  return config;
});

http.interceptors.request.use((config) => {
  let currentProject = JSON.parse(localStorage.getItem("currentProject"));
  //config.headers.get["projectId"] = currentProject != null ? currentProject.id : null;
  config.headers.put["projectId"] =
    currentProject != null ? currentProject.id : null;
  //config.headers.post["projectId"] = currentProject != null ? currentProject.id : null;
  return config;
});

// Interceptando requisição para tratar error retornados pelo serviço
http.interceptors.response.use(
  (response) => {
    return response;
  },
  function(error) {
    if (error.response == undefined || error.response == null) {
      error.response = {
        status: 500,
        data: { message: error.message },
      };
    }

    let httpStatus = error.response.status;

    switch (httpStatus) {
      case 401: {
        window.Vue.notify(
          `Sua sessão expirou faça login novamente para continuar`,
          "error"
        );

        /*
        let currentURL = window.location.pathname;
        localStorage.clear();
        window.Vue.$router.replace({ path: `/auth?redirect=${currentURL}` });
        */
        break;
      }

      case 500: {
        window.Vue.notify(
          `Erro, entre em contato com Suporte ${error}`,
          "error"
        );
        break;
      }

      case 400: {
        window.Vue.notify(`${error.response.data.message}`, "error");
        break;
      }

      case 404: {
        window.Vue.notify(`${error.response.data.message}`, "error");
        break;
      }
    }

    return Promise.reject(error);
  }
);

export default http;
