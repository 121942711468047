import List from "./List";

export default [
  {
    path: "/modulo/listar/",
    meta: {
      public: false,
    },
    name: "Listar Modulos",
    component: List,
  },
];
