<template>
  <div>
    <CGIModal
      :loading="view.loading"
      :exibir="view.showModal"
      :titulo="view.title"
      :fullScreen="true"
      :scrollable="true"
      @clickCancelar="close"
      @clickConfirmar="close"
    >
      <template v-slot:body>
        <v-card>
          <v-tabs background-color="primary" dark grow>
            <v-tab>
              <v-icon left>mdi-account-voice</v-icon>Ticket
            </v-tab>
            <v-tab>
              <v-icon left>mdi-console</v-icon>tarefa
            </v-tab>

            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <tab-ticket-vue ref="TabTicketVue" :dataProp="model.dataTicket" />
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <tab-task-vue ref="TabTaskVue" :dataProp="model.dataTask" />
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </template>
      <template v-slot:actions class="text-right">
        <v-spacer />
        <v-btn right color="primary" @click="close">Ok</v-btn>
      </template>
    </CGIModal>
  </div>
</template>

<script>
import { HttpRequestTask } from "../task/http";
import { HttpRequestTicket } from "../ticket/http";
import CGIModal from "@/components/CGIModal";
import TabTicketVue from "./components/TabTicket.vue";
import TabTaskVue from "./components/TabTask.vue";
import moment from "moment";

export default {
  components: {
    CGIModal,
    TabTicketVue,
    TabTaskVue
  },
  data() {
    return {
      model: {
        id: 0,
        dataTicket: {
          data: {},
          historic: []
        },
        dataTask: {
          data: {},
          historic: []
        }
      },
      view: {
        showModal: false,
        loading: true,
        title: ""
      }
    };
  },
  methods: {
    openModal(id) {
      this.clearModel();
      this.model.id = Object.assign(id);
      this.view.title = `Visualisar Ticket / Tarefa [${id}]`;
      this.view.showModal = true;
      this.view.loading = true;
      this.load(id);
    },
    close() {
      this.clearModel();
      this.view.showModal = false;
    },
    load(id) {
      let proTicket = HttpRequestTicket.getQuickViewById(id);
      let proHistTicket = HttpRequestTicket.getHistorico(id);

      let proTask = HttpRequestTask.getQuickViewById(id);
      let proHistTask = HttpRequestTask.getHistorico(id);

      Promise.all([proTicket, proHistTicket]).then(response => {
        this.model.dataTicket.data = this.dataProcessing(response[0].data.data);

        this.model.dataTicket.historic = response[1].data.data;

        this.view.loading = false;
      });

      Promise.all([proTask, proHistTask])
        .then(response => {
          this.model.dataTask.data = this.dataProcessing(response[0].data.data);

          this.model.dataTask.historic = response[1].data.data;

          this.view.loading = false;
        })
        .catch(() => {
          this.model.dataTask = { data: {}, historic: [] };
        });
    },

    dataProcessing(data) {
      data.dataCadastro = moment(data.dataCadastro).format(
        "DD/MM/YYYY hh:mm:ss"
      );
      data.dataPrevisao = moment(data.dataPrevisao).format("DD/MM/YYYY");
      data.ehTarefa = data.ehTarefa ? "Sim" : "Não";

      return data;
    },

    clearModel() {
      this.model = {
        id: 0,
        dataTicket: {
          data: {},
          historic: []
        },
        dataTask: {
          data: {},
          historic: []
        }
      };
    }
  }
};
</script>

<style>
</style>