<template>
  <div>
    <CGIModal
      :loading="view.loading"
      :exibir="view.modal.show"
      :titulo="view.action"
      :fullScreen="true"
      :scrollable="true"
      @clickCancelar="close"
      @clickConfirmar="submit"
    >
      <template v-slot:body>
        <v-form ref="form" v-model="view.valid" lazy-validation>
          <v-row>
            <v-col cols="12" md="5">
              <v-row dense>
                <v-col cols="12" md="4">
                  <CGITextField
                    label="Id"
                    hint="Id do usuário"
                    v-model="model.id"
                    disabled
                  />
                </v-col>
                <v-col cols="12" md="8">
                  <CGIComboBox
                    v-model="model.clienteUsuarioId"
                    route="/combo/clienteusuario"
                    label="Solicitante"
                    hint="Selecione o solicitante"
                    :itemsAsync="true"
                    required
                    :rules="[view.rules.required]"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12">
                  <CGITextField
                    label="Título"
                    type="text"
                    hint="Entre com o título do ticket"
                    counter="100"
                    :rules="[view.rules.required]"
                    required
                    v-model="model.titulo"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" md="6">
                  <CGIComboBox
                    v-model="model.tipoTicketId"
                    route="/combo/tipoticket"
                    label="Tipo de Ticket"
                    hint="Selecione o tipo de ticket"
                    :itemsAsync="true"
                    required
                    :rules="[view.rules.required]"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <CGIComboBox
                    v-model="model.statusId"
                    route="/combo/statusticket"
                    label="Status de Ticket"
                    hint="Selecione o status de ticket"
                    :itemsAsync="true"
                    required
                    :rules="[view.rules.required]"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" md="6">
                  <CGIDatePicker
                    v-model="model.dataPrevisao"
                    label="Data Previsão"
                    :required="true"
                    :loadTodayPlus="3"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <CGIComboBox
                    v-model="model.moduloPrincipalId"
                    route="/combo/modulo"
                    label="Módulo Principal"
                    hint="Selecione o módulo principal"
                    :itemsAsync="true"
                    required
                    :rules="[view.rules.required]"
                  />
                </v-col>

                <v-col cols="12" md="3">
                  <!-- Observe a classe do checkbox, usada para alinha ele na tela -->
                  <v-checkbox
                    class="mt-1 ml-1"
                    v-model="model.ehTarefa"
                    label="Tarefa DEV"
                    hint="Informe se o ticket irá ser uma tarefa para DEV"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <!-- Observe a classe do checkbox, usada para alinha ele na tela -->
                  <v-checkbox
                    class="mt-1 ml-1"
                    v-model="model.definirTarefa"
                    label="Definir Tarefa"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12">
                  <CGITextEditor
                    class="mb-4"
                    v-model="model.solicitacao"
                    :dateModel="view.solicitacaoModel"
                    label="Solicitação"
                    placeholder="Entre com a solicitação"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" md="6">
                  <CGIComboBox
                    v-model="model.prioridade"
                    route="/combo/prioridade"
                    label="Prioridade"
                    hint="Defina a prioridade do ticket"
                    :itemsAsync="true"
                    required
                    :rules="[view.rules.required]"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <CGIComboBox
                    v-model="model.internoExterno"
                    route="/combo/internoexterno"
                    label="Privacidade"
                    hint="Defina a privacidade do ticket"
                    :itemsAsync="true"
                    required
                    :rules="[view.rules.required]"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <CGIComboBox
                    v-model="model.projetoId"
                    route="/combo/projetos"
                    label="Projeto"
                    hint="Defina o projeto"
                    :itemsAsync="true"
                    required
                    :rules="[view.rules.required]"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" md="6">
                  <CGIComboBox
                    v-model="model.usuarioId"
                    route="/combo/usuario"
                    label="Usuário"
                    hint="Selecione o usuário"
                    :itemsAsync="true"
                    required
                    :rules="[view.rules.required]"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <CGIDatePicker
                    v-model="model.dataCadastro"
                    label="Data Cadastro"
                    :required="true"
                    :loadToday="true"
                    :dateModel="view.dateModelCadastro"
                    :disabled="model.id != 0"
                  />
                </v-col>

                <v-col cols="12" md="12">
              <CGIComboBox
                v-model="model.reuniaoId"
                route="/combo/reuniao-validas"
                label="Reunião"
                hint="Defina a reunião (opcional)"
                :itemsAsync="true"
              />
            </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="12">
                  <CGIFileUpload
                    v-model="model.anexos"
                    routerDownload="ticket"
                    @removed="(value) => removeAttachments(value)"
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" md="7">
              <div class="my-2" v-if="model.id > 0">
                <v-btn
                  small
                  block
                  class="ma-2"
                  color="info"
                  dark
                  @click="onClickNewService()"
                >
                  Informar Atendimento
                  <v-icon right dense>mdi-account-voice</v-icon>
                </v-btn>
              </div>
              <div v-if="!model.definirTarefa">
                <CGIDataTable
                  :columns="view.colunas.historico"
                  :items="view.lists.historico"
                  :expandedFilter="false"
                />
              </div>
              <div v-else>
                <v-col cols="12" md="12">
                  <CGITextEditor
                    class="mb-4"
                    v-model="model.obsTarefa"
                    :dateModel="view.obsTarefa"
                    label="Observações da Tarefa"
                    placeholder="Entre com as observações da tarefa"
                    rows="8"
                  />
                  <CGITextEditor
                    class="mb-4"
                    v-model="model.obsTarefaFront"
                    :dateModel="view.obsTarefaFront"
                    label="Observações da Tarefa FRONT"
                    placeholder="Entre com as observações da tarefa FRONT"
                    rows="8"
                  />
                  <v-textarea
                    v-model="model.testesTarefa"
                    label="Testes da Tarefa"
                    type="text"
                    rows="4"
                    maxlength="600"
                    hint="Entre com os testes para a tarefa"
                    dense
                    outlined
                    required
                    counter="600"
                    :rules="[view.rules.required]"
                  />
                  <v-textarea
                    v-model="model.resumoCliente"
                    label="Resumo para o Cliente"
                    type="text"
                    rows="2"
                    maxlength="600"
                    hint="Notificação que o cliente receberá quando a tarefa for lançada em Produção."
                    dense
                    outlined
                    required
                    counter="600"
                    :rules="[view.rules.required]"
                  />
                </v-col>
                <v-row dense>
                  <v-col cols="12" md="3">
                    <CGICurrency
                      label="Tempo (minutos) do Atendimento"
                      hint="Entre com o tempo, em minutos, do atendimento"
                      v-model="model.tempoAtualizacao"
                      :rules="[view.rules.required]"
                      type="int"
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <CGIDatePicker
                      v-model="model.dataPrevisao"
                      label="Data Previsão"
                      :required="true"
                      :rules="[view.rules.required]"
                      :loadTodayPlus="3"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <CGIComboBox
                      v-model="model.usuarioTestePrincipalId"
                      route="/combo/usuario"
                      label="Usuário Teste"
                      hint="Selecione o usuário para realizar o teste"
                      :itemsAsync="true"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <CGIComboBox
                      v-model="model.usuarioTarefaId"
                      route="/combo/usuarioDev"
                      label="Usuário Tarefa (BACK)"
                      hint="Selecione o usuário para Fazer a Tarefa (BACK)"
                      :itemsAsync="true"
                    />
                  </v-col>
                    <v-col cols="12" md="6">
                    <CGIComboBox
                      v-model="model.usuarioTarefaFrontId"
                      route="/combo/usuarioDev"
                      label="Usuário Tarefa (FRONT)"
                      hint="Selecione o usuário para Fazer a Tarefa (FRONT)"
                      :itemsAsync="true"
                    />
                  </v-col>
                </v-row>
                <v-col cols="12" md="12">
                  <v-row dense>
                    <v-col cols="12" md="3">
                      <v-checkbox
                        v-model="model.criarBaseConhecimento"
                        :label="`Criar Base de Conhecimento`"
                    /></v-col>
                    <v-col cols="12" md="3">
                      <v-checkbox
                        v-model="model.alterarMenu"
                        :label="`Alterar Menu`"
                      />
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-checkbox
                        v-model="model.implantarTarefa"
                        :label="`Implementar Cliente`"
                    /></v-col>
                    <v-col cols="12" md="3">
                      <v-checkbox
                        v-model="model.notificarCliente"
                        :label="`Notificar Cliente`"
                    /></v-col>
                  </v-row>
                  <v-col v-show="model.alterarMenu" cols="12" md="6">
                    <CGITextField
                      label="Menu"
                      type="text"
                      hint="Entre com o Menu"
                      v-model="model.menuTarefa"
                    />
                  </v-col>
                   <v-col v-show="model.criarBaseConhecimento" cols="12" md="6">
                    <CGITextField
                      label="Título Base de Conhecimento"
                      type="text"
                      hint="Entre com o Título"
                      v-model="model.TituloBaseConhecimento"
                    />
                  </v-col>
                  <CGIDataTable
                    :showSearch="true"
                    :itemsPerPage="5"
                    :columns="view.colunas.clientes"
                    :items="view.lists.clientes"
                    :expandedFilter="false"
                  />
                </v-col>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </CGIModal>
    <InformService ref="InformService" @onSuccess="callChange" />
    <SetTask ref="SetTask" @onSuccess="close()" />
  </div>
</template>
<script>
import { HttpRequestTicket } from "./http";
import { mask } from "vue-the-mask";
import CGIModal from "@/components/CGIModal";
import CGITextField from "@/components/CGITextField";
import CGIComboBox from "@/components/CGICombobox";
import CGIDataTable from "@/components/CGIDataTable";
import CGIDatePicker from "@/components/CGIDatePicker";
import CGITextEditor from "@/components/CGITextEditor";
import CGIFileUpload from "@/components/CGIFileUpload";
import CGICurrency from "@/components/CGICurrency";

import SetTask from "../task/SetTask";
import InformService from "./InformService";
import { HttpRequestCliente } from "../client/http";

const objectModel = {
  id: 0,
  clienteUsuarioId: 0,
  usuarioId: 0,
  prioridade: null,
  tipoTicketId: 0,
  solicitacao: "",
  titulo: "",
  statusId: 0,
  solucaoApresentada: "",
  obsConclusao: "",
  usuarioConclusaoId: 0,
  informarConclusao: false,
  informarAtualizacao: false,
  tituloAtualizacao: "",
  statusAtualizacaoId: 0,
  tempoAtualizacao: 0,
  obsAtualizacao: "",
  ehTarefa: false,
  internoExterno: null,
  moduloPrincipalId: 0,
  anexos: [],
  removed: [],
  definirTarefa: false,
};

export default {
  name: "FormTicket",

  directives: {
    mask,
  },

  components: {
    CGIModal,
    CGITextField,
    CGIComboBox,
    CGIDataTable,
    CGIDatePicker,
    CGITextEditor,
    CGIFileUpload,
    InformService,
    SetTask,
    CGICurrency,
  },

  props: {},

  data() {
    return {
      model: Object.assign({}, objectModel),
      view: {
        action: "Incluindo novo ticket",
        loading: false,
        lists: {
          historico: [],
        },
        colunas: {
          historico: [
            {
              text: "Id",
              value: "id",
            },
            {
              text: "Data",
              value: "dataCadastro",
              type: "datetime",
            },
            {
              text: "Título",
              value: "titulo",
            },
            {
              text: "Obs.",
              value: "obs",
              type: "html",
            },
            {
              text: "Usuário",
              value: "nomeUsuario",
            },
          ],
          clientes: [
            {
              text: "",
              value: "selecionado",
              type: "checkbox",
              config: {
                disabled: false,
              },
            },
            {
              text: "Id",
              value: "id",
            },
            {
              text: "Cliente",
              value: "identificacao",
            },
          ],
        },
        modal: {
          show: false,
        },
        rules: {
          required: (value) => !!value || "Campo Obrigatório.",
          requiredTempo: (value) => value == 0 || "Informe o valor",
        },
        ticketId: 0,
        dateModelPrevisao: "",
        dateModelCadastro: "",
        solicitacaoModel: "",
      },
    };
  },

  computed: {},

  watch: {},

  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (
        this.model.id == undefined ||
        this.model.id == null ||
        this.model.id == 0
      ) {
        this.save();
      } else {
        this.update();
      }
    },

    save() {
      this.view.loading = true;
      HttpRequestTicket.postTicket(this.model)
        .then((data) => {
          this.model.id = data.data.data;

          // Gravar Anexos
          if (this.model.anexos.length > 0) {
            let formData = new FormData();
            for (let file of this.model.anexos) {
              formData.append("files", file, file.name);
            }
            HttpRequestTicket.postUpload(formData, this.model.id);
          }

          // Caso tenha marcado que é uma tarefa
          // fecha o modal atual e já abre o SetTask()
          if (this.model.ehTarefa) {
            this.$refs.SetTask.openModal(this.model.id);
          } else {
            this.change(this.model);
          }

          this.notify("Ticket incluído com sucesso", "success");
          this.close();
          this.$emit("onSuccess");
        })
        .catch((error) => {
          this.view.loading = false;

          let message = error.response.data.message;
          this.notify(message, "error");
        })
        .then(() => {
          this.view.loading = false;
          this.$emit("onSuccess");
        });
    },

    update() {
      this.view.loading = true;

      // Atualizando Ticket
      let putTicket = HttpRequestTicket.putTicket(this.model);

      // Editar Anexos
      let formData = new FormData();
      for (let file of this.model.anexos) {
        if (file.id == undefined) formData.append("files", file, file.name);
      }

      formData.append("removidos", this.model.removed);

      let putUpload = HttpRequestTicket.putUpload(formData, this.model.id);

      Promise.all([putTicket, putUpload])
        .then(() => {
          this.notify("Ticket alterado com sucesso", "success");
          this.change(this.model);
          this.close();
          this.$emit("onSuccess");
        })
        .catch((error) => {
          console.log(error);
          let message = error.response.data.message;
          this.notify(message, "error");
        })
        .then(() => {
          this.view.loading = false;
          this.$emit("onSuccess");
        });
    },

    close() {
      this.model = objectModel;
      this.view.modal.show = false;
      this.view.lists.historico = [];
      this.$emit("onSuccess");
    },

    add() {
      this.model = Object.assign({}, objectModel);
      this.view.modal.show = true;
      this.view.action = "Incluindo novo ticket";
      this.view.loading = false;
    },

    change(ticket) {
      this.model = Object.assign({}, objectModel);
      this.view.modal.show = true;
      this.view.ticketId = ticket.id;
      this.view.action = "Alterando ticket [" + ticket.id + "]";
      this.view.loading = true;

      let proTicket = HttpRequestTicket.getById(ticket.id);
      let proHistorico = HttpRequestTicket.getHistorico(ticket.id);
      let proCliente = HttpRequestCliente.get();

      Promise.all([proTicket, proHistorico, proCliente]).then((response) => {
        this.model = response[0].data.data;
        this.view.lists.historico = response[1].data.data;
        this.view.lists.clientes = response[2].data.data;

        // Forçando cópias para mandar pros componentes
        this.view.dateModelPrevisao = Object(this.model.dataPrevisao);
        this.view.dateModelCadastro = Object(this.model.dataCadastro);
        this.view.solicitacaoModel = Object(this.model.solicitacao);

        this.view.loading = false;

        this.dataProcessing();
      });
    },

    /**
     * Ao clicar ação de abrir modal para lançar Novo Atendimento
     */
    onClickNewService() {
      this.$refs.InformService.openModal(
        this.view.ticketId,
        this.model.titulo,
        this.model.internoExterno
      );
    },

    callChange() {
      this.change(this.model);
    },

    dataProcessing() {
      switch (this.model.prioridade) {
        case "U":
          this.model.prioridade = 1;
          break;
        case "A":
          this.model.prioridade = 2;
          break;
        case "M":
          this.model.prioridade = 3;
          break;
        case "B":
          this.model.prioridade = 4;
          break;
      }

      switch (this.model.internoExterno) {
        case "I":
          this.model.internoExterno = 1;
          break;
        case "E":
          this.model.internoExterno = 2;
          break;
      }
    },

    removeAttachments(item) {
      if (this.model.removed == undefined) this.model.removed = [];
      this.model.removed.push(item);
    },
  },
};
</script>
<style></style>
