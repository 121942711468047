import List from "./List";

export default [
  {
    path: "/usuario/listar/",
    meta: {
      public: false,
    },
    name: "Listar Usuários",
    component: List,
  },
];
