<template>
  <div>
    <v-row v-if="!dataProp.data.id">
      <v-col>
        <p class="title text-center">Tarefa não definida ou não necessária.</p>
      </v-col>
    </v-row>

    <v-row v-if="dataProp.data.id">
      <v-col cols="12">
        <v-card outlined class="mb-4">
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="3">
                <span>
                  <b>Número da Tarefa:</b>
                  <v-spacer />
                  {{ dataProp.data.id }}
                </span>
              </v-col>
              <v-col cols="12" md="9">
                <span>
                  <b>Título:</b>
                  <v-spacer />
                  {{ dataProp.data.tituloTarefa }}
                </span>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" md="3">
                <span>
                  <b>Tipo Ticket:</b>
                  <v-spacer />
                  {{ dataProp.data.nomeTipoTicket }}
                </span>
              </v-col>
              <v-col cols="12" md="3">
                <span>
                  <b>Status Ticket:</b>
                  <v-spacer />
                  {{ dataProp.data.nomeStatus }}
                </span>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" md="3">
                <span>
                  <b>Módulo Principal:</b>
                  <v-spacer />
                  {{ dataProp.data.nomeModuloPrincipal }}
                </span>
              </v-col>
              <v-col cols="12" md="3">
                <span>
                  <b>Privacidade:</b>
                  <v-spacer />
                  {{ dataProp.data.internoExterno }}
                </span>
              </v-col>
              <v-col cols="12" md="3">
                <span>
                  <b>Reportado por:</b>
                  <v-spacer />
                  {{ dataProp.data.nomeUsuario }}
                </span>
              </v-col>
              <v-col cols="12" md="3">
                <span>
                  <b>Responsável:</b>
                  <v-spacer />
                  {{ dataProp.data.nomeUsuarioTarefa }}
                </span>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" md="3">
                <span>
                  <b>Data Cadastro:</b>
                  <v-spacer />
                  {{ dataProp.data.dataCadastro }}
                </span>
              </v-col>
              <v-col cols="12" md="3">
                <span>
                  <b>Data Previsão:</b>
                  <v-spacer />
                  {{ dataProp.data.dataPrevisao }}
                </span>
              </v-col>

              <v-col cols="12" md="2">
                <span>
                  <b>Prioridade:</b>
                  <v-spacer />
                  {{ dataProp.data.prioridade }}
                </span>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="6">
                <CGITextEditor
                  class="mb-4"
                  v-model="dataProp.data.obsTarefa"
                  label="Observações da Tarefa (BACK)"
                  disabled
                />
              </v-col>
              <v-col cols="6">
                <CGITextEditor
                  class="mb-4"
                  v-model="dataProp.data.obsTarefaFront"
                  label="Observações da Tarefa (FRONT)"
                  disabled
                />
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12">
                <span>
                  <b>Testes da Tarefa:</b>
                  <v-spacer />
                  {{ dataProp.data.testesTarefa }}
                </span>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12">
                <span>
                  <b>Branch:</b>
                  <v-spacer />
                  <a :href="dataProp.data.branch" target="_blank">{{ dataProp.data.branch }}</a>
                </span>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12">
                <span>
                  <b>SQLs:</b>
                  <v-spacer />
                  <code type="sql" class>{{ dataProp.data.comandosSQL }}</code>
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-row v-if="dataProp.data.id">
      <v-col cols="12">
        <p class="title text-center">Lançamentos</p>
        <CGIDataTable
          :columns="view.colunas.historico"
          :items="dataProp.historic"
          :expandedFilter="false"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CGIDataTable from "@/components/CGIDataTable.vue";
import CGITextEditor from "@/components/CGITextEditor.vue";
export default {
  components: {
    CGIDataTable,
    CGITextEditor
  },
  props: {
    dataProp: {}
  },
  data() {
    return {
      view: {
        colunas: {
          historico: [
            {
              text: "Id",
              value: "id"
            },
            {
              text: "Data",
              value: "dataCadastro",
              type: "datetime"
            },
            {
              text: "Título",
              value: "titulo"
            },
            {
              text: "Obs.",
              value: "obs",
              type: "html"
            },
            {
              text: "Usuário",
              value: "nomeUsuario"
            }
          ]
        }
      }
    };
  }
};
</script>

<style>
</style>