<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <CGITextField
          v-model="dateDisplayed"
          :label="label"
          type="text"
          prepend-inner-icon="mdi-calendar"
          :clearable="clearable"
          :disabled="disabled"
          :required="required"
          :rules="rules"
          v-on="on"
        />
      </template>

      <v-date-picker
        v-model="date"
        :no-title="noTitle"
        :scrollable="scrollable"
        @input="menu = false"
        :disabled="disabled"
      >
        <!-- TODO - Dar a possíbilidade de optar por confirmar a data via botões -->
        <!-- <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
        <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>-->
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import CGITextField from "@/components/CGITextField";
import moment from "moment-business-days";

export default {
  name: "",
  components: {
    CGITextField,
  },

  props: {
    /**
     * Valor definido via v-model
     */
    value: null,
    /**
     * Título do campo calendário
     */
    label: {
      default: "Data",
      type: String,
    },
    /**
     * Botão de limpar o campo
     */
    clearable: {
      default: true,
      type: Boolean,
    },
    /**
     * Calendário com Título
     */
    noTitle: {
      default: true,
      type: Boolean,
    },
    /**
     * Possibilidade de "navegar" no calendário com o mouse (botão do meio)
     */
    scrollable: {
      default: true,
      type: Boolean,
    },
    /**
     * Define se o componente é obrigatório
     */
    required: {
      default: false,
      type: Boolean,
    },
    /**
     * Carrega a data corrente
     */
    loadToday: {
      default: false,
      type: Boolean,
    },
    /**
     * Quantidade de dias a serem adicionados a data corrente
     */
    loadTodayPlus: {
      default: 0,
      type: Number,
    },
    /**
     * Quantidade de dias a serem subtraidos a data corrente
     */
    loadTodayMinus: {
      default: 0,
      type: Number,
    },
    /**
     * Regras de validação do campo
     */
    rules: {
      default() {
        return [];
      },
      type: Array,
    },
    /**
     * Define se o componente é editável
     */
    disabled: {
      default: false,
      type: Boolean,
    },
  },

  data: () => ({
    date: null,
    dateDisplayed: null,
    dateToBeSent: null,
    menu: false,
  }),

  mounted() {
    if (this.loadToday) {
      this.date = new Date().toISOString().substr(0, 10);
    }

    if (this.loadTodayPlus > 0) {
      this.date = moment()
        .businessAdd(this.loadTodayPlus)
        .toISOString()
        .substr(0, 10);
    }

    if (this.loadTodayMinus > 0) {
      this.date = moment()
        .businessSubtract(this.loadTodayMinus)
        .toISOString()
        .substr(0, 10);
    }
  },

  computed: {},

  watch: {
    value() {
      this.date = this.value;
      this.dateDisplayed = this.formatDatePTBR(this.date);
    },
    date(newValue, oldValue) {
      if (newValue != oldValue) {
        this.$emit("input", newValue);
      }
    },
  },

  methods: {
    /**
     * FORMATA A DATA PADRÃO BR (DATA QUE É EXIBIDA NA TELA)
     */
    formatDatePTBR(date) {
      if (!date) return null;

      return moment(date).format("DD/MM/YYYY");
    },
  },
};
</script>

<style></style>
