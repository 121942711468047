import Dashboard from "./Dashboard";

export default [
  {
    path: "/dash",
    meta: {
      public: false
    },
    name: "Dashboard",
    component: Dashboard
  }
];
