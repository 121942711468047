var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CGIDataTable',{attrs:{"loading":_vm.view.loading,"columns":_vm.view.columns,"items":_vm.view.lists.releases},on:{"clickRemove":function (value) { return _vm.onClickRemove(value); },"clickChange":function (value) { return _vm.onClickChange(value); }},scopedSlots:_vm._u([{key:"filtro",fn:function(){return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"3","md":"3"}},[_c('CGITextField',{attrs:{"label":"Versão"},model:{value:(_vm.model.versao),callback:function ($$v) {_vm.$set(_vm.model, "versao", $$v)},expression:"model.versao"}})],1)],1)],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"info"},on:{"click":_vm.onClickAdd}},[_vm._v("Novo")]),_c('v-btn',{staticClass:"ma-2",attrs:{"loading":_vm.view.loading,"color":"primary"},on:{"click":_vm.filter}},[_vm._v(" Filtrar "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("mdi-filter")])],1)],1)],1)]},proxy:true},{key:"outrasAcoes",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"info"},on:{"click":function($event){return _vm.onClickPublish(item)}}},on),[_c('v-icon',[_vm._v("mdi-truck-delivery")])],1)]}}],null,true)},[_c('span',[_vm._v("Publicar Release")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"secondary"},on:{"click":function($event){return _vm.onClickSqlEditor(item)}}},on),[(item.dataPublicacao)?_c('v-icon',[_vm._v("mdi-database-search")]):_c('v-icon',[_vm._v("mdi-database-check")])],1)]}}],null,true)},[(item.dataPublicacao)?_c('span',[_vm._v("Visualizar Sql")]):_c('span',[_vm._v("Editor Sql")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"info"},on:{"click":function($event){return _vm.onClickCloud(item)}}},on),[_c('v-icon',[_vm._v("mdi-source-branch-check")])],1)]}}],null,true)},[_c('span',[_vm._v("Branches do Release")])]),(item.dataPublicacao)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"orange"},on:{"click":function($event){return _vm.onClickSendEmail(item)}}},on),[_c('v-icon',[_vm._v("mdi-email-send-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Enviar e-mail para clientes")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"green"},on:{"click":function($event){return _vm.onClickNotificationClient(item)}}},on),[_c('v-icon',[_vm._v("mdi-bell")])],1)]}}],null,true)},[_c('span',[_vm._v("Clientes Notificados")])])]}}])}),_c('Form',{ref:"form"}),_c('Delete',{ref:"excluir",attrs:{"titulo":"Atenção"}}),_c('Publish',{ref:"Publish",on:{"onSuccess":function($event){return _vm.load()}}}),_c('NotificarClientes',{ref:"NotificarClientes",on:{"onSuccess":function($event){return _vm.load()}}}),_c('Branches',{ref:"Branches",on:{"onSuccess":function($event){return _vm.load()}}}),_c('SqlEditor',{ref:"SqlEditor",on:{"onSucces":function($event){return _vm.load()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }