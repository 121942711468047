import List from "./List";

export default [
  {
    path: "/reuniao/listar/",
    meta: {
      public: false
    },
    name: "Listar Reuniões",
    component: List
  }
];
