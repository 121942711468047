import httpApi from "@/plugins/http";

const router = "certificado";

export const HttpRequestCertificado = {
  /**
   * Atualizar os dados do certificado digital do cliente
   */
  postCert: (params) => {
    return httpApi.post(`${router}`, params);
  }
}