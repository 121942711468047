import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './plugins/router'
import store from './plugins/store'
import money from 'v-money'
import CKEditor from '@ckeditor/ckeditor5-vue';

import './mixins'
import shortcut, { CTRL } from './mixins/shortcut.mjs'

Vue.use(money, { precision: 4 })
Vue.use(CKEditor);
Vue.config.productionTip = false

var vue = new Vue({
   mixins: [
    shortcut('m', CTRL, function() {      
       this.locateTicket();
    })
  ],
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

window.Vue = vue
