<template>
  <v-card outlined class="mb-1">
    <span class="subtitle-1 ml-2">{{label}}</span>
    <ckeditor
      class
      :editor="editor"
      v-model="editorData"
      :config="editorConfig"
      :disabled="disabled"
    />
  </v-card>
</template>

<script>
// ⚠️ NOTE: We don't use @ckeditor/ckeditor5-build-classic any more!
// Since we're building CKEditor from source, we use the source version of ClassicEditor.
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";

import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat.js";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote.js";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold.js";
import Heading from "@ckeditor/ckeditor5-heading/src/heading.js";
import Image from "@ckeditor/ckeditor5-image/src/image.js";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption.js";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle.js";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar.js";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload.js";
import Indent from "@ckeditor/ckeditor5-indent/src/indent.js";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic.js";
import Link from "@ckeditor/ckeditor5-link/src/link.js";
import List from "@ckeditor/ckeditor5-list/src/list.js";
import Table from "@ckeditor/ckeditor5-table/src/table.js";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar.js";
import CodeBlock from "@ckeditor/ckeditor5-code-block/src/codeblock.js";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize.js";
import TodoList from "@ckeditor/ckeditor5-list/src/todolist";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment.js";
import Base64UploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter.js";
import FontColor from "@ckeditor/ckeditor5-font/src/fontcolor.js";
import HorizontalLine from "@ckeditor/ckeditor5-horizontal-line/src/horizontalline.js";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials.js";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph.js";

export default {
  name: "",
  props: {
    value: null,
    label: {
      default: "",
      type: String
    },
    placeholder: {
      default: "Digite alguma informação...",
      type: String
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      editor: ClassicEditor,
      editorData: this.value,
      editorConfig: {
        plugins: [
          Autoformat,
          BlockQuote,
          Bold,
          Heading,
          Image,
          ImageCaption,
          ImageStyle,
          ImageToolbar,
          ImageUpload,
          Indent,
          Italic,
          Link,
          List,
          Table,
          TableToolbar,
          CodeBlock,
          ImageResize,
          TodoList,
          Alignment,
          Base64UploadAdapter,
          FontColor,
          HorizontalLine,
          Essentials,
          Paragraph
        ],
        placeholder: this.placeholder,

        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "fontColor",
            "link",
            "bulletedList",
            "numberedList",
            "todoList",
            "|",
            "codeBlock",
            "alignment",
            // "indent",
            // "outdent",
            "|",
            "imageUpload",
            "blockQuote",
            "insertTable",
            "undo",
            "redo"
          ],

          shouldNotGroupWhenFull: true
        },
        image: {
          toolbar: [
            "imageTextAlternative",
            "imageStyle:full",
            "imageStyle:side"
          ]
        },
        table: {
          contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"]
        }
      }
    };
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue != oldValue) {
        this.editorData = newValue;
      }
    },
    editorData() {
      this.$emit("input", this.editorData);
    }
  }
};
</script>
<style scoped>
</style>