import ClientDashboard from "./dashboad.vue";

export default [
  {
    path: "/client-dash",
    meta: {
      public: false
    },
    name: "Dashboard do Cliente",
    component: ClientDashboard
  }
];
