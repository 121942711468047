<template>
  <div>
    <v-btn large block outlined color="primary" @click="load()">
      <v-icon left>mdi-refresh</v-icon>
      {{ view.title }}
      <span v-if="view.lists.items.length > 0"
        >- ({{ view.lists.items.length }})</span
      >
    </v-btn>

    <div v-for="item in view.lists.items" v-bind:key="item.id">
      <v-card raised class="mx-auto" :color="view.color">
        <v-card-text>
          <v-container dense class="mt-n5">
            <v-row dense>
              <v-col cols="6">
                <v-chip dark small color="indigo darken-1">{{
                  item.id
                }}</v-chip>
              </v-col>

              <v-col cols="6" class="text-right">
                <v-chip small dark :color="item.colorPriori">{{
                  item.nomePrioridade
                }}</v-chip>
              </v-col>
            </v-row>
            <v-row dense>
              <h3 v-html="item.tituloTarefa" v-if="module == 'task'" class="mb-0">
              </h3>
              <h3 v-else v-html="item.titulo" class="mb-0"></h3>
            </v-row>

            <v-row dense class="text--primary">{{ item.nomeCliente }}</v-row>

            <v-row dense class="text--primary">
              <strong v-if="module == 'task'">
                
                <strong v-if="service == 'waitingReleaseTest'">
                  BACK: {{ item.nomeUsuarioTarefa }} <br />
                  FRONT: {{ item.nomeUsuarioFront }} 
                </strong>
                
                <strong v-if="service.includes('FRONT')">
                  FRONT: {{ item.nomeUsuarioFront }}  
                </strong>
                
                <strong v-if="service != 'waitingReleaseTest' && !service.includes('FRONT')"></strong>                
                  <br /> BACK: {{ item.nomeUsuarioTarefa }}            
                </strong>
              </v-row>
              <v-row dense class="text--primary">
                <span style='font-weight: bold;color: red;' v-if="(item.cobrarHoraTecnica && !item.cobrancaHoraTecnicaDefinida)">
                  COBRANÇA NÃO DEFINIDA
                </span>
              </v-row>

                

            
            <v-row dense class="text--primary">
              <strong v-show="item.nomeUsuarioTestePrincipal"
                >Responsável pelo Teste:
                <span style="color: blue">
                  {{ item.nomeUsuarioTestePrincipal }}</span
                ></strong
              >
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="mt-n5">
          <v-row>
            <!-- 
              ATENÇÃO
              De acordo com o "module", vamos exibindo os botões de ação
            -->
            <div class="ml-4" v-show="module == 'ticket'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="primary"
                    v-on="on"
                    depressed
                    small
                    @click="onClickNewService(item)"
                  >
                    <v-icon>mdi-account-voice</v-icon>
                  </v-btn>
                </template>
                <span>Informar Atendimento</span>
              </v-tooltip>
            </div>

            <div class="ml-4" v-show="module == 'task'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="primary"
                    v-on="on"
                    depressed
                    small
                    @click="onClickViewTask(item)"
                  >
                    <v-icon>mdi-comment-edit-outline</v-icon>
                  </v-btn>
                </template>
                <span>Visualizar/Editar Tarefa</span>
              </v-tooltip>
            </div>

            <div class="ml-4">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="primary"
                    v-on="on"
                    depressed
                    small
                    @click="onClickEditTask(item)"
                  >
                    <v-icon v-if="module == 'task'"
                      >mdi-comment-plus-outline</v-icon
                    >
                    <v-icon v-else>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <!-- Do mesmo modo que temos um IF no icone, repetimos esse IF no tooltip -->
                <span v-if="module == 'task'">Atualizar Tarefa</span>
                <span v-else>Alterar Ticket</span>
              </v-tooltip>
            </div>

            <div class="ml-4" v-if="showArrows">
              <v-btn
                v-if="view.showLeft"
                text
                small
                color="primary"
                @click="onClickChangeStatusTask('LEFT', item)"
              >
                <v-icon large>mdi-arrow-left-bold</v-icon>
              </v-btn>
            </div>
            <div v-if="showArrows">
              <v-btn
                v-if="view.showRight"
                text
                small
                color="primary"
                @click="onClickChangeStatusTask('RIGHT', item)"
              >
                <v-icon large>mdi-arrow-right-bold</v-icon>
              </v-btn>
            </div>
            <div v-if="view.showDevButton">
              <v-btn
                color="primary"
                depressed
                @click="onClickTestButton('D', item)"
              >
                Em Desenvolvimento
              </v-btn>
            </div>
            <div class="ml-4" v-if="view.showTestButtons">
              <v-btn
                color="primary"
                depressed
                @click="onClickTestButton('L', item)"
              >
                Lançar Testes
              </v-btn>
            </div>
           
          </v-row>

          <div class="text-right">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  class="ma-1"
                  v-on="on"
                  fab
                  x-small
                  dark
                  color="success"
                  @click="onClickOpenTicketTask(item)"
                >
                  <v-icon>mdi-file-eye</v-icon>
                </v-btn>
              </template>
              <span>Visualiar Ticket/Tarefa</span>
            </v-tooltip>
            <v-btn
              fab
              x-small
              dark
              color="info"
              @click="item.exibirObsTarefa = !item.exibirObsTarefa"
            >
              <v-icon>
                {{
                  item.exibirObsTarefa ? "mdi-chevron-up" : "mdi-chevron-down"
                }}
              </v-icon>
            </v-btn>
          </div>
        </v-card-actions>

        <v-divider v-show="item.exibirObsTarefa" />
        <v-expand-transition>
          <div v-show="item.exibirObsTarefa">
            <v-card-text
              v-if="module == 'task'"
              color="red"
              v-html="item.obsTarefa"
            ></v-card-text>
            <v-card-text
              v-else
              color="red"
              v-html="item.solicitacao"
            ></v-card-text>
          </div>
        </v-expand-transition>
      </v-card>
      <br />
    </div>
    <SetTask ref="SetTask" @onSuccess="load()" />
    <TestTask ref="TestTask" @onSuccess="load()" />
    <UpdateTask ref="UpdateTask" @onSuccess="load()" />
    <FormTicket ref="FormTicket" @onSuccess="load()" />
    <InformService ref="InformService" @onSuccess="load()" />
    <ViewTaskTicket ref="ViewTaskTicket" />
  </div>
</template>

<script>
import http from "@/plugins/http";

import FormTicket from "../app/ticket/Form";
import SetTask from "../app/task/SetTask";
import UpdateTask from "../app/task/UpdateTask";
import TestTask from "../app/task/TestTask";
import InformService from "../app/ticket/InformService";
import ViewTaskTicket from "../app/view-task-ticket/ViewTaskTicket";
import EventBus from "@/plugins/event-bus";

export default {
  name: "ResumeList",

  components: {
    //CGIModal,
    SetTask,
    UpdateTask,
    FormTicket,
    InformService,
    ViewTaskTicket,
    TestTask
  },

  data() {
    return {
      service: "",
      module: "",
      view: {
        title: "",
        color: "white",
        showLeft: true,
        showRight: true,
        showTestButtons: false,
        showDevButton: false,
        lists: {
          items: [],
        },
      },
    };
  },

  props: {
    type: {
      default: "",
      type: String,
    },
    showArrows: {
      default: true,
      type: Boolean,
    },
    showTestButtons: {
      default: false,
      type: Boolean,
    },
  },

  mounted() {
    this.module = "task";
    this.service = this.type;
    
    switch (this.type) {
      case "backLog":
        this.view.showLeft = false;
        this.view.title = `Backlog`;
        break;

      case "planning":
        this.view.title = `Planejamento`;
        break;

      case "selectedForDevBACK":
        this.view.title = `Selecionadas Backend`;
        break;

      case "inDevBACK" || "inDev":
        this.view.title = `Em Desenvolvimento`;
        break;

      case "availableForReviewBACK":
        this.view.title = `Disponíveis para Revisão`;
        break;

        case "selectedForDevFRONT":
        this.view.title = `Selecionadas Frontend`;
        break;

      case "inDevFRONT":
        this.view.title = `Em Desenvolvimento`;
        break;

      case "availableForReviewFRONT":
        this.view.title = `Disponíveis para Revisão`;
        break;

      case "waitingReleaseTest":
        this.view.title = `Aguardando Atualizar para Testar`;
        break;

      case "availableForTest":
        this.view.title = `Disponíveis para Testes`;
        this.view.color = "#FFCC80";
        break;

      case "inTests":
        this.view.showTestButtons = true;
        this.view.showRight = false;
        this.view.title = `Em Teste`;
        break;

      case "testAproved":
        this.view.title = `Teste Aprovado`;
        this.view.color = "#c8e6c9";
        break;

      case "testReproved":
        this.view.title = `Teste Reprovado`;
        this.view.showRight = false;
        this.view.color = "#ffcccb";
        this.view.showDevButton = true;

        break;

      case "inRelease":
        this.view.title = `Em Release`;
        this.view.showLeft = false;
        this.view.showRight = false;
        break;

      case "published":
        this.view.title = `Publicado`;
        this.view.showLeft = false;
        this.view.showRight = false;
        break;

      case "included":
        this.view.title = `Incluídas`;
        break;

      case "inProgress":
        this.view.title = `Em andamento`;
        break;

      case "waitingForClient":
        this.view.title = `Aguardando cliente`;
        break;

      case "sentToDev":
        this.view.title = `Enviada para Dev`;
        this.view.color = "#c8e6c9";
        break;

      case "finished":
        this.view.title = `Concluídas`;
        break;

      case "returnedToSupport":
        this.view.title = `Devolvida para suporte`;
        break;

      case "VisitsMeetings":
        this.view.title = `Visitas / Reuniões`;
        break;

      case "Implantation":
        this.view.title = `Implantação`;
        break;

      case "Training":
        this.view.title = `Treinamento`;
        break;

      case "Others":
        this.view.title = `Outros`;
        break;
      case "PreTicket":
        this.view.title = `Pré-Tickets`;
        break;
      case "PreTicketDevolvido":
        this.view.title = `Pré-Tickets Devolvidos`;
        break;
      case "TicketDevolvido":
        this.view.title = `Tickets Devolvidos`;
        break;
      case "TicketNotificacao":
        this.view.title = `Tickets para Notificar`;
        break;
    }
    let tipoTickeId = 0;
    if (
      this.type == "included" ||
      this.type == "inProgress" ||
      this.type == "waitingForClient" ||
      this.type == "sentToDev" ||
      this.type == "finished" ||
      this.type == "Preticket" ||
      this.type == "PreTicketDevolvido" ||
      this.type == "TicketDevolvidos" ||
      this.type == "TicketNotificacao"
    ) {
      this.module = "ticket";
      this.service = this.type;
      this.view.showLeft = false;
      this.view.showRight = false;
    }
    if (
      this.type == "VisitsMeetings" ||
      this.type == "Implantation" ||
      this.type == "Training" ||
      this.type == "Others" ||
      this.type == "TicketNotificacao"
    ) {
      this.module = "ticket";
      this.service = this.type;
      this.view.showLeft = false;
      this.view.showRight = false;
      return;
    }

    this.load(0, tipoTickeId);

    /**
     * EventBus - CALL_LOAD
     * Usado para chamar os métodos de LOAD da página
     */
    var self = this;
    EventBus.$on("CALL_LOAD", () => {
      self.load();
    });
  },

  methods: {
    onClickViewTask(item) {
      this.$refs.SetTask.openModal(item.id);
    },

    onClickEditTask(item) {
      if (this.module == "task") {
        this.$refs.UpdateTask.openModal(item.id);
      } else {
        this.$refs.FormTicket.change(item);
      }
    },

    onClickNewService(item) {
      this.$refs.InformService.openModal(
        item.id,
        item.titulo,
        item.internoExterno
      );
    },

    onClickTestButton(status, item) {
      if (status == "L"){
        this.$refs.TestTask.openModal(item.id);
        return;
      } 
      else if (status == "A")
        status = "testAproved";

      else if (status == "D") 
        status = "inDev";
      else 
        status = "testReproved";

      http
        .put(`tarefa/change-task-status/${item.id}/${status}`)
        .then(() => {
          this.notify("Tarefa alterada com sucesso", "success");
          this.$emit("clickChange", status);
        })
        .catch((error) => {
          let message = error.response.data.message;
          this.notify(message, "error");
        })
        .then(() => {
          this.view.loading = false;
        });
    },

    onClickChangeStatusTask(direction, item) {
      let status = "";

      if (direction == "LEFT") {
        if (this.type == "planning") status = "backLog";
        
        if (this.type == "selectedForDevBACK") status = "planning";
        if (this.type == "inDevBACK") status = "selectedForDevBACK";
        if (this.type == "availableForReviewBACK") status = "inDevBACK";

        if (this.type == "selectedForDevFRONT") status = "availableForReviewBACK";
        if (this.type == "inDevFRONT") status = "selectedForDevFRONT";
        if (this.type == "availableForReviewFRONT") status = "inDevFRONT";

        if (this.type == "waitingReleaseTest") status = "availableForReviewFRONT";
        if (this.type == "availableForTest") status = "waitingReleaseTest";

        if (this.type == "inTests") status = "availableForTest";
        if (this.type == "testAproved") status = "inTests";
        if (this.type == "testReproved") status = "inTests";

        if (this.type == "inRelease") status = "inTests";
        if (this.type == "published") status = "inRelease";
      }

      if (direction == "RIGHT") {
        if (this.type == "backLog") status = "planning";
        if (this.type == "planning") status = "selectedForDev";

        if (this.type == "selectedForDevBACK") status = "inDevBACK";
        if (this.type == "inDevBACK") status = "availableForReviewBACK";
        if (this.type == "availableForReviewBACK") status = "selectedForDevFRONT";

        if (this.type == "selectedForDevFRONT") status = "inDevFRONT";
        if (this.type == "inDevFRONT") status = "availableForReviewFRONT";
        if (this.type == "availableForReviewFRONT") status = "waitingReleaseTest";

        if (this.type == "waitingReleaseTest") status = "availableForTest";
        if (this.type == "availableForTest") status = "inTests";

        if (this.type == "inTests") status = "testAproved";
        if (this.type == "testAproved") status = "testReproved";
        if (this.type == "testReproved") status = "inRelease";

        if (this.type == "inRelease") status = "published";
      }

      http
        .put(`tarefa/change-task-status/${item.id}/${status}`)
        .then(() => {
          this.notify("Tarefa alterada com sucesso", "success");
          this.$emit("clickChange", status);
        })
        .catch((error) => {
          let message = error.response.data.message;
          this.notify(message, "error");
        })
        .then(() => {
          this.view.loading = false;
        });
    },

    onClickOpenTicketTask(item) {
      this.$refs.ViewTaskTicket.openModal(item.id);
    },

    load(usuarioId = 0, tipoTicketId = 0, clienteId = 0) {
      console.log(usuarioId, tipoTicketId, clienteId);
      this.view.loading = true;

      let Url = `tarefa/resume/${this.service}/${this.module}/${usuarioId}/${tipoTicketId}/${clienteId}`;

      let proSelected = http.get(Url);

      Promise.all([proSelected]).then((response) => {
        this.view.lists.items = response[0].data.data;
        this.view.loading = false;
        console.log(response[0].data.data);
        this.view.lists.items.map((item) => {
          if (item.prioridade == "U") item.colorPriori = "red";
          if (item.prioridade == "A") item.colorPriori = "orange";
          if (item.prioridade == "M") item.colorPriori = "blue";
          if (item.prioridade == "B") item.colorPriori = "green";
        });
      });
    },
  },

  /**
   * Ao destruir, desligamos o EventBus para não fazer chamadas desnecessárias
   */
  destroyed() {
    EventBus.$off("CALL_LOAD");
  },
};
</script>

<style>
.fix {
  overflow-wrap: anywhere;
  word-wrap: break-word;
  word-break: normal;
  hyphens: auto;
}
</style>
