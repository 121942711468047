import List from "./List";

export default [
  {
    path: "/clienteusuario/listar/",
    meta: {
      public: false
    },
    name: "Listar Usuários dos Clientes",
    component: List
  }
];
