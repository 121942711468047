<template>
  <v-btn :loading="loading" :color="config.color" @click="onClick">{{ config.label }}</v-btn>
</template>
<script>
export default {
  props: {
    loading: {
      default: false,
      type: Boolean
    },
    color: {
      default: "",
      type: String
    },
    label: {
      default: "",
      type: String
    },
    type: {
      default: undefined
    }
  },

  data() {
    return {
      config: {
        color: this.color,
        label: this.label
      }
    };
  },

  mounted() {
    this.verifyTypeButton();
  },

  methods: {
    verifyTypeButton() {
      if (this.type == undefined || this.type == "") {
        return;
      }

      if (this.type == "confirm") {
        this.confirm();
      } else if (this.type == "cancel") {
        this.cancel();
      } else if (this.type == "filter") {
        this.filter();
      }
    },

    /**
     * Configurações para button tipo Confirmar
     */
    confirm() {
      this.config.label = "Confirmar";
      this.config.color = this.color.length > 0 ? this.color : "";
      // DEFINRI COM ROMULO - TODO ROMULO
    },

    /**
     * Configurações para button tipo Cancel
     */
    cancel() {
      this.config.label = "Cancelar";
      this.config.color = this.color.length > 0 ? this.color : "";
      // DEFINRI COM ROMULO - TODO ROMULO
    },

    /**
     * Configurações para button tipo filtro
     */
    filter() {
      this.config.label = "Filtrar";
      this.config.color = this.color.length > 0 ? this.color : "";
      // DEFINRI COM ROMULO - TODO ROMULO
    },

    onClick() {
      this.$emit("click");
    }
  }
};
</script>