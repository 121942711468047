<template>
  <div>
    <ModalBase
      :exibir="view.modal.exibir"
      :titulo="'Excluir'"
      @clickCancelar="view.modal.exibir = false"
      @clickConfirmar="confirmar()"
    >
      >
      <template v-slot:body>
        <v-form ref="form" v-model="view.valid" lazy-validation>
          <v-row align="center">
            <v-col cols="3">
              <v-text-field
                dense
                v-model="model.id"
                label="Id"
                type="text"
                readonly
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                dense
                v-model="model.descricao"
                label="Descrição"
                type="text"
                readonly
              />
            </v-col>
            <v-col cols="12" v-if="model.campo1 != ''">
              <v-text-field
                dense
                v-model="model.valor1"
                :label="model.campo1"
                type="text"
                readonly
              />
            </v-col>
            <v-col cols="12" v-if="model.campo2 != ''">
              <v-text-field
                dense
                v-model="model.valor2"
                :label="model.campo2"
                type="text"
                readonly
              />
            </v-col>
            <v-col cols="12" v-if="model.campo3 != null">
              <v-text-field
                dense
                v-model="model.valor3"
                :label="model.campo3"
                type="text"
                readonly
              />
            </v-col>
            <v-col cols="12" v-if="model.campo4 != null">
              <v-text-field
                dense
                v-model="model.valor4"
                :label="model.campo4"
                type="text"
                readonly
              />
            </v-col>
            <v-col cols="12" v-if="model.exibirObsExclusao">
              <v-textarea
                v-model="model.obsExclusao"
                counter
                label="Obs"
                type="text"
                rows="4"
                maxlength="300"
                hint="Entre com a observação sobre esta solicitação"
                dense
                outlined
                :rules="[view.rules.required]"
              />
            </v-col>
          </v-row>
        </v-form>
      </template>
    </ModalBase>
  </div>
</template>
<script>
import ModalBase from "@/components/CGIModal";
import httpApi from "@/plugins/http";

export default {
  name: "",

  components: {
    ModalBase,
  },

  props: {},

  data() {
    return {
      model: {
        id: 0,
        descricao: "",
        campo1: "",
        campo2: "",
        campo3: "",
        campo4: "",
        valor1: "",
        valor2: "",
        valor3: "",
        valor4: "",
        obsExclusao: "",
        exibirObsExclusao: true,
      },

      service: "",
      id: 0,
      view: {
        modal: {
          exibir: false,
        },
        rules: {
          required: (value) => !!value || "Campo Obrigatório.",
        },
      },
    };
  },

  computed: {},

  watch: {},

  methods: {
    confirmar() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.view.loading = true;
      httpApi
        .post(`${this.service}/${this.id}`, this.model)
        .then(() => {
          this.notify("Registro excluído", "success");
          this.$emit("onSuccess");
          this.clear();
          this.view.loading = false;
          this.view.modal.exibir = false;
        })
        .catch((error) => {
          let message = error.response.data.message;
          this.notify(message, "error");
        })
        .then(() => {
          this.clear();
          this.view.loading = false;
          this.view.modal.exibir = false;
        });
    },
    clear() {
      this.model = {};
    },

    abrirModal(model, service) {
      this.service = service;
      this.model = model;
      this.id = model.id;
      this.view.modal.exibir = true;

      let id = this.id;
      httpApi.get(`${service}/${id}`).then((response) => {
        this.model = response.data.data;
        this.view.loading = false;
      });
    },
  },
};
</script>
<style></style>
