<template>
  <div>
    <CGIModal
      :loading="view.loading"
      :exibir="view.modal.show"
      :titulo="view.action"
      :fullScreen="false"
      :scrollable="true"
      :maxWidth="'1000px'"
      @clickCancelar="close"
      @clickConfirmar="submit"
    >
      <template v-slot:body>
        <v-form ref="form" v-model="view.valid" lazy-validation>
          <v-row dense>
            <v-col cols="12" md="6">
              <CGIComboBox
                ref="comboClient"
                v-model="model.clienteUsuarioId"
                route="/combo/clienteusuario"
                label="Solicitante"
                hint="Selecione o solicitante"
                :itemsAsync="true"
                required
                :rules="[view.rules.required]"
              />
            </v-col>
            <v-col cols="1">
              <v-btn color="info" dark @click="onClickAdd">
                <v-icon large>mdi-plus</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" md="5">
              <CGIComboBox
                v-model="model.moduloPrincipalId"
                route="/combo/modulo"
                label="Módulo Principal"
                hint="Selecione o módulo principal"
                :itemsAsync="true"
                required
                :rules="[view.rules.required]"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <CGITextField
                label="Título"
                type="text"
                hint="Entre com o título do ticket"
                :rules="[view.rules.required]"
                required
                v-model="model.titulo"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="4">
              <CGIComboBox
                v-model="model.tipoTicketId"
                route="/combo/tipoticket"
                label="Tipo de Ticket"
                hint="Selecione o tipo de ticket"
                :itemsAsync="true"
                required
                :rules="[view.rules.required]"
              />
            </v-col>
            <v-col cols="12" md="4">
              <CGIComboBox
                v-model="model.statusId"
                route="/combo/statusticket"
                label="Status de Ticket"
                hint="Selecione o status de ticket"
                :itemsAsync="true"
                required
                :rules="[view.rules.required]"
              />
            </v-col>
            <v-col cols="12" md="4">
              <CGIDatePicker
                v-model="model.dataCadastro"
                label="Data Cadastro"
                :required="true"
                :loadToday="true"
                :dateModel="view.dateModelCadastro"
                :rules="[view.rules.required]"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <CGITextEditor
                v-model="model.solicitacao"
                label="Solicitação"
                placeholder="Entre com a solicitação"
                :uniqueId="'solicitacao'"
                :rules="[view.rules.required]"
              />
              <!-- <v-textarea
                v-model="model.solicitacao"
                counter
                label="Solicitação"
                type="text"
                rows="4"
                maxlength="800"
                hint="Entre com a solicitação"
                dense
                outlined
                :rules="[view.rules.required]"
              />-->
            </v-col>

            <v-col cols="12">
              <CGITextEditor
                v-model="model.obsAtendimento"
                label="Observações / Conclusão do atendimento"
                placeholder="Entre com a observação / solução apresentada"
                :uniqueId="'obsAtendimento'"
              />
              <!-- <v-textarea
                v-model="model.obsAtendimento"
                counter
                label="Observações / Conclusão do atendimento"
                type="text"
                rows="4"
                maxlength="800"
                hint="Entre com a observação / solução apresentada"
                dense
                outlined
                :rules="[view.rules.required]"
              />-->
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="4">
              <CGIComboBox
                v-model="model.internoExterno"
                route="/combo/internoexterno"
                label="Privacidade"
                hint="Defina a privacidade do ticket"
                :itemsAsync="true"
                required
                :rules="[view.rules.required]"
              />
            </v-col>
            <v-col cols="12" md="4">
              <CGIComboBox
                v-model="model.prioridade"
                route="/combo/prioridade"
                label="Prioridade"
                hint="Defina a prioridade do ticket"
                :itemsAsync="true"
                required
                :rules="[view.rules.required]"
              />
            </v-col>
            <v-col cols="12" md="4">
              <CGICurrency
                label="Tempo (minutos) do Atendimento"
                hint="Entre com o tempo, em minutos, do atendimento"
                v-model="model.tempoAtualizacao"
                :rules="[view.rules.requiredTempo]"
                type="int"
              />
            </v-col>
            
            <v-col cols="12" md="12">
              <CGIComboBox
                v-model="model.reuniaoId"
                route="/combo/reuniao-validas"
                label="Reunião"
                hint="Defina a reunião (opcional)"
                :itemsAsync="true"
              />
            </v-col>
          </v-row>
        </v-form>
      </template>
    </CGIModal>

    <FormNewClient ref="FormNewClient" @onSuccess="rechargeComboClient()" />
  </div>
</template>
<script>
import { HttpRequestTicket } from "./http";
import CGIModal from "@/components/CGIModal";
import CGITextField from "@/components/CGITextField";
import CGICurrency from "@/components/CGICurrency";
import CGIComboBox from "@/components/CGICombobox";

import { mask } from "vue-the-mask";
import CGIDatePicker from "@/components/CGIDatePicker";
import CGITextEditor from "@/components/CGITextEditor";

import FormNewClient from "../client-requester/Form";

const objectModel = {
  dataCadastro: "",
  clienteUsuarioId: 0,
  prioridade: 3,
  tipoTicketId: 1,
  solicitacao: "",
  titulo: "",
  statusId: 0,
  tempoAtualizacao: 0,
  internoExterno: 1,
  obsAtendimento: "",
  moduloPrincipalId: 0,
  reuniaoId: null
};

export default {
  name: "",

  directives: {
    mask,
  },

  components: {
    CGIModal,
    CGITextField,
    CGIComboBox,
    CGIDatePicker,
    CGICurrency,
    CGITextEditor,
    FormNewClient,
  },

  props: {},

  data() {
    return {
      model: Object.assign({}, objectModel),
      view: {
        action: "Incluindo novo ticket rápido",
        loading: false,
        modal: {
          show: false,
        },
        rules: {
          required: (value) => !!value || "Campo Obrigatório.",
          requiredTempo: (value) => value == 0 || "Informe o valor",
        },
        ticketId: 0,
        dateModelPrevisao: "",
        dateModelCadastro: "",
      },
    };
  },

  computed: {},

  watch: {},

  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.save();
    },

    save() {
      this.view.loading = true;

      HttpRequestTicket.postFastTicket(this.model)
        .then((data) => {
          this.model.id = data.data.data;
          this.notify(
            `Ticket [${this.model.id}] incluído com sucesso`,
            "success"
          );
          this.close();
          this.$emit("onSuccess");
        })
        .catch((error) => {
          let message = error.response.data.message;
          this.notify(message, "error");
        })
        .then(() => {
          this.view.loading = false;
        });
    },

    close() {
      this.model = Object.assign({}, objectModel);
      this.view.modal.show = false;
    },

    add() {
      this.view.modal.show = true;
      this.view.action = "Incluindo novo ticket rápido";
      this.view.loading = false;
    },

    onClickAdd() {
      this.$refs.FormNewClient.add();
    },

    rechargeComboClient() {
      this.$refs.comboClient.load();
    },
  },
};
</script>
<style>
/* .row--dense {
  margin-bottom: -15px;
} */
</style>
