import List from "./List";

export default [
  {
    path: "/cliente/listar/",
    meta: {
      public: false
    },
    name: "Listar Clientes",
    component: List
  }
];
