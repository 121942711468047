<template>
  <div style="padding-left: 5px; padding-right: 5px;">
    <v-card-title v-if="expandedFilter" class="card-title-expanded-filter">
      <slot name="header">
        <span>Filtros</span>

        <v-spacer />

        <v-btn fab text small @click="view.filter.show = !view.filter.show">
          <v-icon v-if="!view.filter.show">mdi-arrow-down</v-icon>
          <v-icon v-if="view.filter.show">mdi-arrow-up</v-icon>
        </v-btn>
      </slot>
    </v-card-title>
    <v-card-title v-if="view.filter.show" class="card-title-filter">
      <slot name="filtro"></slot>
    </v-card-title>
     <v-card-title>
      <v-text-field
        v-model="view.search"
        v-if="showSearch"
        append-icon="mdi-magnify"
        label="Pesquisar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
     
      dense
      class="elevation-1"
      :headers="columns"
      :items="items"
      :page.sync="view.page.actualPage"
      :items-per-page="itemsPerPage"
      hide-default-footer
      :loading="loading"
      :loading-text="'Carregando dados'"
      no-results-text="Sem dados para exibir"
      no-data-text="Sem dados"
      locale="pt-BR"
      :search="view.search"
      @page-count="view.page.pageCount = $event"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="(item, iIndex) in items" :key="`items_${iIndex}`">
            <template v-for="(column, cIndex) in columns">
              <td :key="cIndex">
                <slot v-bind:item="item" :name="column.value">
                  <!-- 
                    Para colunas que não existe uns dos tipos definidos
                  -->
                  <template v-if="column.type == undefined">{{ item[column.value] }}</template>

                  <!-- 
                    Para colunas com valores monetários
                  -->
                  <template v-if="column.type == 'currency'">{{ item[column.value] | currency }}</template>

                  <!-- 
                    Coluna de tipo data formatada por parâmetro
                    Formatos: 
                    Date BR 'DD/MM/YYYY'
                    DateTime: 'DD/MM/YYYY HH:mm:ss' 
                  -->
                  <template
                    v-if="column.type == 'date'"
                  >{{ getDate("date", item[column.value], column.format) }}</template>

                  <template
                    v-if="column.type == 'datetime'"
                  >{{ getDate("datetime", item[column.value], column.format) }}</template>

                  <template
                    v-if="column.type == 'time'"
                  >{{ getDate("time", item[column.value], column.format) }}</template>

                  <!-- Coluna tipo Checkbox
                    objeto default
                    {
                      label: ''         -> texto ser exibido
                      disabled: 'default true'
                    }
                  -->
                  <template v-if="column.type == 'checkbox'">
                    <v-simple-checkbox
                      :ripple="false"
                      dense
                      v-model="item[column.value]"
                      :disabled="(column.config || { disabled: true }).disabled"
                    />

                    <!-- <v-checkbox
                      dense
                      :disabled="(column.config || { disabled: true }).disabled"
                      :label="(column.config || { label: '' }).label"
                      v-model="item[column.value]"
                    />-->
                  </template>

                  <!-- Coluna tipo Chip
                    objeto default
                    {
                      dark: false,
                      outlined: false,
                      label: false,
                      color: 'primary',
                      textColor: 'white',
                      slot: 'chip.column' -> chip pode ser sobrescrito via slot
                    }
                  -->
                  <template v-if="column.type == 'chip'">
                    <v-chip
                      dense
                      :dark="column.config.dark"
                      :outlined="column.config.outlined"
                      :label="column.config.label"
                      :color="defineColor(item, column)"
                      :text-color="
                        (column.config || { textColor: 'white' }).textColor
                      "
                    >
                      <slot
                        v-bind:item="item"
                        :name="'chip.' + column.value"
                      >{{ item[column.value] }}</slot>
                    </v-chip>
                  </template>

                  <!-- 
                    Coluna para renderizar textos com html
                  -->
                  <template v-if="column.type == 'html'">
                    <span v-html="item[column.value]"></span>
                  </template>

                  <!-- 
                    Coluna para ações default
                  -->

                  <template v-if="column.type == 'actions'">
                    <slot v-bind:item="item" name="acoes">
                      <div class="my-2">
                        <!-- AÇÕES PADRÃO -->
                        <v-tooltip bottom v-if="showStandardActions">
                          <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on" color="primary" @click="onClickChange(item)">
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>Alterar</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="showStandardActions">
                          <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on" color="error" @click="onClickRemove(item)">
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Excluir</span>
                        </v-tooltip>

                        <!-- OUTRAS AÇÕES -->
                        <slot v-bind:item="item" name="outrasAcoes"></slot>
                      </div>
                    </slot>
                  </template>
                </slot>
              </td>
            </template>
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <div class="text-right mt-5">
      <h5 class="font-italic text--secondary">Total: {{items.length}}</h5>
    </div>

    <div class="text-center mt-n5">
      <v-pagination
        v-model="view.page.actualPage"
        :length="view.page.pageCount"
        :total-visible="10"
        circle
      />
    </div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: "",

  components: {},

  props: {
    /**
     * Colunas do grid
     */
    columns: {
      default() {
        return [];
      },
      type: Array
    },

    /**
     * Array de items
     */
    items: {
      type: Array
    },

    /**
     * Loading do grid
     */
    loading: {
      default: false,
      type: Boolean
    },

    /**
     * Exibe ou não a opção de esconder os filtros do grid
     */
    expandedFilter: {
      default: true,
      type: Boolean
    },

    /**
     * Exibe ou não a opção de esconder os filtros do grid
     */
    showFieldFilter: {
      default: true,
      type: Boolean
    },

    /**
     * Exibe os botões de Editar e Excluir
     */
    showStandardActions: {
      default: true,
      type: Boolean
    },
    itemsPerPage: {
      default: 20,
      type: Number
    },
     showSearch: {
      default: false,
      type: Boolean
    }
  },

  filters: {
    /**
     * Filtro para formatar currency
     */
    currency: value => {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL"
      }).format(value);
    }
  },

  data() {
    return {
      model: {},
      view: {
        filter: {
          show: this.showFieldFilter
        },
        page: {
          actualPage: 1,
          pageCount: 1
        },
        loading: {},
        lists: {
          data: []
        },
        search:''
      }
    };
  },

  computed: {},

  watch: {
    items() {
      this.view.lists.data = this.items;
    }
  },

  mounted() {},

  methods: {
    /**
     * Emite um evento ao clicar na ação de remover
     */
    onClickRemove(item) {
      this.$emit("clickRemove", item);
    },

    /**
     * Emite um evento ao clicar na ação de alterar
     */
    onClickChange(item) {
      this.$emit("clickChange", item);
    },

    /**
     * Formata a coluna de data para ser exibida no datatable
     */
    getDate(type, value, format) {
      if (type == "date" && (format == undefined || format == null)) {
        format = "DD/MM/YYYY";
      }

      if (type == "datetime" && (format == undefined || format == null)) {
        format = "DD/MM/YYYY HH:mm:ss";
      }

      if (type == "time" && (format == undefined || format == null)) {
        format = "HH:mm:ss";
      }

      if (value == undefined || value == null) {
        return "";
      }

      return moment(value).format(format);
    },

    defineColor(item, column) {
      if (typeof column.config.color == "function") {
        return column.config.color(item);
      }

      if (typeof column.config.color == "object") {
        let key = Object.keys(column.config.color)[0];
        let value = item[key];

        if (value == undefined) {
          throw `Por favor, verifique se as props "${item[key]}" existe no objeto de item retornar pelo servidor`;
        }

        return column.config.color[key][value];
      }

      return (column.config || { color: "primary" }).color;
    }
  }
};
</script>

<style scoped>
.card-title-expanded-filter {
  padding-bottom: 0 !important;
}

.card-title-filter {
  padding-top: 0 !important;
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

thead {
  font-size: 24px !important;
}
</style>
