<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <v-card outlined>
          <v-row class="ml-1 mb-n4">
            <v-col cols="12" md="4">
              <CGIDatePicker class="mt-1" v-model="model.startDate" label="Data Inicial" loadToday />
            </v-col>
            <v-col cols="12" md="4">
              <CGIDatePicker class="mt-1" v-model="model.endDate" label="Data Final" loadToday />
            </v-col>
            <v-col cols="12" md="4">
              <v-btn class="mt-1" color="primary" @click="load()">
                Filtrar
                <v-icon right dark>mdi-filter</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card outlined>
          <div class="text-center mt-2 mb-4">
            <v-btn class="ma-2" color="blue-grey" dark @click="onClick('/ticket/resume')">
              Resumo Tickets
              <v-icon right dark>mdi-face-agent</v-icon>
            </v-btn>
            <v-btn class="ma-2" color="info" @click="onClick('/task/resume')">
              Resumo Tarefas
              <v-icon right dark>mdi-console</v-icon>
            </v-btn>
            <v-btn class="ma-2" color="warning" @click="onClick('/ticket/updates')">
              Atualizações
              <v-icon right dark>mdi-bell-alert</v-icon>
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="3">
        <ListItemCard
          type="ticket_total_pending"
          ref="DashboardTicketTotalPending"
          icon="mdi-alert-octagram"
        />
      </v-col>
      <v-col cols="12" md="3">
        <ListItemCard
          type="task_total_pending"
          ref="DashboardTaskTotalPending"
          icon="mdi-clipboard-alert"
        />
      </v-col>
      <v-col cols="12" md="3">
        <ListItemCard
          type="resume_users"
          ref="DashboardResumeUsers"
          icon="mdi-card-account-details"
        />
      </v-col>
      <v-col cols="12" md="3">
        <ListItemCard
          type="resume_clients"
          ref="DashboardResumeClients"
          :rows="10"
          icon="mdi-briefcase-account"
        />
      </v-col>
      <v-col cols="12" md="3">
        <ListItemCard
          type="resume_requesters"
          ref="DashboardResumeRequesters"
          :rows="10"
          icon="mdi-account-tie-voice"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ListItemCard from "./components/ListItemCard";
import CGIDatePicker from "@/components/CGIDatePicker";
import EventBus from "@/plugins/event-bus";
import moment from "moment";

export default {
  name: "",

  components: {
    ListItemCard,
    CGIDatePicker
  },

  props: {},

  data() {
    return {
      model: {
        startDate: "",
        endDate: ""
      }
    };
  },

  computed: {},

  watch: {},

  mounted() {
    let today = moment(new Date()).format("YYYY-MM-DD");
    this.model.startDate = today;
    this.model.endDate = today;
    this.load();

    /**
     * EventBus - CALL_LOAD
     * Usado para chamar os métodos de LOAD da página
     */
    var self = this;
    EventBus.$on("CALL_LOAD", () => {
      if (self != undefined) self.load();
    });
  },

  methods: {
    onClick(value) {
      if (value != this.$router.currentRoute.path) {
        let url = value;
        this.$router.push(url);
      }
    },
    load() {
      this.$refs.DashboardTicketTotalPending.load(
        this.model.startDate,
        this.model.endDate
      );

      this.$refs.DashboardTaskTotalPending.load(
        this.model.startDate,
        this.model.endDate
      );

      this.$refs.DashboardResumeUsers.load(
        this.model.startDate,
        this.model.endDate
      );

      this.$refs.DashboardResumeClients.load(
        this.model.startDate,
        this.model.endDate
      );

      this.$refs.DashboardResumeRequesters.load(
        this.model.startDate,
        this.model.endDate
      );
    }
  },

  /**
   * Ao destruir, desligamos o EventBus para não fazer chamadas desnecessárias
   */
  destroyed() {
    EventBus.$off("CALL_LOAD");
  }
};
</script>
<style></style>
