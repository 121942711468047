<template>
  <div>
    <CGIDataTable
      :loading="view.loading"
      :columns="view.columns"
      :items="view.lists.releases"
      @clickRemove="(value) => onClickRemove(value)"
      @clickChange="(value) => onClickChange(value)"
    >
    
      <!-- Filtro -->
      <template v-slot:filtro>
        <v-row dense>
          <v-col cols="12">
            <v-row dense>
              <v-col cols="3" md="3">
                <CGITextField label="Versão" v-model="model.versao" />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn color="info" @click="onClickAdd">Novo</v-btn>
            <v-btn :loading="view.loading" class="ma-2" color="primary" @click="filter">
              Filtrar
              <v-icon right dark>mdi-filter</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <!-- Container de Ações -> Coluna de ações dentro do GRID-->
      <template v-slot:outrasAcoes="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" color="info" @click="onClickPublish(item)">
              <v-icon>mdi-truck-delivery</v-icon>
            </v-btn>
          </template>
          <span>Publicar Release</span>
        </v-tooltip>

        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" color="info" @click="onClickDownload(item)">
              <v-icon>mdi-download-outline</v-icon>
            </v-btn>
          </template>
          <span>Download do SQL dos branches do release</span>
        </v-tooltip>-->

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" color="secondary" @click="onClickSqlEditor(item)">
              <v-icon v-if="item.dataPublicacao">mdi-database-search</v-icon>
              <v-icon v-else>mdi-database-check</v-icon>
            </v-btn>
          </template>
          <span v-if="item.dataPublicacao">Visualizar Sql</span>
          <span v-else>Editor Sql</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" color="info" @click="onClickCloud(item)">
              <v-icon>mdi-source-branch-check</v-icon>
            </v-btn>
          </template>
          <span>Branches do Release</span>
        </v-tooltip>
        <v-tooltip bottom v-if="item.dataPublicacao">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" color="orange" @click="onClickSendEmail(item)">
              <v-icon>mdi-email-send-outline</v-icon>
            </v-btn>
          </template>
          <span>Enviar e-mail para clientes</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" color="green" @click="onClickNotificationClient(item)">
              <v-icon>mdi-bell</v-icon>
            </v-btn>
          </template>
          <span>Clientes Notificados</span>
        </v-tooltip>
      </template>
    </CGIDataTable>

    <Form ref="form" />
    <Delete titulo="Atenção" ref="excluir" />
    <Publish ref="Publish" @onSuccess="load()" />
    <NotificarClientes ref="NotificarClientes" @onSuccess="load()" />
    <Branches ref="Branches" @onSuccess="load()" />
    <SqlEditor ref="SqlEditor" @onSucces="load()" />
  </div>
</template>

<script>
import { HttpRequestRelease } from "./http";
import Form from "./Form";
import Delete from "@/components/CGIDelete";
import CGIDataTable from "@/components/CGIDataTable";
import CGITextField from "@/components/CGITextField";
import Publish from "./Publish";
import Branches from "./Branches";
import NotificarClientes from "./NotificarClientes";
import EventBus from "@/plugins/event-bus";
import SqlEditor from "./SqlEditor";

export default {
  name: "ListarRelease",

  /**
   * Componentes filhos utilizados
   */
  components: {
    Form,
    Delete,
    CGIDataTable,
    CGITextField,
    Publish,
    Branches,
    NotificarClientes,
    SqlEditor,
    
  },

  /**
   * Variáveis do componente
   */
  data() {
    return {
      model: {
        nome: "",
        identificacao: ""
      },
      view: {
        columns: [
          {
            text: "Id",
            value: "id"
          },
          {
            text: "Dt. Cad.",
            value: "dataCadastro",
            type: "datetime"
          },
          {
            text: "Versão",
            value: "versao"
          },
          {
            text: "Dt. Início",
            value: "dataInicio",
            type: "date"
          },
          {
            text: "Dt. Publicação.",
            value: "dataPublicacao",
            type: "date"
          },

          {
            text: "",
            type: "actions"
          }
        ],
        loading: false,
        lists: {
          releases: []
        }
      }
    };
  },

  /**
   * Método chamado conforme ciclo de vida do vue
   */
  mounted() {
    this.load();

    /**
     * EventBus - CALL_LOAD
     * Usado para chamar os métodos de LOAD da página
     */
    var self = this;
    EventBus.$on("CALL_LOAD", () => {
      self.load();
    });
  },

  /**
   * Objeto onde declaramos métodos do componente
   */
  methods: {
    /**
     * Carregando dados iniciais
     */
    load() {
      this.view.loading = true;
      HttpRequestRelease.get(this.model).then(response => {
        this.view.lists.releases = response.data.data;
        this.view.loading = false;
      });
    },

    /**
     * Ao clicar ação de filtrar
     */
    filter() {
      this.load();
    },

    /**
     * Ao clicar ação de remover um usuário
     */
    onClickRemove(usuario) {
      this.$refs.excluir.abrirModal(usuario, "/release/excluir");
    },

    /**
     * Ao clicar ação de Incluir
     */
    onClickAdd() {
      this.$refs.form.add();
    },

    onClickPublish(item) {
      this.$refs.Publish.openModal(item);
    },

    onClickCloud(item) {
      this.$refs.Branches.openModal(item);
    },

    onClickDownload(item) {
      HttpRequestRelease.getSQL(item.id);
    },

    /**
     * Ao clicar ação de alterar
     */
    onClickChange(release) {
      this.$refs.form.change(release);
    },

    /**
     * Envia email para os clientes com tarefas publicadas no release
     */
    onClickSendEmail(item) {
      HttpRequestRelease.sendPublicationEmail(item.id)
        .then(() => {
          this.notify("E-mails enviados com sucesso", "success");
        })
        .catch(error => {
          let message = error.response.data.message;
          this.notify(message, "error");
        });
    },

    onClickSqlEditor(item) {
      console.log(item)
      this.$refs.SqlEditor.openModal(item);
    },
    onClickNotificationClient(item) {
      this.$refs.NotificarClientes.openModal(item);
    }
  },

  /**
   * Ao destruir, desligamos o EventBus para não fazer chamadas desnecessárias
   */
  destroyed() {
    EventBus.$off("CALL_LOAD");
  }
};
</script>
<style></style>
