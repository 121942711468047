<template>
  <div>
    <CGIDataTable
      :loading="view.loading"
      :columns="view.columns"
      :items="view.lists.users"
      @clickRemove="(value) => onClickRemove(value)"
      @clickChange="(value) => onClickChange(value)"
    >
      <!-- Filtro -->
      <template v-slot:filtro>
        <v-row dense>
          <v-col cols="12">
            <v-row dense>
              <v-col cols="12" md="3">
                <CGITextField label="Nome" v-model="model.nome" />
              </v-col>

             
              <v-col cols="12" md="3">
                <CGIComboBox
                  v-model="model.clienteId"
                  route="/combo/cliente"
                  label="Cliente"
                  hint="Selecione o Cliente"
                  @input="(value) => changeCombo(value)"
                  :itemsAsync="true"
                />
              </v-col>
              <v-col cols="12" md="3">
                <CGIComboBox
                  v-model="model.usuarioId"
                  route="/combo/usuario"
                  label="Usuário"
                  hint="Selecione o usuário"
                  @input="(value) => changeCombo(value)"
                  :itemsAsync="true"
                />
              </v-col>
               <v-col cols="12" md="1">
                <CGIComboBox
                  v-model="model.ativo"
                  route="/combo/simnaoambos"
                  label="Ativo "
                  hint="Selecione se o usuário esta ativo"
                  :itemsAsync="true"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn color="info" @click="onClickAdd">Novo</v-btn>
            <v-btn
              :loading="view.loading"
              class="ma-2"
              color="primary"
              @click="filter"
            >
              Filtrar
              <v-icon right dark>mdi-filter</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </CGIDataTable>

    <Form ref="form" @onSuccess="load()" />
    <Delete titulo="Atenção" ref="excluir" />
  </div>
</template>
<script>
import { HttpRequestUsuario } from "./http";
import Form from "./Form";
import Delete from "@/components/CGIDelete";
import CGIDataTable from "@/components/CGIDataTable";
import CGITextField from "@/components/CGITextField";
import EventBus from "@/plugins/event-bus";
import CGIComboBox from "@/components/CGICombobox";

export default {
  name: "ListarTicket",

  /**
   * Componentes filhos utilizados
   */
  components: {
    Form,
    Delete,
    CGIDataTable,
    CGITextField,
    CGIComboBox,
  },

  /**
   * Variáveis do componente
   */
  data() {
    return {
      model: {
        nome: "",
        clienteId:0,
        usuarioId:0,
        ativo:1
      },
      view: {
        columns: [
          {
            text: "Id",
            value: "id",
          },
          {
            text: "Login",
            value: "login",
          },
          {
            text: "Nome",
            value: "nome",
          },
          {
            text: "E-Mail",
            value: "eMail",
          },
          {
            text: "Ativo",
            value: "ativo",
            type: "checkbox",
          },
          {
            text: "",
            type: "actions",
          },
        ],
        loading: false,
        lists: {
          users: [],
        },
      },
    };
  },

  /**
   * Método chamado conforme ciclo de vida do vue
   */
  mounted() {
    this.load();

    /**
     * EventBus - CALL_LOAD
     * Usado para chamar os métodos de LOAD da página
     */
    var self = this;
    EventBus.$on("CALL_LOAD", () => {
      self.load();
    });
  },

  /**
   * Objeto onde declaramos métodos do componente
   */
  methods: {
    /**
     * Carregando dados iniciais
     */
    load() {
      this.view.loading = true;
      HttpRequestUsuario.get(this.model).then((response) => {
        this.view.lists.users = response.data.data;
        this.view.loading = false;
      });
    },

    /**
     * Ao clicar ação de filtrar
     */
    filter() {
      this.load();
    },

    /**
     * Ao clicar ação de remover um usuário
     */
    onClickRemove(usuario) {
      this.$refs.excluir.abrirModal(usuario, "/usuario/excluir");
    },

    /**
     * Ao clicar ação de Incluir
     */
    onClickAdd() {
      this.$refs.form.add();
    },

    /**
     * Ao clicar ação de alterar
     */
    onClickChange(usuario) {
      this.$refs.form.change(usuario);
    },
  },

  /**
   * Ao destruir, desligamos o EventBus para não fazer chamadas desnecessárias
   */
  destroyed() {
    EventBus.$off("CALL_LOAD");
  },
};
</script>
<style></style>
