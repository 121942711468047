<template>
  <div>
    <CGIModal
      :loading="view.loading"
      :exibir="view.modal.show"
      :titulo="view.action"
      :fullScreen="true"
      :scrollable="true"
      @clickCancelar="close"
      @clickConfirmar="submit"
    >
      <template v-slot:body>
        <v-form ref="form" v-model="view.valid" lazy-validation>
          <v-row dense>
            <v-col cols="12" md="2">
              <CGITextField
                label="Id"
                hint="Id da Reunião"
                v-model="model.id"
                disabled
              />
            </v-col>

            <v-col cols="12" md="7">
              <CGITextField
                label="Título"
                type="text"
                hint="Entre com o título"
                :rules="[view.rules.required]"
                required
                v-model="model.titulo"
              />
            </v-col>
            <v-col cols="12" md="3">
              <CGIComboBox
                v-model="model.tipoReuniaoId"
                route="/combo/tiporeuniao"
                label="Tipo de Reunião"
                hint="Selecione o tipo"
                :itemsAsync="true"
                required
                :rules="[view.rules.required]"
              />
            </v-col>

            <v-col cols="12" md="3">
              <CGIComboBox
                v-model="model.solicitanteId"
                route="/combo/clienteusuario"
                label="Solicitante"
                hint="Selecione o solicitante"
                :itemsAsync="true"
                required
                :rules="[view.rules.required]"
              />
            </v-col>
            <v-col cols="12" md="3">
              <CGIComboBox
                v-model="model.clienteUsuarioId"
                route="/combo/clienteusuario"
                label="Participante"
                hint="Participante"
                :itemsAsync="true"
                required
                :rules="[view.rules.required]"
              />
            </v-col>

            <v-col cols="12" md="3">
              <CGIComboBox
                v-model="model.usuarioPrincipalId"
                route="/combo/usuario"
                label="Técnico Principal"
                hint="Selecione o usuário principal"
                :itemsAsync="true"
                required
                :rules="[view.rules.required]"
              />
            </v-col>

            <v-col cols="12" md="3">
              <CGIComboBox
                v-model="model.usuarioAuxiliarId"
                route="/combo/usuario"
                label="Técnico Auxiliar"
                hint="Selecione o usuário auxiliar"
                :itemsAsync="true"
              />
            </v-col>

            <v-row>
              <v-col cols="12" md="3">
                <CGIDatePicker
                  v-model="model.dataAgenda"
                  label="Data Agenda"
                  :required="true"
                />
              </v-col>
              <v-col cols="12" md="1">
                <CGITextField
                  label="Hora"
                  hint="Hora Agenda"
                  v-model="model.horaAgenda"
                />
              </v-col>
              <v-col cols="12" md="3">
                <CGIDatePicker
                  v-model="model.dataReuniao"
                  label="Data Reunião"
                  :required="true"
                />
              </v-col>
              <v-col cols="12" md="1">
                <CGITextField
                  label="Hora"
                  hint="Hora Reunião"
                  v-model="model.horaReuniao"
                />
              </v-col>
              <v-col cols="12" md="2">
                <CGITextField
                  label="Duração"
                  hint="Duração"
                  v-model="model.duracao"
                />
              </v-col>

              <v-col cols="12" md="2">
                <v-radio-group v-model="model.presencialRemoto">
                  <v-radio label="Presencial" value="P"></v-radio>
                  <v-radio label="Remoto" value="R"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-row dense v-if="model.tipoReuniaoId==3">
              <v-col cols="12" md="4">
                <v-checkbox
                  class="mt-1 ml-1"
                  v-model="model.moduloAlmoxarifado"
                  label="Almoxarifado"
                />
                <v-textarea
                  auto-grow
                  outlined
                  label="Tópicos de Almoxarifado"
                  type="text"
                  hint="Tópicos de Almoxarifado"
                  v-model="model.moduloTopicoAlmoxarifado"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-checkbox
                  class="mt-1 ml-1"
                  v-model="model.moduloCadastros"
                  label="Cadastros"
                />
                <v-textarea
                  auto-grow
                  outlined
                  label="Tópicos de Cadastros"
                  type="text"
                  hint="Tópicos de Cadastros"
                  v-model="model.moduloTopicoCadastros"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-checkbox
                  class="mt-1 ml-1"
                  v-model="model.moduloFinanceiro"
                  label="Financeiro"
                />
                <v-textarea
                  auto-grow
                  outlined
                  label="Tópicos de Financeiro"
                  type="text"
                  hint="Tópicos de Financeiro"
                  v-model="model.moduloTopicoFinanceiro"
                />
              </v-col>

              <v-col cols="12" md="4">
                <v-checkbox
                  class="mt-1 ml-1"
                  v-model="model.moduloManutencao"
                  label="Manutenção"
                />
                <v-textarea
                  auto-grow
                  outlined
                  label="Tópicos de Manutenção"
                  type="text"
                  hint="Tópicos de Manutenção"
                  v-model="model.moduloTopicoManutencao"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-checkbox
                  class="mt-1 ml-1"
                  v-model="model.moduloServico"
                  label="Serviços"
                />
                <v-textarea
                  auto-grow
                  outlined
                  label="Tópicos de Serviços"
                  type="text"
                  hint="Tópicos de Serviços"
                  v-model="model.moduloTopicoServico"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-checkbox
                  class="mt-1 ml-1"
                  v-model="model.moduloVendas"
                  label="Vendas"
                />
                <v-textarea
                  auto-grow
                  outlined
                  label="Tópicos de Vendas"
                  type="text"
                  hint="Tópicos de Vendas"
                  v-model="model.moduloTopicoVendas"
                />
              </v-col>
            </v-row>
          </v-row>

          <v-row dense>
            <v-col cols="9" md="12">
              <CGITextEditor
                class="mb-4"
                v-model="model.resumo"
                :rules="[view.rules.required]"
                label="Resumo"
                placeholder="Entre com o resumo"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="9" md="12">
              <CGITextEditor
                class="mb-4"
                v-model="model.resolucoesImediatas"
                :rules="[view.rules.required]"
                label="Resoluções Imediatas"
                placeholder="Entre com as resoluções imediatas"
              />
            </v-col>
          </v-row>
          <v-col cols="4" md="3">
              <CGIComboBox
                v-model="model.statusId"
                route="/combo/statusreuniao"
                label="Status"
                hint="Selecione o status da reunião"
                :itemsAsync="true"
                required
                :rules="[view.rules.required]"
              />
            </v-col>
        </v-form>
      </template>
    </CGIModal>
  </div>
</template>
<script>
import { HttpRequestReuniao } from "./http";
import CGIModal from "@/components/CGIModal";
import CGITextField from "@/components/CGITextField";
import CGITextEditor from "@/components/CGITextEditor";
import CGIComboBox from "@/components/CGICombobox";
import CGIDatePicker from "@/components/CGIDatePicker";
//import CGIDataTable from "@/components/CGIDataTable";

const objectModel = {
  id: 0,
  titulo: "",
  lists: {
    tickets: [],
  },
  colunas: {
    tickets: [
      {
        text: "Id",
        value: "id",
      },
      {
        text: "Cliente",
        value: "identificacao",
      },
    ],
  },
};

export default {
  name: "",

  components: {
    CGIModal,
    CGITextField,
    CGITextEditor,
    CGIComboBox,
    CGIDatePicker,
    //CGIDataTable,
  },

  props: {},

  data() {
    return {
      model: objectModel,
      view: {
        action: "Incluindo nova reunião",
        conteudoModel: "",
        loading: false,
        modal: {
          show: false,
        },
        rules: {
          required: (value) => !!value || "Campo Obrigatório.",
        },
      },
    };
  },

  computed: {},

  watch: {},

  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (
        this.model.id == undefined ||
        this.model.id == null ||
        this.model.id == 0
      ) {
        this.save();
      } else {
        this.update();
      }
    },

    save() {
      this.view.loading = true;

      HttpRequestReuniao.postReuniao(this.model)
        .then(() => {
          this.view.modal.show = false;
          this.notify("Reunião incluída com sucesso", "success");
          close();
        })
        .catch((error) => {
          let message = error.response.data.message;
          this.notify(message, "error");
        })
        .then(() => {
          this.view.loading = false;
        });
    },
    update() {
      HttpRequestReuniao.putReuniao(this.model)
        .then(() => {
          this.notify("Reunião alterada com sucesso", "success");
          this.view.modal.show = false;
          close();
        })
        .catch((error) => {
          let message = error.response.data.message;
          this.notify(message, "error");
        })
        .then(() => {
          this.view.loading = false;
          this.close();
        });
    },

    close() {
      this.model = {};
      this.view.modal.show = false;
    },

    add() {
      this.view.modal.show = true;
      this.view.action = "Incluindo nova reunião";
    },

    change(reuniao) {
      this.view.modal.show = true;
      this.view.action = "Alterando a reunião [" + reuniao.titulo + "]";

      this.view.loading = true;

      let proCliente = HttpRequestReuniao.getById(reuniao.id);

      Promise.all([proCliente]).then((response) => {
        this.model = response[0].data.data;

        this.view.loading = false;
      });
    },
  },
};
</script>
<style></style>
