import httpApi from "@/plugins/http";

const router = "ticket";

export const HttpRequestTicket = {
  /**
   * Lista todos os Ticket com ou sem filtro
   */
  get: filter => {
    return httpApi.get(`${router}/listar`, {
      params: filter
    });
  },

  getHistorico(id) {
    return httpApi.get(`${router}/historicos/${id}`);
  },

  getTicketNews() {
    return httpApi.get(`${router}/updates/tickets`);
  },

  getTicketUpdates() {
    return httpApi.get(`${router}/updates/updates`);
  },

  /**
   * Busca um Ticket por Id
   * @param {int} id
   */
  getById(id) {
    return httpApi.get(`${router}/${id}`);
  },

  /**
   * Serviço para criar um Ticket
   */
  postTicket: params => {
    return httpApi.post(`${router}`, params);
  },

  /**
   * Serviço para alterar um Ticket
   */
  putTicket: params => {
    return httpApi.put(`${router}/alterar/${params.id}`, params);
  },

  postInformService: params => {
    return httpApi.post(`${router}/incluir-atendimento`, params);
  },

  postFastTicket: params => {
    return httpApi.post(`${router}/incluir-ticket-rapido`, params);
  },

  /**
  * Busca os dados do ticket para uma visualização rápida
  * @param {int} id
  */
  getQuickViewById(id) {
    return httpApi.get(`${router}/visualizacao-rapida/${id}`);
  },

  /**
   * @param {Array} params 
   * @param {int} id 
   */
  postUpload(params, id) {
    return httpApi.post(`${router}/upload/${id}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  /**
   * @param {Array} params 
   * @param {int} id 
   */
  putUpload(params, id) {
    return httpApi.put(`${router}/upload/${id}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
};
