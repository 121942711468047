import Vue from "vue";
import Router from "vue-router";
import moment from "moment";

import routeAuth from "@/app/auth/routes.js";
import routeDashboard from "@/app/dashboard/routes.js";
import routeTicket from "@/app/ticket/routes.js";
import routeTask from "@/app/task/routes.js";
import routeClient from "@/app/client/routes.js";
import routeClientRequester from "@/app/client-requester/routes.js";
import routeRelease from "@/app/release/routes.js";
import routeKnowledgebase from "@/app/knowledgebase/routes.js";
import routeUser from "@/app/user/routes.js";
import routeClientDashboard from "@/app/client-dashboard/routes.js";
import routeModule from "@/app/module/routes.js";
import routeReuniao from "@/app/reuniao/routes.js";

// Component
// import Dashboard from "../app/Dashboard/Dashboard.vue";

Vue.use(Router);

var router = new Router({
  mode: "history",
  // Rotas dos modulos
  routes: [
    // { path: "*", component: Dashboard, meta: { public: false } },
    ...routeAuth,
    ...routeTicket,
    ...routeClient,
    ...routeClientRequester,
    ...routeTask,
    ...routeRelease,
    ...routeKnowledgebase,
    ...routeDashboard,
    ...routeUser,
    ...routeClientDashboard,
    ...routeModule,
    ...routeReuniao
  ],
});

router.beforeEach((to, from, next) => {
  // TODO ALAN -> Tratar melhor essa verificação
  if (!to.meta.public) {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user == null || user == undefined) {
      next("/auth");
    } else {
      let expirationDateTime = moment(user.expiraEm);
      let now = moment();

      if (now > expirationDateTime) {
        next("/auth");
      }
    }
  }

  next();
});

export default router;
