import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

import pt from "vuetify/es5/locale/pt";

export default new Vuetify({
  lang: {
    locales: { pt },
    current: "pt"
  },
  theme: {
    themes: {
      options: {
        themeVariations: ["primary", "secondary", "accent"],
        extra: {
          mainToolbar: {
            color: "primary"
          },
          sideToolbar: {},
          sideNav: "primary",
          mainNav: "primary lighten-1",
          bodyBg: ""
        }
      }
    }
  }
});
