<template>
  <div>
    <CGIDataTable
      :loading="view.loading"
      :columns="view.columns"
      :items="view.lists.tickets"
      :itemsPerPage="50"
      @clickRemove="(value) => onClickRemove(value)"
      @clickChange="(value) => onClickChange(value)"
    >
      <!-- Filtro -->
      <template v-slot:filtro>
        <v-row dense class="mb-n5">
          <v-col cols="12" md="2">
            <CGITextField
              label="Número do ticket"
              v-model="model.ticketId"
              type="int"
            />
          </v-col>
          <v-col cols="12" md="2">
            <CGIDatePicker v-model="model.dataInicial" label="Data Inicial" />
          </v-col>

          <v-col cols="12" md="2">
            <CGIDatePicker v-model="model.dataFinal" label="Data Final" />
          </v-col>

          <v-col cols="12" md="3">
            <CGIComboBox
              v-model="model.clienteId"
              route="/combo/cliente"
              label="Cliente"
              hint="Selecione o cliente"
              :itemsAsync="true"
            />
          </v-col>
          <v-col cols="12" md="3">
            <CGIComboBox
              v-model="model.clienteUsuarioId"
              route="/combo/clienteusuario"
              label="Solicitante"
              hint="Selecione o solicitante"
              :itemsAsync="true"
            />
          </v-col>
        </v-row>

        <v-row dense class="mb-n5">
          <v-col cols="12" md="2">
            <CGIComboBox
              v-model="model.usuarioId"
              route="/combo/usuario"
              label="Usuário"
              hint="Selecione o usuário"
              :itemsAsync="true"
            />
          </v-col>
          <v-col cols="12" md="2">
            <CGIComboBox
              v-model="model.tipoTicketId"
              route="/combo/tipoticket"
              label="Tipo de Ticket"
              hint="Selecione o tipo de ticket"
              :itemsAsync="true"
            />
          </v-col>
          <v-col cols="12" md="2">
            <CGITextField label="Contém o Texto" v-model="model.textoLivre" />
          </v-col>
          <v-col cols="12" md="3">
            <CGIComboBox
              v-model="model.prioridadeId"
              route="/combo/prioridade"
              label="Prioridade"
              hint="Selecione a prioridade"
              :setTodos="true"
              :itemsAsync="true"
            />
          </v-col>
          <v-col cols="12" md="3">
            <CGIComboBox
              v-model="model.excluidosId"
              route="/combo/simnaoambos"
              label="Excluídos"
              hint="Selecione se deseja listar excluídos"
              :itemsAsync="true"
            />
          </v-col>
        </v-row>

        <v-row dense class="mb-n7">
          <v-col cols="12" md="3">
            <CGIComboBox
              v-model="model.internoExternoId"
              route="/combo/internoexterno"
              label="Interno ou Externo"
              hint="Selecione tickets internos ou externos"
              :setTodos="true"
              :itemsAsync="true"
            />
          </v-col>
          <v-col cols="12" md="4">
            <CGIComboBox
              v-model="model.moduloPrincipalId"
              route="/combo/modulo"
              label="Módulo"
              :setTodos="true"
              :itemsAsync="true"
            />
          </v-col>

          <v-col cols="12" md="4">
            <CGIComboBox
              v-model="model.releaseId"
              route="/combo/release"
              label="Release de lançamento"
              :setTodos="true"
              :itemsAsync="true"
            />
          </v-col>
        </v-row>

        <v-row dense class="mb-n7">
          <v-col cols="12" md="4">
            <v-checkbox
              class="mt-1 ml-1"
              v-model="model.semTarefaDefinida"
              label="Sem Tarefa Definida"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-checkbox
              class="mt-1 ml-1"
              v-model="model.semCobrancaDefinida"
              label="Sem Cobrança Definida"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-checkbox
              class="mt-1 ml-1"
              v-model="model.semCobrancaGerada"
              label="Sem Cobrança Gerada"
            />
          </v-col>
        </v-row>

        <v-row dense class="mb-n7 mt-2">
          <v-col cols="12" md="8">
            <CGIComboBox
              v-model="model.listaStatusId"
              route="/combo/statusticket"
              label="Status"
              hint="Selecione o status"
              multiple
              small_chips
              :itemsAsync="true"
            />
          </v-col>
       
          <v-col cols="12" md="8">
            <CGIComboBox
              v-model="model.reuniaoId"
              route="/combo/reuniao"
              label="Reunião"
              hint="Selecione a Reunião"
              :itemsAsync="true"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-checkbox
              class="mt-1 ml-1"
              v-model="model.ticketsDeReunioes"
              label="Tickets de Reuniões"
            />
          </v-col>
        </v-row>

        <!-- BOTÕES DE AÇÃO NO BLOCO DE FILTRO-->
        <v-col cols="12" class="text-right">
          <v-btn color="info" @click="onClickNewFastTicket"
            >Novo Ticket - Rápido</v-btn
          >
          <v-btn class="ma-2" color="info" @click="onClickNewHistoric"
            >Lançar Histórico Cliente</v-btn
          >
          <v-btn color="info" @click="onClickAdd">Novo</v-btn>
          <v-btn
            :loading="view.loading"
            class="ma-2"
            color="primary"
            @click="filter"
          >
            Filtrar
            <v-icon right dark>mdi-filter</v-icon>
          </v-btn>
        </v-col>
      </template>
      <!-- Fim do template de FILTRO -->

      <!-- Container de Ações -> Coluna de ações dentro do GRID-->
      <template v-slot:outrasAcoes="{ item }">
        <v-menu bottom :offset-y="true">
          <template v-slot:activator="{ on }">
            <v-btn fab dark small color="secondary" icon v-on="on">
              <v-icon>mdi-expand-all</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item-group color="primary">
              <v-list-item
                v-for="(itemM, i) in view.lists.actionMenus"
                :key="i"
              >
                <v-list-item-content>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        :color="itemM.color"
                        @click="handle_function_call(itemM.action, item)"
                      >
                        <v-icon>{{ itemM.icon }}</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ itemM.tooltip }}</span>
                  </v-tooltip>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </template>
    </CGIDataTable>

    <NewHistoric ref="newHistoric" />

    <Form ref="form" @onSuccess="load()" />
    <Delete titulo="Atenção" ref="excluir" @onSuccess="load()" />

    <InformService ref="InformService" @onSuccess="load()" />
    <NewFastTicket ref="NewFastTicket" @onSuccess="load()" />
    <SetTask ref="SetTask" @onSuccess="load()" />
    <ViewTaskTicket ref="ViewTaskTicket" />
  </div>
</template>
<script>
import { HttpRequestTicket } from "./http";
import NewHistoric from "../client/NewHistoric";
import Form from "./Form";
import Delete from "@/components/CGIDelete";
import CGIDataTable from "@/components/CGIDataTable";
import CGITextField from "@/components/CGITextField";
import CGIComboBox from "@/components/CGICombobox";
// import CGICurrency from "@/components/CGICurrency";
import { mask } from "vue-the-mask";
import InformService from "./InformService";
import NewFastTicket from "./NewFastTicket";
import SetTask from "../task/SetTask";

import ViewTaskTicket from "../view-task-ticket/ViewTaskTicket";

import CGIDatePicker from "@/components/CGIDatePicker";

import EventBus from "@/plugins/event-bus";

export default {
  name: "ListarTicket",

  /**
   * Componentes filhos utilizados
   */
  components: {
    NewHistoric,
    Form,
    Delete,
    CGIDataTable,
    CGITextField,
    CGIComboBox,
    // CGICurrency,
    InformService,
    NewFastTicket,
    CGIDatePicker,
    SetTask,
    ViewTaskTicket,
  },

  directives: {
    mask,
  },

  /**
   * Variáveis do componente
   */
  data() {
    return {
      model: {
        clienteId: 0,
        clienteUsuarioId: 0,
        usuarioId: 0,
        dataInicial: "",
        dataFinal: "",
        prioridadeId: 0,
        excluidosId: 2,
        internoExternoId: 0,
        tipoTicketId: 0,
        moduloPrincipalId: 0,
        listaStatusId: "1,4,2,17,3,19,20",
        textoLivre: "",
        semTarefaDefinida: false,
      },
      view: {
        columns: [
          {
            text: "Id",
            value: "id",
          },
          {
            text: "Dt. Cad.",
            value: "dataCadastro",
            type: "datetime",
          },
          {
            text: "Prioridade",
            value: "nomePrioridade",
            type: "chip",
            config: {
              dark: true,
              color: (item) => {
                if (item.prioridade == "U") return "red";
                if (item.prioridade == "A") return "orange";
                if (item.prioridade == "M") return "blue";
                if (item.prioridade == "B") return "green";
              },
            },
          },
          {
            text: "Cliente",
            value: "clienteESolicitante",
            type: "html",
          },
          /*{
            text: "Solicitante",
            value: "nomeClienteUsuario"
          },*/
          {
            text: "Usuários",
            value: "usuarioEUsuarioTarefa",
            type: "html",
          },
          {
            text: "Tipo de Ticket",
            value: "internoExternoETipo",
            type: "html",
          },
          {
            text: "Status",
            value: "nomeStatus",
            type: "chip",
            config: {
              dark: true,
              label: true,
              color: (item) => {
                if (item.nomeStatus == "Incluído") return "green";
                if (item.nomeStatus == "Em andamento") return "yellow darken-3";
                if (item.nomeStatus == "Aguardando cliente")
                  return "orange darken-3";
                if (item.nomeStatus == "Enviado para Dev") return "cyan";
                if (item.nomeStatus == "Devolvido para Suporte") return "pink";
                if (item.nomeStatus == "Concluído") return "lightblue";
                if (item.nomeStatus == "Em Release") return "darkblue";
                if (item.nomeStatus == "Entregue") return "gray";
                if (item.nomeStatus == "Pré-Ticket") return "lime accent-4";
                if (item.nomeStatus == "Pré-Ticket Devolvido")
                  return "deep-orange accent-2";
              },
            },
          },
          {
            text: "Título",
            value: "tituloETarefaCriada",
            type: "html",
          },
          {
            text: "Dt. Prev.",
            value: "dataPrevisao",
            type: "date",
          },
          {
            text: "Dt. Conclusão",
            value: "dataConclusao",
            type: "date",
          },
          {
            text: "",
            type: "actions",
          },
        ],
        loading: false,
        lists: {
          tickets: [],
          actionMenus: [
            {
              action: "onClickOpenTicketTask",
              tooltip: "Visualiar Ticket/Tarefa",
              icon: "mdi-file-eye",
              color: "success",
            },
            {
              action: "onClickNewService",
              tooltip: "Informar Atendimento",
              icon: "mdi-account-voice",
              color: "info",
            },
            {
              action: "onClickSetTask",
              tooltip: "Tarefa",
              icon: "mdi-dev-to",
              color: "orange",
            },
          ],
        },
      },
    };
  },

  /**
   * Método chamado conforme ciclo de vida do vue
   */
  mounted() {
    this.load();

    /**
     * EventBus - CALL_LOAD
     * Usado para chamar os métodos de LOAD da página
     */
    var self = this;
    EventBus.$on("CALL_LOAD", () => {
      self.load();
    });
  },

  /**
   * Objeto onde declaramos métodos do componente
   */
  methods: {
    /**
     * Carregando dados iniciais
     */
    load() {
      this.view.loading = true;

      HttpRequestTicket.get(this.model)
        .then((response) => {
          this.view.lists.tickets = response.data.data;
          this.dataProcessing();
          this.view.loading = false;
        })
        .catch((error) => {
          if (error.response.status != 500) {
            this.view.loading = false;
            let message = error.response.data.message;
            this.notify(message, "error");
          }
        });
    },

    /**
     * Ao clicar ação de filtrar
     */
    filter() {
      this.load();
    },

    /**
     * Ao clicar ação de remover um usuário
     */
    onClickRemove(usuario) {
      this.$refs.excluir.abrirModal(usuario, "/ticket/excluir");
    },

    /**
     * Ao clicar ação de resetar permissão de usuário
     */
    onClickResetPass(usuario) {
      this.$refs.resetPassword.abrirModal(usuario);
    },

    /** 
     * Ao clicar ação de Copiar permissão de outro usuário
     */
    onClickNewHistoric() {
      this.$refs.newHistoric.abrirModal();
    },

    /**
     * Ao clicar ação de Incluir
     */
    onClickAdd() {
      this.$refs.form.add();
    },

    /**
     * Ao clicar ação de alterar
     */
    onClickChange(user) {
      this.$refs.form.change(user);
    },

    onClickNewService(item) {
      this.$refs.InformService.openModal(
        item.id,
        item.titulo,
        item.internoExterno
      );
    },

    onClickSetTask(item) {
      this.$refs.SetTask.openModal(item.id);
    },

    onClickNewFastTicket() {
      this.$refs.NewFastTicket.add();
    },

    onClickOpenTicketTask(item) {
      this.$refs.ViewTaskTicket.openModal(item.id);
    },

    dataProcessing() {
      this.view.lists.tickets.map((item) => {
        if (item.ehTarefa && item.nomeStatusTarefa) {
          item.tituloETarefaCriada =
            item.titulo +
            `<br /> <span title="Tarefa criada." style="color: blue; font-weight: bold;"> <i class="mdi mdi-thumb-up-outline"></i> - ${item.nomeStatusTarefa}</span>`;

          if (item.versaoRelease) {
            item.tituloETarefaCriada += `<span style="color: grey;"><i> - <i class="mdi mdi-send-check"></i> ${item.versaoRelease}</i></span>`;
          }

          if (item.reuniaoId) {
            item.tituloETarefaCriada += `<br /><span style="color: darkgreen;font-weight: bold;"><i> - <i class="mdi mdi-send-check">${item.resumoReuniao}</span>`;
          }
        }

        if (item.ehTarefa && item.nomeStatusTarefa == undefined) {
          item.tituloETarefaCriada =
            item.titulo +
            `<br /> <span title="Marcado, mas ainda sem tarefa." style="color: red; font-weight: bold;"> <i class="mdi mdi-information-outline"></i></span>`;
        }
      });
    },

    handle_function_call(function_name, param) {
      this[function_name](param);
    },
  },

  /**
   * Ao destruir, desligamos o EventBus para não fazer chamadas desnecessárias
   */
  destroyed() {
    EventBus.$off("CALL_LOAD");
  },
};
</script>
<style></style>
