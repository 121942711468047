<template>
  <div>
    <CGIModal
      :loading="view.loading"
      :exibir="view.modal.show"
      :titulo="view.action"
      :fullScreen="false"
      :maxWidth="'1200'"
      :scrollable="true"
      @clickCancelar="close"
      @clickConfirmar="submit"
    >
      <template v-slot:body>
        <v-form ref="form" v-model="view.valid" lazy-validation>
          <v-row dense>
            <v-col cols="12" md="4">
              <CGITextField label="Id" hint="Id do Release" v-model="model.id" disabled />
            </v-col>
            <v-col cols="12" md="4">
              <CGITextField
                label="Versão"
                type="text"
                hint="Entre com a versão"
                disabled
                v-model="model.versao"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <CGIDataTable :columns="view.columns.tasks" :items="view.lists.tasks" />
            </v-col>
          </v-row>
        </v-form>
      </template>
    </CGIModal>
  </div>
</template>
<script>
import { HttpRequestRelease } from "./http";
import CGIModal from "@/components/CGIModal";
import CGITextField from "@/components/CGITextField";
import CGIDataTable from "@/components/CGIDataTable";
import { mask } from "vue-the-mask";

const objectModel = {
  id: 0,
  versao: "",
  dataInicio: ""
};

export default {
  name: "",

  directives: {
    mask
  },

  components: {
    CGIModal,
    CGITextField,
    CGIDataTable
  },

  props: {},

  data() {
    return {
      model: objectModel,
      view: {
        action: "Incluindo novo Release",
        loading: false,
        lists: {
          tasks: []
        },
        columns: {
          tasks: [
            {
              text: "Tarefa Id",
              value: "id"
            },
            {
              text: "Dt. Cadastro",
              value: "dataCadastro",
              type: "date"
            },
            {
              text: "Tarefa",
              value: "tituloTarefa"
            },
            {
              text: "Branch",
              value: "branch"
            }
          ]
        },
        modal: {
          show: false
        }
      }
    };
  },

  computed: {},

  watch: {},

  methods: {
    submit() {
      this.close();
    },
    close() {
      this.model = {};
      this.view.modal.show = false;
    },

    openModal(release) {
      this.view.modal.show = true;
      this.view.action = "Branches do Release [" + release.id + "]";

      this.view.loading = true;

      let proRelease = HttpRequestRelease.getById(release.id);
      let proBranches = HttpRequestRelease.getBranches(release.id);

      Promise.all([proRelease, proBranches]).then(response => {
        this.model = response[0].data.data;
        this.view.lists.tasks = response[1].data.data;

        this.view.loading = false;
      });
    }
  }
};
</script>
<style></style>
