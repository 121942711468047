<template>
  <div>
    <v-app-bar color="primary" dense dark>
      <v-icon large left>{{ icon }}</v-icon>
      <v-toolbar-title>{{ view.title }}</v-toolbar-title>

      <v-spacer></v-spacer>
    </v-app-bar>
    <v-simple-table dense height="auto" fixed-header class="elevation-1">
      <template v-slot:default>
        <v-data-table
          class="list-item-card"
          dense
          hide-default-footer
          disable-sort
          :headers="view.lists.headers"
          :items="view.lists.items"
        >
          <template v-slot:item.action="{ item }">
            <v-icon small class="mr-2" @click="onClickRow(item)">mdi-magnify</v-icon>
          </template>
        </v-data-table>
      </template>
    </v-simple-table>
    <v-divider></v-divider>
    <v-spacer />
    <ItemDetail ref="ItemDetail" />
  </div>
</template>

<script>
import http from "@/plugins/http";
import ItemDetail from "./ItemDetail";

export default {
  name: "ListItemCard",

  components: {
    ItemDetail
  },

  data() {
    return {
      service: "",
      module: "",
      item: 0,
      startDate: "",
      endDate: "",
      view: {
        title: "",
        loading: false,
        lists: {
          items: [],
          headers: []
        }
      }
    };
  },

  props: {
    type: {
      default: "",
      type: String
    },
    rows: {
      default: 0,
      type: Number
    },
    icon: {
      default: "",
      type: String
    }
  },

  mounted() {
    switch (this.type) {
      case "ticket_total_pending":
        this.view.title = "Tickets Pendentes";
        this.view.keyProp = "nomeStatus";
        this.view.lists.headers = [
          { text: "Status", align: "start", value: "nomeStatus" },
          { text: "Qtd", align: "center", value: "qtd" }
        ];
        break;

      case "task_total_pending":
        this.view.title = "Tarefas Pendentes";
        this.view.keyProp = "nomeStatus";
        this.view.lists.headers = [
          { text: "Status", align: "start", value: "nomeStatus" },
          { text: "Qtd", align: "center", value: "qtd" }
        ];
        break;

      case "resume_users":
        this.view.title = "Usuários";
        this.view.keyProp = "nomeUsuario";
        this.view.lists.headers = [
          { text: "Atendente", align: "start", value: "nomeUsuario" },
          { text: "Tempo Atend.", align: "center", value: "tempo" },
          { text: "Qtd. Atend.", align: "center", value: "qtd" }
        ];
        break;

      case "resume_clients":
        this.view.title = "Clientes";
        this.view.keyProp = "nomeCliente";
        this.view.lists.headers = [
          { text: "Cliente", align: "start", value: "nomeCliente" },
          { text: "Qtd", align: "center", value: "qtd" }
        ];
        break;

      case "resume_requesters":
        this.view.title = "Solicitantes";
        this.view.keyProp = "nomeSolicitante";
        this.view.lists.headers = [
          { text: "Cliente", align: "start", value: "nomeCliente" },
          { text: "Solicitante", align: "start", value: "nomeSolicitante" },
          { text: "Qtd", align: "center", value: "qtd" }
        ];
        break;
    }
    this.view.lists.headers.push({ width: "2", value: "action" });
  },

  methods: {
    onClickRow(item) {
      this.$refs.ItemDetail.load(
        this.startDate,
        this.endDate,
        this.type,
        this.icon,
        item[this.view.keyProp],
        item.id
      );
    },
    load(startDate, endDate) {
      this.view.loading = true;

      this.startDate = startDate;
      this.endDate = endDate;

      let Url = `ticket/dashboard/${this.type}/${startDate}/${endDate}/${this.rows}`;

      http.get(Url).then(response => {
        this.view.lists.items = response.data.data;
        this.view.loading = false;
      });
    }
  }
};
</script>

<style>
</style>
