<template>
  <div>
    <v-row>
      <v-col cols="12" md="2">
        <v-list dense>
          <v-list-item-group>
            <v-list-item
              @click="FiltroModulo(item)"
              v-for="item in list.modulos"
              :key="item.Id"
            >
              <v-list-item-content>
                <v-list-item-title
                  v-text="item.nomeQtdBaseConhecimento"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col cols="12" md="10">
        <CGIDataTable
          :loading="view.loading"
          :columns="view.columns"
          :items="view.lists.knowledgebases"
          @clickRemove="(value) => onClickRemove(value)"
          @clickChange="(value) => onClickChange(value)"
        >
          <!-- Filtro -->
          <template v-slot:filtro>
            <v-row dense>
              <v-row cols="12">
                <v-col cols="3" md="3">
                  <CGITextField label="Título" v-model="model.titulo" />
                </v-col>
                <v-col cols="12" md="2">
                  <CGIComboBox
                    v-model="model.releaseId"
                    route="/combo/release"
                    label="Release de lançamento"
                    :setTodos="true"
                    :itemsAsync="true"
                  />
                </v-col>
                <v-col cols="12" md="2">
                  <CGIComboBox
                    v-model="model.internoExternoId"
                    route="/combo/internoexterno"
                    label="Interno ou Externo"
                    hint="Selecione tickets internos ou externos"
                    :setTodos="true"
                    :itemsAsync="true"
                  />
                </v-col>
                <v-col cols="6">
                  <v-checkbox
                    dense
                    v-model="model.baseDeConhecimentoPendentes"
                    label="Base de Conhecimento Pendentes"
                  />
                </v-col>
              </v-row>
              <v-col cols="12" class="text-right">
                <v-btn color="info" @click="onClickAdd">Novo</v-btn>
                <v-btn
                  :loading="view.loading"
                  class="ma-2"
                  color="primary"
                  @click="filter"
                >
                  Filtrar
                  <v-icon right dark>mdi-filter</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </CGIDataTable>
      </v-col>
    </v-row>

    <Form ref="form" />
    <Delete titulo="Atenção" ref="excluir" />
  </div>
</template>
<script>
import { HttpRequestBaseDeConhecimento } from "./http";
import Form from "./Form";
import Delete from "@/components/CGIDelete";
import CGIDataTable from "@/components/CGIDataTable";
import CGITextField from "@/components/CGITextField";
import CGIComboBox from "@/components/CGICombobox";
import EventBus from "@/plugins/event-bus";

export default {
  name: "ListarKnowledgeBase",

  /**
   * Componentes filhos utilizados
   */
  components: {
    Form,
    Delete,
    CGIDataTable,
    CGITextField,
    CGIComboBox,
  },

  /**
   * Variáveis do componente
   */
  data() {
    return {
      model: {
        nome: "",
        identificacao: "",
        releaseId: 0,
        internoExternoId: 0,
        moduloId: 0,
        baseDeConhecimentoPendentes:false,
        
      },
      list: {
        modulos: [],
      },
      view: {
        columns: [
          {
            text: "Id",
            value: "id",
          },
          {
            text: "Dt. Cad.",
            value: "dataCadastro",
            type: "datetime",
          },
          {
            text: "Título",
            value: "titulo",
          },
          {
            text: "Interno ou Externo",
            value: "internoExternoExt",
          },
          {
            text: "Módulo",
            value: "nomeModulo",
          },
          {
            text: "Usuário",
            value: "nomeUsuario",
          },
          {
            text: "",
            type: "actions",
          },
        ],
        loading: false,
        lists: {
          knowledgebases: [],
        },
      },
    };
  },

  /**
   * Método chamado conforme ciclo de vida do vue
   */
  mounted() {
    this.load();

    /**
     * EventBus - CALL_LOAD
     * Usado para chamar os métodos de LOAD da página
     */
    var self = this;
    EventBus.$on("CALL_LOAD", () => {
      self.load();
    });
  },

  /**
   * Objeto onde declaramos métodos do componente
   */
  methods: {
    /**
     * Carregando dados iniciais
     */
    load() {
      this.view.loading = true;
      HttpRequestBaseDeConhecimento.get(this.model).then((response) => {
        this.view.lists.knowledgebases = response.data.data;
        console.log(response.data.data);
        this.view.loading = false;
      });
      HttpRequestBaseDeConhecimento.getModuloBaseConhecimento().then(
        (response) => {
          this.list.modulos = response.data.data;
          console.log(this.list.modulos);
          this.view.loading = false;
        }
      );
    },

    /**
     * Ao clicar ação de filtrar
     */
    filter() {
      this.load();
    },

    /**
     * Ao clicar ação de remover um usuário
     */
    onClickRemove(usuario) {
      this.$refs.excluir.abrirModal(usuario, "/BaseDeConhecimento/excluir");
    },

    /**
     * Ao clicar ação de Incluir
     */
    onClickAdd() {
      this.$refs.form.add();
    },

    /**
     * Ao clicar ação de alterar
     */
    onClickChange(knowledgebase) {
      this.$refs.form.change(knowledgebase);
    },
    FiltroModulo(dados) {
      this.model.moduloId = dados.id;

      this.load();
    },
  },

  /**
   * Ao destruir, desligamos o EventBus para não fazer chamadas desnecessárias
   */
  destroyed() {
    EventBus.$off("CALL_LOAD");
  },
};
</script>
<style></style>
