<template>
  <div>
    <v-btn large block outlined color="primary" @click="load()">
      <v-badge left color="red" overlap bordered :content="view.lists.items.length">
        <!-- TODO: GAMBS PARA O BADGE NAO FICAR POR CIMA DO TEXTO -->
        <span>..</span>
      </v-badge>
      {{ view.title }}
      <v-icon>mdi-refresh</v-icon>
    </v-btn>

    <div v-for="item in view.lists.items" v-bind:key="item.solicitacao">
      <v-card raised class="mx-auto" :color="view.color">
        <v-card-text>
          <div>
            <v-chip dark small color="indigo darken-1">{{ item.id }}</v-chip>
            <h3 class="mb-0">{{ item.titulo }}</h3>
          </div>

          <div class="text--primary">{{ item.nomeCliente }}</div>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <!-- 
              ATENÇÃO
              De acordo com o "service", vamos exibindo os botões de ação
            -->
            <div class="ml-4" v-show="service == 'tickets'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" v-on="on" depressed small @click="onClickNewService(item)">
                    <v-icon>mdi-account-voice</v-icon>
                  </v-btn>
                </template>
                <span>Informar Atendimento</span>
              </v-tooltip>
            </div>

            <div class="ml-4">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" v-on="on" depressed small @click="onClickViewTask(item)">
                    <v-icon>mdi-comment-edit-outline</v-icon>
                  </v-btn>
                </template>
                <span>Visualizar/Editar Tarefa</span>
              </v-tooltip>
            </div>

            <div class="ml-4">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" v-on="on" depressed small @click="onClickEditTask(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <!-- Do mesmo modo que temos um IF no icone, repetimos esse IF no tooltip -->
                <span>Alterar Ticket</span>
              </v-tooltip>
            </div>

            <div class="ml-4">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn color="warning" v-on="on" depressed small @click="onClickSetOK(item)">
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
                </template>
                <!-- Do mesmo modo que temos um IF no icone, repetimos esse IF no tooltip -->
                <span>Define este ticket já verificado por você</span>
              </v-tooltip>
            </div>
          </v-row>
        </v-card-actions>

        <v-divider />

        <div>
          <v-card-text v-if="service == 'tickets'" color="red" v-html="item.solicitacao"></v-card-text>
          <v-card-text v-else v-html="item.obsAtendimento"></v-card-text>
        </div>
      </v-card>
      <br />
    </div>
    <SetTask ref="SetTask" @onSuccess="load()" />
    <UpdateTask ref="UpdateTask" @onSuccess="load()" />
    <FormTicket ref="FormTicket" @onSuccess="load()" />
    <InformService ref="InformService" @onSuccess="load()" />
  </div>
</template>

<script>
import http from "@/plugins/http";

import FormTicket from "../app/ticket/Form";
import SetTask from "../app/task/SetTask";
import UpdateTask from "../app/task/UpdateTask";
import InformService from "../app/ticket/InformService";

export default {
  name: "CGIUpdateItemList",

  components: {
    //CGIModal,
    SetTask,
    UpdateTask,
    FormTicket,
    InformService
  },

  data() {
    return {
      service: "",
      view: {
        title: "",
        color: "white",
        showLeft: true,
        showRight: true,
        lists: {
          items: []
        }
      }
    };
  },

  props: {
    type: {
      default: "",
      type: String
    },
    showArrows: {
      default: true,
      type: Boolean
    }
  },

  mounted() {
    this.service = this.type;

    if (this.service == "tickets") {
      // NOVOS TÍCKETS
      this.view.title = "Novos Tickets";
    }

    if (this.service == "updates") {
      // TICKETS ATUALIZADOS
      this.view.title = "Tickets Atualizados";
    }

    this.load();
  },

  methods: {
    onClickViewTask(item) {
      this.$refs.SetTask.openModal(item.id);
    },

    onClickEditTask(item) {
      if (this.service == "task") {
        this.$refs.UpdateTask.openModal(item.id);
      } else {
        this.$refs.FormTicket.change(item);
      }
    },

    onClickSetOK(item) {
      let Url = "";

      if (this.service == "updates")
        Url = `ticket/set-ok/${this.service}/${item.idAtendimento}`;
      else Url = `ticket/set-ok/${this.service}/${item.id}`;

      http
        .put(Url)
        .then(() => {
          this.notify("Ticket alterado com sucesso", "success");
          this.load();
        })
        .catch(error => {
          let message = error.response.data.message;
          this.notify(message, "error");
        });
    },

    onClickNewService(item) {
      this.$refs.InformService.openModal(
        item.id,
        item.titulo,
        item.internoExterno
      );
    },

    load() {
      this.view.loading = true;

      let Url = `ticket/updates/${this.service}`;

      let proSelected = http.get(Url);

      Promise.all([proSelected]).then(response => {
        this.view.lists.items = response[0].data.data;
        this.view.loading = false;
      });
    }
  }
};
</script>

<style>
.fix {
  overflow-wrap: anywhere;
  word-wrap: break-word;
  word-break: normal;
  hyphens: auto;
}
</style>
