<template>
  <div>
    <CGIModal
      :loading="view.loading"
      :exibir="view.modal.show"
      :titulo="view.action"
      :fullScreen="true"
      :scrollable="true"
      @clickCancelar="close"
      @clickConfirmar="submit"
    >
      <template v-slot:body>
        <v-form ref="form" v-model="view.valid" lazy-validation>
          <v-row>
            <v-col cols="12" md="5">
              <v-row dense>
                <v-col cols="12" md="4">
                  <CGITextField
                    label="Id"
                    hint="Id do cliente"
                    v-model="model.id"
                    disabled
                  />
                </v-col>
                <v-col cols="8" md="8">
                  <CGITextField
                    label="Identificação"
                    type="text"
                    hint="Entre com a identificação"
                    :rules="[view.rules.required]"
                    required
                    v-model="model.identificacao"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12">
                  <CGITextField
                    label="Nome"
                    type="text"
                    hint="Entre com o nome"
                    :rules="[view.rules.required]"
                    required
                    v-model="model.nome"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" md="9">
                  <CGITextField
                    label="Link"
                    type="text"
                    hint="Entre com o link"
                    :rules="[view.rules.required]"
                    required
                    v-model="model.link"
                  />
                </v-col>
                <v-col cols="12" md="3 ">
                  <v-checkbox
                    dense
                    class="mt-1 ml-1"
                    v-model="model.ativo"
                    label="Ativo"
                    hint="Informe se o cliente está ativo"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" md="12">
                  <CGITextField
                    label="E-mail para notificações"
                    type="text"
                    hint="Endereço de e-mail que vai receber as notificações de atulização do Sistema"
                    :rules="[view.rules.required]"
                    required
                    v-model="model.eMailNotificacao"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" md="6">
                  <CGIComboBox
                    v-model="model.clienteId"
                    route="/combo/projetos"
                    label="Projeto"
                    hint="Defina o Projeto do cliente"
                    :itemsAsync="true"
                    required
                    :rules="[view.rules.required]"
                  />
                </v-col>
                  <v-col cols="12" md="6">
                    <CGIComboBox
                      v-model="model.usuarioTestePrincipalId"
                      route="/combo/usuario"
                      label="Usuário Teste"
                      hint="Selecione o usuário para realizar o teste"
                      :itemsAsync="true"
                    />
                  </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" md="12">
                  <CGIDataTable
                    :showSearch="true"
                    :columns="view.colunas.modulo"
                    :items="view.listas.modulo"
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" md="7">
              <CGIDataTable
                :columns="view.colunas.historico"
                :items="view.listas.historico"
              />
            </v-col>
          </v-row>
        </v-form>
      </template>
    </CGIModal>
  </div>
</template>
<script>
import { HttpRequestCliente } from "./http";
import CGIModal from "@/components/CGIModal";
import CGITextField from "@/components/CGITextField";
import CGIDataTable from "@/components/CGIDataTable";
import CGIComboBox from "@/components/CGICombobox";
import { mask } from "vue-the-mask";

const objectModel = {
  id: 0,
  identificacao: "",
  nome: "",
  link: "",
  ativo: true,
  eMailNotificacao: "",
  modulos:"",
  usuarioTestePrincipalId:0,
  clienteId:0,
};

export default {
  name: "",

  directives: {
    mask,
  },

  components: {
    CGIModal,
    CGITextField,
    CGIDataTable,
    CGIComboBox,
  },

  props: {},

  data() {
    return {
      model: objectModel,
      view: {
        action: "Incluindo novo cliente",
        loading: false,
        listas: {
          historico: [],
          modulo: [],
        },
        colunas: {
          historico: [
            {
              text: "Id",
              value: "id",
            },
            {
              text: "Data",
              value: "dataCadastro",
              type: "datetime",
            },
            {
              text: "Título",
              value: "titulo",
            },
            {
              text: "Obs.",
              value: "obs",
            },
            {
              text: "Usuário",
              value: "nomeUsuario",
            },
          ],
          modulo: [
            {
              text: "",
              value: "selecionado",
              type: "checkbox",
              config: {
                disabled: false,
              },
            },
             {
              text: "Notificar",
              value: "notificar",
              type: "checkbox",
              config: {
                disabled: false,
              },
            },
            {
              text: "Nome",
              value: "nome",
            },
            {
              text: "Grupo",
              value: "grupo",
            },
          ],
        },
        modal: {
          show: false,
        },
        rules: {
          required: (value) => !!value || "Campo Obrigatório.",
        },
      },
    };
  },

  computed: {},

  watch: {},

  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (
        this.view.id == undefined ||
        this.view.id == null ||
        this.view.id == 0
      ) {
        this.save();
      } else {
        this.update();
      }
    },

    save() {
      this.view.loading = true;

      this.model.modulos = this.view.listas.modulo;

      HttpRequestCliente.postCliente(this.model)
        .then(() => {
          this.view.modal.show = false;
          this.notify("Cliente incluído com sucesso", "success");
          close();
        })
        .catch((error) => {
          let message = error.response.data.message;
          this.notify(message, "error");
        })
        .then(() => {
          this.view.loading = false;
        });
    },
    update() {
      HttpRequestCliente.putCliente(this.model)
        .then(() => {
          this.notify("Cliente alterado com sucesso", "success");
          this.view.modal.show = false;
          close();
        })
        .catch((error) => {
          let message = error.response.data.message;
          this.notify(message, "error");
        })
        .then(() => {
          this.view.loading = false;
          this.close();
        });
    },

    close() {
      this.model = {};
      this.view.modal.show = false;
    },

    add() {
      this.view.modal.show = true;
    },

    change(cliente) {
      this.view.modal.show = true;
      this.view.action = "Alterando cliente [" + cliente.nome + "]";

      this.view.loading = true;

      let proCliente = HttpRequestCliente.getById(cliente.id);
      let proModulo = HttpRequestCliente.getModulos(cliente.id);

      Promise.all([proCliente, proModulo]).then((response) => {
        this.model = response[0].data.data;
        this.view.listas.modulo = response[1].data.data;

        this.view.loading = false;
      });
    },
  },
};
</script>
<style></style>
