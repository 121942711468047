<template>
  <div>
    <v-row>
    <v-col cols="12" md="3">
      <CGIComboBox
        v-model="model.usuarioId"
        route="/combo/usuario"
        label="Usuário"
        hint="Selecione o usuário"
        :itemsAsync="true"
      />
    </v-col>
    <v-col cols="12" md="3">
      <v-btn
        color="primary"
        @click="onChanged(model.TabName)"
      >
        Filtrar
        <v-icon right dark>mdi-filter</v-icon>
      </v-btn>
    </v-col>
    </v-row>
    <v-card>
      <v-tabs background-color="primary" center-active grow dark>
        <v-tab @click="onChanged('Backlog')">
          <v-icon left>mdi-file-tree</v-icon>Backlog
        </v-tab>
        <v-tab @click="onChanged('Backend')">
          <v-icon left>mdi-iframe-outline</v-icon>Backend
        </v-tab>
        <v-tab @click="onChanged('Frontend')">
          <v-icon left>mdi-iframe-outline</v-icon>Frontend
        </v-tab>
        <v-tab @click="onChanged('inTest')">
          <v-icon left>mdi-code-tags-check</v-icon>Para Testar
        </v-tab>
        <v-tab @click="onChanged('Tested')">
          <v-icon left>mdi-clipboard-check-multiple-outline</v-icon>Testadas
        </v-tab>
        <v-tab @click="onChanged('Release')">
          <v-icon left>mdi-send-check</v-icon>Release
        </v-tab>

        <v-tab-item>
          <v-row>
            <v-col cols="12" md="4">
              <ResumeList
                ref="ResumeListBackLog"
                type="backLog"
                @clickChange="onChanged('Backlog')"
              />
            </v-col>
            <v-col cols="12" md="4">
              <ResumeList
                ref="ResumeListPlanning"
                type="planning"
                @clickChange="onChanged('Backlog')"
              />
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item>
          <v-row>
            <v-col cols="12" md="4">
              <ResumeList
                ref="ResumeListSelectedBACK"
                type="selectedForDevBACK"
                @clickChange="onChanged('Backend')"
              />
            </v-col>
            <v-col cols="12" md="4">
              <ResumeList
                ref="ResumeListDevBACK"
                type="inDevBACK"
                @clickChange="onChanged('Backend')"
              />
            </v-col>
            <v-col cols="12" md="4">
              <ResumeList
                ref="ResumeListReviewBACK"
                type="availableForReviewBACK"
                @clickChange="onChanged('Backend')"
              />
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item>
          <v-row>
            <v-col cols="12" md="4">
              <ResumeList
                ref="ResumeListSelectedFRONT"
                type="selectedForDevFRONT"
                @clickChange="onChanged('Frontend')"
              />
            </v-col>
            <v-col cols="12" md="4">
              <ResumeList
                ref="ResumeListDevFRONT"
                type="inDevFRONT"
                @clickChange="onChanged('Frontend')"
              />
            </v-col>
            <v-col cols="12" md="4">
              <ResumeList
                ref="ResumeListReviewFRONT"
                type="availableForReviewFRONT"
                @clickChange="onChanged('Frontend')"
              />
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item>
          <v-row>
            <v-col cols="12" md="4">
              <ResumeList
                ref="ResumeListWaitingReleaseTest"
                type="waitingReleaseTest"
                @clickChange="onChanged('inTest')"
              />
            </v-col>
            <v-col cols="12" md="4">
              <ResumeList
                ref="ResumeListAvailableForTest"
                type="availableForTest"
                @clickChange="onChanged('inTest')"
              />
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item>
          <v-row>
            <v-col cols="12" md="4">
              <ResumeList
                ref="ResumeListInTests"
                type="inTests"
                @clickChange="onChanged('Tested')"
              />
            </v-col>
            <v-col cols="12" md="4">
              <ResumeList
                ref="ResumeListTestAproved"
                type="testAproved"
                @clickChange="onChanged('Tested')"
              />
            </v-col>
            <v-col cols="12" md="4">
              <ResumeList
                ref="ResumeListTestReproved"
                type="testReproved"
                @clickChange="onChanged('Tested')"
              />
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item>
          <v-row>
            <v-col cols="12" md="4">
              <ResumeList
                ref="ResumeListInRelease"
                type="inRelease"
                @clickChange="onChanged('Release')"
              />
            </v-col>
            <v-col cols="12" md="4">
              <ResumeList
                ref="ResumeListPublished"
                type="published"
                @clickChange="onChanged('Release')"
              />
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import ResumeList from "@/components/CGIKanbanListCard";
import CGIComboBox from "@/components/CGICombobox";

export default {
  name: "ResumeFULL",

  components: {
    ResumeList,
    CGIComboBox,
  },
  data() {
    return {
      model: {
        usuarioId: 0,
        TabName: "",
      },
    };
  },

  methods: {
    onChanged(nameTab) {
      this.model.TabName = nameTab;
      
      setTimeout(() => {
        switch (nameTab) {
          case "Backlog":
            this.$refs.ResumeListBackLog.load(this.model.usuarioId);
            this.$refs.ResumeListPlanning.load(this.model.usuarioId);
            break;
          case "Backend":
            this.$refs.ResumeListSelectedBACK.load(this.model.usuarioId);
            this.$refs.ResumeListDevBACK.load(this.model.usuarioId);
            this.$refs.ResumeListReviewBACK.load(this.model.usuarioId);
            break;
          case "Frontend":
            this.$refs.ResumeListSelectedFRONT.load(this.model.usuarioId);
            this.$refs.ResumeListDevFRONT.load(this.model.usuarioId);
            this.$refs.ResumeListReviewFRONT.load(this.model.usuarioId);
            break;
          case "inTest":
            this.$refs.ResumeListWaitingReleaseTest.load(this.model.usuarioId);
            this.$refs.ResumeListAvailableForTest.load(this.model.usuarioId);
            break;
          case "Tested":
            this.$refs.ResumeListInTests.load(this.model.usuarioId);
            this.$refs.ResumeListTestAproved.load(this.model.usuarioId);
            this.$refs.ResumeListTestReproved.load(this.model.usuarioId);
            break;
          case "Realease":
            this.$refs.ResumeListInRelease.load(this.model.usuarioId);
            this.$refs.ResumeListPublished.load(this.model.usuarioId);
            break;
        }
      }, 100);
    },
  },
};
</script>
<style></style>
