<template>
  <div>
    <CGITextField
      v-model="modValue"
      :label="label"
      v-bind:value="compValue"
      v-on:keypress="onlyNumber"
      v-on:keyup="emit"
    />
  </div>
</template>

<script>
import CGITextField from "@/components/CGITextField";
// import { MaskHelper } from '../helpers/Mask'

export default {
  components: {
    CGITextField
  },

  model: { prop: "value", event: "input" },
  props: {
    value: {
      type: [String, Number],
      default: "0"
    },
    label: {
      type: String,
      default: ""
    },
    hint: {
      type: String,
      default: ""
    },
    outlined: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showSymbol: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "currency"
    }
  },
  data: () => ({
    modValue: "",
    options: {
      locale: "pt-BR",
      prefix: "R$",
      precision: 2
    }
  }),
  mounted() {
    if (!this.showSymbol && this.type != "currency") {
      this.options.prefix = "";
    }

    if (this.type == "currency") {
      this.options.prefix = "R$";
      this.options.precision = 2;
    }

    if (this.type == "decimal") {
      this.options.prefix = "";
      this.options.precision = 2;
    }

    if (this.type == "int") {
      this.options.prefix = "";
      this.options.precision = 0;
    }
  },
  computed: {
    compValue() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return (this.modValue = this.humanFormat(
        this.machineFormat(this.humanFormat(this.value))
      ));
    }
  },
  methods: {
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },

    emit() {
      this.$emit("input", this.machineFormat(this.modValue));
    },

    /* Formatar no padrão NNNNNN.NN */
    machineFormat(number) {
      if (number) {
        if (this.type == "int") {
          return parseInt(number);
        }

        // Retirar prefixo
        if (number.includes(" ")) {
          number = number.split(" ")[1];
        }

        // Retirar pontos e virgulas
        number = number
          .replace(new RegExp(/[.]/, "g"), "")
          .replace(new RegExp(",", "g"), "");
        // Ajustar quantidade de zeros à esquerda
        number = number.padStart(parseInt(this.options.precision) + 1, "0");
        // Incluir ponto na casa correta, conforme a precisão configurada
        number =
          number.substring(
            0,
            number.length - parseInt(this.options.precision)
          ) +
          "." +
          number.substring(
            number.length - parseInt(this.options.precision),
            number.length
          );

        if (isNaN(number)) {
          number = "0";
        }
      } else {
        number = "0";
      }
      return parseFloat(number);
    },

    humanFormat: function(number) {
      if (isNaN(number)) {
        number = "0";
      } else {
        if (this.type == "int") {
          return parseInt(number);
        }

        number = Number(number).toLocaleString(this.options.locale, {
          maximumFractionDigits: this.options.precision,
          minimumFractionDigits: this.options.precision
        });

        if (this.type == "decimal") {
          number = number.split(".").join("");
        }
      }

      return this.options.prefix + " " + number;
    }
  }
};
</script>
