<template>
  <div>
    <CGIModal
      :loading="view.loading"
      :exibir="view.modal.show"
      :titulo="view.action"
      :fullScreen="false"
      :max-width="'900px'"
      :scrollable="true"
      @clickCancelar="close"
      @clickConfirmar="submit"
    >
      <template v-slot:body>
        <v-form ref="form" v-model="view.valid" lazy-validation>
          <v-row dense>
            <v-col cols="6" md="6">
              <CGITextField
                label="Id"
                hint="Id do soliciante"
                v-model="model.id"
                disabled
              />
            </v-col>
            <v-col cols="12" md="12">
              <CGIComboBox
                v-model="model.clienteId"
                route="/combo/cliente"
                label="Cliente"
                hint="Selecione o cliente"
                :itemsAsync="true"
                :rules="[view.rules.required]"
                required
              />
            </v-col>

            <v-col cols="6">
              <CGITextField
                label="Nome"
                type="text"
                hint="Entre com o nome"
                :rules="[view.rules.required]"
                required
                v-model="model.nome"
              />
            </v-col>

            <v-col cols="12">
              <CGITextField
                label="E-Mail"
                type="text"
                hint="Entre com o e-mail"
                v-model="model.eMail"
              />
            </v-col>

            <v-col cols="6">
              <v-checkbox
                dense
                v-model="model.ativo"
                label="Ativo"
                hint="Informe se o cliente está ativo"
              />
            </v-col>

            <v-col cols="6">
              <v-checkbox
                dense
                v-model="model.notificacaoResumoWhatsApp"
                label="Notificar WhatasApp"
                hint="Informe se o cliente receberá notificações Whatsapp"
              />
            </v-col>

            <v-col cols="6">
              <CGITextField
                label="Nº WhatsApp (com DDD)"
                type="text"
                hint="Entre com o nº whatsapp"
                v-model="model.whatsApp"
              />
            </v-col>
          </v-row>
          <v-row dense class="mb-n7 mt-2">
            <v-col cols="12" md="8">
              <CGIComboBox
                v-model="model.resumosWhatsApp"
                route="/combo/resumonotificacao"
                label="Resumo Notif. WhatsApp"
                hint="Selecione os resumos"
                multiple
                small_chips
                :itemsAsync="true"
              />
            </v-col>
          </v-row>
        </v-form>
      </template>
    </CGIModal>
  </div>
</template>
<script>
import { HttpRequestClienteUsuario } from "./http";
import CGIModal from "@/components/CGIModal";
import CGITextField from "@/components/CGITextField";
import CGIComboBox from "@/components/CGICombobox";

const objectModel = {
  id: 0,
  identificacao: "",
  nome: "",
  link: "",
  eMail: "",
  ativo: true,
  notificacaoResumoWhatsApp: false,
  whatsApp: "",
  resumosWhatsApp: "",
};

export default {
  name: "",

  components: {
    CGIModal,
    CGITextField,
    CGIComboBox,
  },

  props: {},

  data() {
    return {
      model: Object.assign({}, objectModel),
      view: {
        action: "Incluindo novo solicitante",
        loading: false,
        modal: {
          show: false,
        },
        rules: {
          required: (value) => !!value || "Campo Obrigatório.",
        },
      },
    };
  },

  computed: {},

  watch: {},

  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (
        this.model.id == undefined ||
        this.model.id == null ||
        this.model.id == 0
      ) {
        this.save();
      } else {
        this.update();
      }
    },

    save() {
      this.view.loading = true;

      HttpRequestClienteUsuario.postClienteUsuario(this.model)
        .then(() => {
          this.view.modal.show = false;
          this.notify("Solicitante incluído com sucesso", "success");
        })
        .catch((error) => {
          let message = error.response.data.message;
          this.notify(message, "error");
        })
        .then(() => {
          this.view.loading = false;
          this.$emit("onSuccess");
          this.close();
        });
    },
    update() {
      HttpRequestClienteUsuario.putClienteUsuario(this.model)
        .then(() => {
          this.notify("Solicitante alterado com sucesso", "success");
          this.view.modal.show = false;
          close();
        })
        .catch((error) => {
          let message = error.response.data.message;
          this.notify(message, "error");
        })
        .then(() => {
          this.view.loading = false;
          this.close();
          this.$emit("onSuccess");
        });
    },

    close() {
      this.model = objectModel;
      this.model = Object.assign({}, objectModel);
      this.view.modal.show = false;
    },

    add() {
      this.view.modal.show = true;
    },

    change(cliente) {
      this.model = Object.assign({}, objectModel);
      this.view.modal.show = true;
      this.view.action = "Alterando solicitante [" + cliente.nome + "]";

      this.view.loading = true;

      let proClienteUsuario = HttpRequestClienteUsuario.getById(cliente.id);

      Promise.all([proClienteUsuario]).then((response) => {
        this.model = response[0].data.data;
        this.view.loading = false;
      });
    },
  },
};
</script>
<style></style>
