<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    :mini-variant.sync="mini"
    :expand-on-hover="true"
    :width="310"
    permanent
    dense
    mini-variant-width="70"
  >
    <template v-slot:prepend>
      <v-list-item>
        <v-list-item-avatar>
          <v-img :src="require('../assets/avatar.png')"></v-img>
        </v-list-item-avatar>
        <v-list-item-title
          >{{ getUser() }} - {{ getCurrentProject() }}</v-list-item-title
        >
      </v-list-item>

      <!-- <v-list-item v-if="!mini" class="filter_menu">
        <v-combobox
          :items="view.lists.menus"
          label="Filtrar menu"
          outlined
          dense
          item-text="title"
        />
      </v-list-item>-->
      <!-- <v-list-item v-else class="filter_menu">
        <v-icon>mdi-magnify</v-icon>
      </v-list-item>-->
      <v-divider />
    </template>

    <v-list>
      <template v-for="menu in view.lists.menus">
        <!-- Menu sem submenus -->
        <v-list-item
          v-if="menu.submenus === undefined"
          :key="menu.title"
          @click="onClick(menu)"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ menu.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ menu.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Menu com submenus -->
        <v-list-group
          v-else
          dense
          :key="menu.title"
          v-model="menu.active"
          :prepend-icon="menu.action"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>{{ menu.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="menu.title"></v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            dense
            v-for="subItem in menu.submenus"
            :key="subItem.title"
            sub-group
            @click="onClick(subItem)"
          >
            <v-list-item-content>
              <v-list-item-title v-text="subItem.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </template>
    </v-list>

    <template v-slot:append>
      <v-divider />

      <v-list-item @click="onClickSair" link>
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Sair</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "NavigationDrawer",

  props: {},

  data: () => ({
    mini: true,
    view: {
      lists: {
        menus: [],
      },
      projectName: "",
    },
  }),

  computed: {
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
  },

  mounted() {
    this.mounteMenus();
  },

  methods: {
    mounteMenus() {
      this.view.lists.menus = [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          href: "/dash",
        },
      ];

      // TODO Alterar para vuex, feito assim so para teste
      let user = JSON.parse(localStorage.getItem("user"));

      // TODO - Ideal é que o menu seja montado na API e chegue aqui já tratado.
      //        O Front simplesmente deve ficar responsável em exibir.
      //        Pensando que esse menu venha a crescer no futuro.
      if (user.clienteId > 0) {
        this.view.lists.menus[0].href = "/client-dash";
        return;
      }

      user.menus.map((userMenu) => {
        let menu = {
          title: userMenu.label,
          submenus: [],
          icon: this.generateIcon(userMenu.label),
        };

        if (userMenu.itens.length > 0) {
          userMenu.itens.map((subMenuUser) => {
            menu.submenus.push({
              title: subMenuUser.label,
              href: subMenuUser.link,
            });
          });
        }

        this.view.lists.menus.push(menu);
      });
    },
    generateIcon(name) {
      // console.log(name);
      let icon = "";
      switch (name) {
        case "Suporte":
          icon = "mdi-face-agent";
          break;
        case "Dev":
          icon = "mdi-console";
          break;
        case "Release":
          icon = "mdi-send-circle";
          break;
        case "Cadastros":
          icon = "mdi-cogs";
          break;
        default:
          icon = "mdi-folder";
          break;
      }
      return icon;
    },

    onClick(menu) {
      if (menu.href !== undefined && !this.$route.path.includes(menu.href)) {
        // TODO ALAN -> Verificar com Romulo
        let href = menu.href;
        if (href[0] != "/") {
          href = `/${menu.href}`;
        }

        this.$router.push(href);
      }
    },

    onClickSair() {
      localStorage.clear();
      this.$router.replace({ path: "/auth" });
    },

    getUser() {
      // TODO Alterar para vuex, feito assim so para teste
      let user = JSON.parse(localStorage.getItem("user"));
      return user.nomeUsuario;
    },
    getCurrentProject() {
      let project = JSON.parse(localStorage.getItem("currentProject"));
      return project.nome;
    },
  },
};
</script>

<style scoped>
.filter_menu {
  margin-top: 5px;
}
</style>
