<script>

import { VTextField } from 'vuetify/lib';

export default {
  extends: VTextField,
  props: {
    dense: {
      default: true,
      type: Boolean
    },
    clearable: {
      default: true,
      type: Boolean
    },
    outlined: {
      default: true,
      type: Boolean
    },
  }
}
</script>