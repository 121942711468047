<template>
  <v-app-bar
    elevation="1"
    color="white"
    app
    flat
    height="56"
    :hide-on-scroll="false"
  >
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn text color="primary" v-on="on">
          {{ view.projectName }}
          <v-icon right>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, i) in view.lists.projects"
          :key="i"
          @click="onClickChanceProject(item)"
        >
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn
      text
      color="primary"
      v-if="view.projectVersion"
      @click="goToRelease()"
      >{{ view.projectVersion }}</v-btn
    >
    <v-spacer />

    <v-menu
      v-if="!view.user.clienteId || view.user.clienteId == 0"
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
          @click="onClickBell()"
        >
          <v-badge
            v-if="view.lists.notifications.length > 0"
            color="red"
            overlap
            bordered
          >
            <template v-slot:badge>
              <span>{{ view.lists.notifications.length }}</span>
            </template>
            <v-icon color="info">mdi-bell</v-icon>
          </v-badge>
          <v-icon v-else color="info">mdi-bell</v-icon>
        </v-btn>
      </template>
      <v-list nav>
        <v-subheader>Resumo</v-subheader>
        <v-list-item-group color="primary">
          <v-list-item
            v-for="(item, i) in view.lists.notifications"
            :key="i"
            @click="onClickItem(item)"
          >
            <v-list-item-content>
              <v-badge
                bordered
                inline
                color="pink"
                :content="item.count"
              ></v-badge>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import http from "@/plugins/http";
import EventBus from "@/plugins/event-bus";
import { mapState, mapMutations } from "vuex";

export default {
  name: "Toolbar",

  components: {},

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    view: {
      user: {},
      projetoId: 0,
      projectName: "Projetos",
      lists: {
        notifications: [],
        projects: [],
      },
    },
  }),

  mounted() {
    this.view.user = JSON.parse(localStorage.getItem("user"));
    this.loadNotifications();
    this.loadProjects();

    let currentProject = JSON.parse(localStorage.getItem("currentProject"));
    this.view.projectName = currentProject.nome;
    this.view.projectVersion = currentProject.versaoAtual;
    this.view.projetoId = currentProject.id;

    setInterval(() => {
      this.loadNotifications();
    }, 80000);
  },

  computed: {
    ...mapState(["drawer"]),
  },

  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),

    onClickDashboard() {
      if ("/dash" != this.$router.currentRoute.path) {
        let url = "/dash";
        this.$router.push(url);
      }
    },

    onClickChanceProject(project) {
      if (this.view.user.clienteId > 0) return;

      let currentProject = {
        id: project.id,
        nome: project.name,
        versaoAtual: project.group,
      };
      localStorage.setItem("currentProject", JSON.stringify(currentProject));
      this.view.projectVersion = project.group;
      this.view.projectName = project.name;

      EventBus.$emit("CALL_LOAD");

      /**
       * Força a atualização do componente
       * (necessário para atualizar a versão do projeto)
       */
      this.$forceUpdate();
    },

    onClickBell() {
      this.loadNotifications();
    },

    onClickItem(item) {
      if (item.route != this.$router.currentRoute.path) {
        let url = item.route;
        this.$router.push(url);
      }
    },

    loadNotifications() {
      this.view.loading = true;

      let Url = `user/notification`;

      let proSelected = http.get(Url);

      Promise.all([proSelected]).then((response) => {
        this.view.lists.notifications = response[0].data.data;
        this.view.loading = false;
      });
    },

    // Rota para carregar os projetos que o usuário tem acesso
    loadProjects() {
      http.get("combo/usuarioprojetos").then((response) => {
        this.view.lists.projects = response.data.data;
      });
    },

    goToRelease() {
      if (this.view.user.clienteId > 0) return;
      this.$router.push("/release/listar");
    },
  },
};
</script>
