<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <CGIUpdateItemList ref="UpdateItemListTickets" type="tickets" />
      </v-col>
      <v-col cols="12" md="6">
        <CGIUpdateItemList ref="UpdateItemListUpdates" type="updates" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CGIUpdateItemList from "@/components/CGIUpdateItemList";

export default {
  name: "Dash",

  components: {
    CGIUpdateItemList
  },

  data() {
    return {};
  },

  methods: {}
};
</script>
<style></style>
